<div class="page-title-area-results">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Mock Test</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Mock Test</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="create-account-area pt-100 pb-100">
    <div *ngIf="!submitted" class="container">
        <div class="row">
            <div class="col-lg-12">
                <h1 class="col">Subject : <span style="font-weight: bold;"> {{ex_cate}} </span></h1>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="row" *ngFor="let i of displayquestion">
                    <div class="col-lg-7">
                        <p class="para">Completed Questions:{{i.index + 1 }} <span style="color:green;"></span> of {{getquestionpaper.length}}</p>
                    </div>
                    <div class="col-lg-1">
                        <img src="assets/img/time.jpg" width="30px" height="30px">
                    </div>
                    <div class="col-lg-4">
                        <p class="para1"> <span *ngIf="(timer$ | async) as timer"> <span *ngIf="timer >= 0">{{ timer |
                                    formatTime }} Sec Left </span> </span></p>
                        <!-- <input type="text" value={{now}} /> -->
                        <!-- <div class="description">
                            <p>Minutes</p>
                            <small>Seconds</small>
                          </div>
                          <div class="times">
                            <p id="mins"></p>
                            <p id="seconds"></p>
                          </div> -->
                    </div>
                </div>
                <!-- //newcode changes -->
                <section class="job-area job-area-twos pt-100" style="margin-bottom:10% ;">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12" *ngFor="let i of displayquestion;let inds =index">
                                <div class="job-item">
                                    <h5>Exam Name:{{ex_name}}</h5>
                                    <div class="job-inner align-items-center">
                                        <div class="job-inner-left">
                                            <h3 style="font-size:17px"><label>Q:{{i.index + 1}}</label>
                                                {{i.question}}</h3>
                                            <input type="radio" name="option" id="option1"
                                                (change)="submitAnswer(i.index,i.option1, 1)"
                                                [checked]="i.answer_ind ==1" [value]="i.option1"><label
                                                for="option1">{{i.option1}}</label>&nbsp;<br>
                                            <input type="radio" name="option" id="option2"
                                                (change)="submitAnswer(i.index,i.option2, 2)"
                                                [checked]="i.answer_ind ==2" [value]="i.option2"><label
                                                for="option2">{{i.option2}}</label>&nbsp;<br>
                                            <input type="radio" name="option" id="option3"
                                                (change)="submitAnswer(i.index,i.option3, 3)"
                                                [checked]="i.answer_ind ==3" [value]="i.option3"><label
                                                for="option3">{{i.option3}}</label>&nbsp;<br>
                                            <ul>
                                                <input type="radio" name="option" id="option4"
                                                    (change)="submitAnswer(i.index,i.option4, 4)"
                                                    [checked]="i.answer_ind ==4" [value]="i.option4"><label
                                                    for="option4">{{i.option4}}</label>&nbsp;<br>
                                            </ul>
                                            <div class="row" style="margin-top:25px">
                                                <div class="col-lg-3">
                                                    <button type="submit" *ngIf="i.index != 0 " class="btn5"
                                                        (click)="submitNext(i.index - 1)">Previous</button>
                                                </div>
                                                <div class="col-lg-3">
                                                    <!-- <button type="button" *ngIf="i.status == 3 "
                                                        (click)="saveandReviewNext(i.index, 2)" class="reviwsubmit">Mark
                                                        For Review</button> -->

                                                </div>

                                                <div class="col-lg-3">
                                                    <button type="button" *ngIf="i.index == getquestionpaper.length -1 "
                                                        (click)="submitallanswers()" class="btnsubmit">Submit</button>

                                                </div>
                                                <div class="col-lg-3">
                                                    <button type="button" *ngIf="i.index != getquestionpaper.length -1 "
                                                        (click)="submitNext(i.index +1);saveandReviewNext(i.index, i.status == 3 ? 3: 1 )"
                                                        class="btnsubmit">
                                                        Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-lg-6">
                <div class="pricing-item1" data-wow-delay=".4s">
                    <div class="job-card" style="color: #b3b9ba;">
                        <div class="job-inner">
                            <div class="row">
                                <div class="col-lg-12">
                                    <p class="que">Questions ({{getquestionpaper.length}})</p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-1">

                                    </div>
                                    <div class="col-lg-10">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-3 col-4 col-sm-4 " style="text-align: center;"
                                                *ngFor="let data of getquestionpaper;let ind =index">
                                                <button class="btns1"
                                                    [style.background-color]="data.status ==3 ? '#45d96d' : (data.status == 2 ? '#af54e9' : data.status == 1 ? '#f7d611' : null)"
                                                    style="margin-top: 5%;"
                                                    (click)="submitNext(data.index)">{{ind+1}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 10%;">
                                    <div class="col-lg-6">
                                        <button class="box1" style="margin-left: 8%;"></button>
                                        <label style="margin-left:6px" class="inp">Answered</label>
                                    </div>

                                    <div class="col-lg-6">
                                        <button class="box2" style="margin-left: 8%;"></button>
                                        <label style="margin-left:6px" class="inp">Not Answered</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- <button class="box3" style="margin-top: 7%;margin-left: 8%;"></button>
                                        <label style="margin-left:6px" class="inp">Marked For Review</label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="submitted" class="container">
        <div class="row">
            <div class="col-lg-12">
                <h1 class="col">Subject : <span style="font-weight: bold;"> {{ex_cate}} </span></h1>
                <h5>Exam Name:{{ex_name}}</h5>
            </div>
        </div>
        <div class="alert alert-info" role="alert">
            Your total Score is: <span style="    color: #ff0018;
            font-weight: bold;
            font-size: 28px;"> {{score}}</span>
        </div>
        <div *ngIf="showresultquestions.length" class="progress" style="margin-bottom: 10px;height: 30px;">
            <div *ngIf="showresultquestions.length" class="progress-bar" role="progressbar"
                aria-label="Example with label" [style.width.%]="(score/getquestionpaper.length) * 100"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{(score/getquestionpaper.length) * 100 |
                number : '1.2-2'}}%</div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2">
            <div class="col curse bot">
                <div class="card radius-10 bg-success bg-gradient curse">
                    <div class="card-body">
                        <div class="d-flex align-items-center" (click)="showStatusQustions(3)">
                            <div>
                                <h3 class="mb-0 text-white">Answered : {{answered}} </h3>

                            </div>
                            <div class="text-white ms-auto font-35"><i class='bx bx-money'></i>
                            </div>
                            <!-- <h5 class="mb-0 text-white"> <span>Right: {{score}} </span> <span>Wrong: {{answered - score}} </span> </h5> -->
                        </div>
                        <div class="total" style="display: flex; justify-content: space-between;">
                            <h5 class="my-1 text-white" (click)="showStatusQustions(5)">Correct: {{score}} </h5>
                            <h5 class="my-1 text-white" (click)="showStatusQustions(6)">Wrong: {{answered - score}}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col curse bot" (click)="showStatusQustions(0)">
                <div class="card radius-10 bg-warning bg-gradient curse">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h3 class="mb-0 text-white">Not Answered </h3>
                                <h3 class="mb-0 text-white">{{notanswered + skipped}} </h3>
                                <!-- <h5 class="my-1 text-white">$89,245</h5> -->
                                <!-- <h5 class="my-1 text-white">Income:845</h5>
                    <h5 class="my-1 text-white">Expenditure:845</h5> -->
                            </div>
                            <div class="text-white ms-auto font-35"><i class='bx bx-money'></i>
                            </div>
                        </div>
                        <div class="total">
                            <!-- <h5 class="my-1 text-white" *ngFor="let item of counts">Income: {{item.today}}₹ </h5>
                  <h5 class="my-1 text-white" *ngFor="let item of expand">Expenditure: {{item.today}}₹</h5> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col curse bot" (click)="showStatusQustions(2)">
                <div class="card radius-10  bg-gradient curse" style="background-color: rgb(175, 84, 233);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h3 class="mb-0 text-white">Mark For Review </h3>
                                <h3 class="mb-0 text-white"> {{ review}} </h3>
                            </div>
                            <div class="text-white ms-auto font-35"><i class='bx bx-money'></i>
                            </div>
                        </div>
                        <div class="total">
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col curse bot" (click)="showStatusQustions(1)">
                <div class="card radius-10 bg-warning  bg-gradient curse">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h3 class="mb-0 text-white">Skipped </h3>
                                <h3 class="mb-0 text-white"> {{skipped}} </h3>
                            </div>
                            <div class="text-white ms-auto font-35"><i class='bx bx-money'></i>
                            </div>
                        </div>
                        <div class="total">
                        </div>
                    </div>
                </div>
            </div> -->

        </div>

        <div class="row">
            <div class="col-lg-12">
                <p class="que curse" (click)="showStatusQustions(4)">Review All</p>
            </div>
            <div class="row">
                <div class="col-lg-1">

                </div>
                <div class="col-lg-10">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-4 col-sm-4 " style="text-align: center;"
                            *ngFor="let data of showresultquestions;let ind =index">
                            <button class="btns1"
                                [style.background-color]="(data.status ==3 && data.answer == data.correct_answer) ? '#45d96d' : (data.status ==3 && data.answer != data.correct_answer) ?  'red' : 'yellow' "
                                style="margin-top: 5%;" (click)="reviewQuestion(data.index)">{{data.index +1}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <mat-card *ngFor="let item of displayReviewquestion" style="background: #ffeac4;">
            <mat-card-title style="color: black;"> <span style="font-weight: bold;">Q:{{item.index + 1}}</span>
                {{item.question}}</mat-card-title>
            <mat-card-actions>
                <button mat-button>&nbsp;&nbsp;{{item.option1}}</button>
                <button mat-button>&nbsp;&nbsp;{{item.option2}}</button>
                <button mat-button>&nbsp;&nbsp;{{item.option3}}</button>
                <button mat-button>&nbsp;&nbsp;{{item.option4}}</button>
            </mat-card-actions>
            <br>

            <div class="alert alert-primary" role="alert"
                *ngIf="item.status == 3 && item.answer == item.correct_answer">
                Your Answer is correct <span style="color: blue; font-weight: bold;"> {{item.answer }}</span>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="item.status == 3 && item.answer != item.correct_answer">
                Your Answer is Wrong <span style="color: blue; font-weight: bold;"> {{item.answer }}</span>
            </div>
            <div class="alert alert-info" role="alert" *ngIf=" item.status != 3">
                The Correct Answer is <span style="color: blue; font-weight: bold;"> {{item.correct_answer }}</span>
            </div>
            <div class="alert alert-info" role="alert" *ngIf=" item.status == 3 && item.answer != item.correct_answer">
                The Correct Answer is <span style="color: blue; font-weight: bold;"> {{item.correct_answer}}</span>
            </div>
        </mat-card>

    </div>


    <!-- //radio starts -->

    <!-- //questions starts -->
    <!--
    <section class="job-area job-area-twos pt-100" style="margin-bottom:10% ;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" *ngFor="let i of displayquestion">
                    <div class="job-item">
                        <h5>Exam Name:{{i.exam_name}}</h5>
                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3 style="font-size:17px"><label>Question:{{i.id}}</label>
                                    {{i.question}}</h3>
                                <input type="radio" name="option" id="option1" (change)="submitAnswer(i.index, 1)"
                                    value="1"><label for="option1">{{i.option1}}</label>&nbsp;<br>
                                <input type="radio" name="option" id="option2" (change)="submitAnswer(i.index, 2)"
                                    value="2"><label for="option2">{{i.option2}}</label>&nbsp;<br>
                                <input type="radio" name="option" id="option3" (change)="submitAnswer(i.index, 3)"
                                    value="3"><label for="option3">{{i.option3}}</label>&nbsp;<br>
                                <ul>
                                    <input type="radio" name="option" id="option4" (change)="submitAnswer(i.index, 4)"
                                        value="4"><label for="option4">{{i.option4}}</label>&nbsp;<br>
                                </ul>
                                <div class="row" style="margin-top:25px">
                                    <div class="col-lg-4">
                                        <button type="submit" *ngIf="i.index != 0 " class="btn5" (click)="submitNext(i.index - 1)">Previous</button>
                                    </div>

                                    <div class="col-lg-4">
                                        <button type="button" *ngIf="i.index == getquestionpaper.length -1 " (click)="submitallanswers()" class="btnsubmit">Submit</button>

                                    </div>
                                    <div class="col-lg-4">
                                        <button type="button" *ngIf="i.index != getquestionpaper.length -1 " (click)="submitNext(i.index +1)" class="btnsubmit">Submit &
                                            Next</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <div class="col-md-6" *ngFor="let i of displayquestion" class="fontsizes">
         <h2 style="color:#008081;text-align:center">Your Result</h2>
        <div class="col-lg-4" style="margin-top:10px">
            <button class="circles">30%</button>
        </div>
        <div class="container" style="margin-left:20%;margin-bottom:40px;">
            <div class="row">
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>Quiz Name</p>
                </div>
                <div class="col-lg-5" style="background-color:#f7f6f5;color:#76add4">
                    <p>HTML TesT-1</p>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>Total Questions</p>
                </div>
                <div class="col-lg-5" style="background-color:#f7f6f5;color:#343433">
                    <p>10</p>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>Correct answer</p>
                </div>
                <div class="col-lg-5" style="background-color:#f7f6f5;color:#18c93f">
                    <p>{{i.answer}}</p>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>Wrong Answer</p>
                </div>
                <div class="col-lg-5" style="background-color:#f7f6f5;color:#ff034d">
                    <p>7</p>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>Score</p>
                </div>
                <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                    <p>{{i.correct_answer}}</p>
                </div>
            </div>
        </div>
    </div> -->
    <!-- //newcode starts -->
    <!-- <div class="card imges" style="background-color:#6abbc9;width:50%;border-radius:20px;">
            <div class="row">
                <div class="col-lg-6">
                    <div class="container">
                        <h2 style="background-color: #6abbc9;color:#e9f4f6;text-align:center;">Your Result</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4" style="margin-top:10px">
                    <button class="circles">30%</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="container" style="margin-left:20%;margin-bottom:40px;">
                        <div class="row">
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>Quiz Name</p>
                            </div>
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:#76add4">
                                <p>HTML Test-1</p>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>Total Questions</p>
                            </div>
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:#343433">
                                <p>10</p>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>Correct answer</p>
                            </div>
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:#18c93f">
                                <p>3</p>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>Wrong Answer</p>
                            </div>
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:#ff034d">
                                <p>7</p>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>Score</p>
                            </div>
                            <div class="col-lg-5" style="background-color:#f7f6f5;color:rgb(31, 17, 17)">
                                <p>3/10</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
