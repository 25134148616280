<div class="page-title-area-profile">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Profile</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="pricing-area">
    <div class="container" *ngIf="role == 1">
        <form>
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <h2 style="text-align: center; color: black;">Recruiter Details</h2>
                    <!-- <div class="page-content page-container" id="page-content"> -->
                    <div class="padding">
                        <!-- <div class="row container d-flex justify-content-center"> -->
                        <!-- <div class="col-xl-6 col-md-12"> -->
                        <div class="card user-card-full" *ngFor="let item of profilearr">
                            <div class="row m-l-0 m-r-0">
                                <div class="col-sm-4 bg-c-lite-green user-profile">
                                    <div class="card-block text-center text-white">
                                        <div class="m-b-25">
                                            <img src="{{item.file}}" class="img-radius " alt="User-Profile-Image">
                                        </div>
                                        <h6 class="f-w-600">{{item.company_name}}</h6>
                                        <p>{{item.industry_type}}</p>
                                        <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                                    </div>
                                </div>
                                <div class="col-sm-8" style="    background: #e2ffef;">
                                    <div class="card-block">
                                        <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h3>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Email</p>
                                                <h6 class="text-muted f-w-400"> {{item.recruiter_email}} </h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Phone</p>
                                                <h6 class="text-muted f-w-400">{{item.phone_number}}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Address</p>
                                                <h6 class="text-muted f-w-400"> {{item.address}} </h6>
                                            </div>
                                        </div>
                                        <!-- <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Projects</h6>
                                                                                    <div class="row">
                                                                                        <div class="col-sm-6">
                                                                                            <p class="m-b-10 f-w-600">Recent</p>
                                                                                            <h6 class="text-muted f-w-400">Sam Disuja</h6>
                                                                                        </div>
                                                                                        <div class="col-sm-6">
                                                                                            <p class="m-b-10 f-w-600">Most Viewed</p>
                                                                                            <h6 class="text-muted f-w-400">Dinoter husainm</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ul class="social-link list-unstyled m-t-40 m-b-10">
                                                                                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="facebook" data-abc="true"><i class="mdi mdi-facebook feather icon-facebook facebook" aria-hidden="true"></i></a></li>
                                                                                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="twitter" data-abc="true"><i class="mdi mdi-twitter feather icon-twitter twitter" aria-hidden="true"></i></a></li>
                                                                                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="instagram" data-abc="true"><i class="mdi mdi-instagram feather icon-instagram instagram" aria-hidden="true"></i></a></li>
                                                                                    </ul> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                    <!-- </div> -->
                    <!-- </div> -->

                    <!-- <div class="job-item" *ngFor="let i of profilearr">
                        <div class="row">
                            <div class="col-lg-4">
                                <img src="{{i.file}}" alt="img" style="width:60px; height: 30px;">
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Company Name:
                                        &nbsp;&nbsp;</span>{{i.company_name}}</span>&nbsp;&nbsp;&nbsp;&nbsp;

                            </div>
                        </div>

                            <div class="col-lg-12">
                                </div>

                        <div class="row">
                            <div class="col-lg-4">

                                <span><span style="font-weight: bold;">Recruiter Name:&nbsp;&nbsp;</span>
                                    {{i.recruiter_name}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Recruiter
                                        Email:&nbsp;&nbsp;</span>{{i.recruiter_email}}</span>&nbsp;&nbsp;<br>
                            </div>
                            <div class="col-lg-4">
                                <span><span style="font-weight: bold;">Phone
                                        Number:&nbsp;&nbsp;</span>{{i.phone_number}}</span>&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span>
                                    <span
                                        style="font-weight: bold;">Address:&nbsp;&nbsp;</span>{{i.address}}</span>&nbsp;&nbsp;<br>
                            </div>
                            <div class="col-lg-4">
                                <span><span style="font-weight: bold;">Industry
                                        Type:&nbsp;&nbsp;</span>{{i.industry_type}}</span>&nbsp;&nbsp;
                            </div>

                        </div>
                    </div> -->
                </div>

                <div class="col-sm-12 col-lg-12" style="margin-bottom: 10%;">
                    <h2 style="text-align: center; color: black;">Recruiter Payment Details</h2>

                    <div class="padding">
                        <!-- <div class="row container d-flex justify-content-center"> -->
                        <!-- <div class="col-xl-6 col-md-12"> -->
                        <div class="card user-card-full" *ngFor="let item of paymentarr">
                            <div class="row m-l-0 m-r-0">
                                <div class="col-sm-4 bg-c-lite-green user-profile">
                                    <div class="card-block text-center text-white">
                                        <div class="m-b-25">
                                            <img src="{{item.img_url}}" class="img-radius " alt="User-Profile-Image">
                                        </div>
                                        <h6 class="f-w-600">{{item.company_name}}</h6>
                                        <!-- <p>{{item.industry_type}}</p> -->
                                        <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                                    </div>
                                </div>
                                <div class="col-sm-8" style="    background: #e2ffef;">
                                    <div class="card-block">
                                        <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Package Information</h3>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Package Name</p>
                                                <h6 class="text-muted f-w-400"> {{item.recruiter_email}} </h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Package Amount</p>
                                                <h6 class="text-muted f-w-400">{{item.packageamount}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Experience</p>
                                                <h6 class="text-muted f-w-400">{{item.experience}}years</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Salary</p>
                                                <h6 class="text-muted f-w-400">{{item.salary}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">How Many Resumes:</p>
                                                <h6 class="text-muted f-w-400">{{item.uploadresume}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Location Name</p>
                                                <h6 class="text-muted f-w-400">{{item.location_name}}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Remarks</p>
                                                <h6 class="text-muted f-w-400"> {{item.description}} </h6>
                                            </div>
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Description</p>
                                                <h6 class="text-muted f-w-400"> {{item.remarks}} </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="job-item" *ngFor="let i of paymentarr">
                        <div class="row">
                            <div class="col-lg-4">
                                <img src="{{i.img_url}}" alt="img" style="width:60px; height: 60px;">
                            </div>

                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;"> Company Name:
                                        &nbsp;&nbsp;</span>{{i.company_name}}&nbsp;&nbsp;</span>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">

                                <span><span style="font-weight: bold;">Remarks:&nbsp;&nbsp; &nbsp;&nbsp;</span>
                                    {{i.description}}</span>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Location
                                        Name:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.location_name}}</span>&nbsp;&nbsp;&nbsp;<br>
                            </div>
                            <div class="col-lg-4">
                                <span><span style="font-weight: bold;">Salary:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.salary}}</span>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Experience:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.experience}}years</span>&nbsp;&nbsp;&nbsp;<br>
                            </div>
                            <div class="col-lg-4">
                                <span><span style="font-weight: bold;">Package
                                        Name:&nbsp;&nbsp; &nbsp;&nbsp;</span>{{i.packagename}}</span>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Package
                                        Amount:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.packageamount}}</span>&nbsp;&nbsp;&nbsp;<br>
                            </div>
                            <div class="col-lg-4">
                                <span><span style="font-weight: bold;">How Many
                                        Resumes:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.uploadresume}}</span>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-6">
                                <span><span style="font-weight: bold;">Description:&nbsp;&nbsp;
                                        &nbsp;&nbsp;</span>{{i.remarks}}</span>&nbsp;&nbsp;&nbsp;<br>
                            </div>

                        </div>
                    </div> -->

                </div>
            </div>

        </form>
    </div>
</div>

<div class="pricing-area" *ngIf="role == 0">
    <div class="page-content container page-container" id="page-content">
        <div class="padding">
            <!-- <div class="row container d-flex justify-content-center">
<div class="col-xl-6 col-md-12"> -->
            <div class="card user-card-full" *ngFor="let item of studentdata">
                <div class="row m-l-0 m-r-0">
                    <div class="col-sm-4 bg-c-lite-green user-profile">
                        <div class="card-block text-center text-white">
                            <div class="m-b-25">
                                <img src="https://img.icons8.com/bubbles/100/000000/user.png" class="img-radius"
                                    alt="User-Profile-Image">
                            </div>
                            <h6 class="f-w-600">Name: {{item.name}}</h6>
                            <p>Role: {{item.job_title}}</p>
                            <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                        </div>
                    </div>
                    <div class="col-sm-8" style="    background: #e2ffef;">
                        <div class="card-block">
                            <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h3>
                            <div class="row">
                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Email</p>
                                    <h6 class="text-muted f-w-400"> {{item.email}} </h6>
                                </div>
                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Mobile Number</p>
                                    <h6 class="text-muted f-w-400">{{item.phone_number}}</h6>
                                </div>
                                <!-- <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Passed out year</p>
                                                                        <h6 class="text-muted f-w-400">{{item.year_of_passing}}</h6>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Percentage</p>
                                                                        <h6 class="text-muted f-w-400">{{item.percentage}}</h6>
                                                                    </div> -->
                                <!-- <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Qualification</p>
                                                                        <h6 class="text-muted f-w-400">{{item.qualification}}</h6>
                                                                    </div> -->
                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Address</p>
                                    <h6 class="text-muted f-w-400">{{item.address}}</h6>
                                </div>

                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">No of Tests completed</p>
                                    <h6 class="text-muted f-w-400">{{getexamtest.length}}</h6>
                                </div>
                            </div>
                            <!-- <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Projects</h6> -->
                            <div class="row">

                                <div class="col-sm-12">
                                    <p class="m-b-10 f-w-600" style="margin-bottom: 15px;">Skills</p>
                                    <!-- <h6 class="text-muted f-w-400"> -->
                                    <span class="chip" *ngFor="let data of item.skills">{{data.skills}}</span>

                                    <!-- </h6> -->
                                </div>
                                <div class="col-sm-6">
                                    <!-- <p class="m-b-10 f-w-600">Most Viewed</p> -->
                                    <!-- <h6 class="text-muted f-w-400">Dinoter husainm</h6> -->
                                </div>
                            </div>
                            <ul class="social-link list-unstyled m-t-40 m-b-10">
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="facebook" data-abc="true"><i
                                            class="mdi mdi-facebook feather icon-facebook facebook"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="twitter" data-abc="true"><i
                                            class="mdi mdi-twitter feather icon-twitter twitter"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="instagram" data-abc="true"><i
                                            class="mdi mdi-instagram feather icon-instagram instagram"
                                            aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div>
                                             </div> -->
        </div>
    </div>
    <section class="job-area job-area-twos pt-100">
        <h3 style="text-align: center;color: green; margin-bottom: 15px;">Completed Tests</h3>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 " *ngFor="let i of getexamtest">
                    <!-- <h2 *ngIf="i.completed != 1 " style="color:red;text-align: center;">
                        No Scheduled exams/writtenedexams</h2> -->
                    <div class="job-item box">
                        
                        <!-- <div *ngIf="i.completed == 1" class="ribbon ribbon-top-left"><span>completed</span></div> -->
                        <div *ngIf="i.completed == 1" class="ribbon ribbon-top-right"><span>completed</span></div>
                        <!-- <div *ngIf="i.completed == 0" class="ribbon ribbon-top-right"><span>no data</span></div> -->
                        <!-- <div *ngIf="i.completed == 1" class="ribbon ribbon-bottom-left"><span>completed</span></div>
                        <div *ngIf="i.completed == 1" class="ribbon ribbon-bottom-right"><span>completed</span></div> -->
                        <img src="{{i.exam_imgurl}}" alt="img" style="width:60px; height: 60px;margin-top: 10%;">
                        <div class="job-inner align-items-center" style="padding-top: 35px;">
                            <div class="job-inner-left">
                                <h3 style="font-size:17px"><label>Exam Name:</label>
                                    {{i.exam_name}}</h3>
                                <label>Total Questions:</label><span>{{i.total_qns}}</span>&nbsp;<br>
                                <label>Exam Time Minutes:</label><span>{{i.exam_duration}}</span>&nbsp;<br>
                                <ul>
                                    <label>Total Marks:</label><span>{{i.total_marks}}</span>&nbsp;<br>
                                    <label>Description:</label><span>{{i.description}}</span>&nbsp;<br>
                                    <label>Total Score:</label><span>{{i.score}}</span>&nbsp;<br>
                                    <label>Total Percentage:</label><span>{{i.result}}% </span> &nbsp;<br>
                                    <label *ngIf="i.result >= 90">You are qualified</label><span *ngIf="i.result >= 90"
                                        (click)="genarateCert(i.date, i.exam_name)" class="blink-text">&nbsp; Download
                                        Certificate</span>&nbsp;<br>
                                </ul>
                            </div>
                            <div class="job-inner-right">
                                <ul>
                                    <li class="curse"><a (click)="startTest(i.id, i.total_qns, i.exam_duration)">{{
                                            i.completed == 0 ? 'Start Test': 'View Test'}}</a></li>
                                    <!-- <li class="curse"><a (click)="startTest(i.id, i.total_qns, i.exam_duration)">Start Test</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>