import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginModel } from 'src/app/core/core/model/login.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


var Api = 'https://recruitin.in:8084/nodeapp/';
// var Api:'http://localhost:2479/nodeapp/'

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {
  private dataShare = new BehaviorSubject(false);
  currentMessage = this.dataShare.asObservable();
  private shodata = new BehaviorSubject([]);
  currentdata = this.shodata.asObservable();
  private currentUserSubject: BehaviorSubject<LoginModel>;   
  public currentUser: Observable<LoginModel>;        
  loginData: LoginModel;
  loginDatastudent: LoginModel;                 


  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginModel>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  changeMessage(message: any) {
    this.dataShare.next(message);
  }

  public get currentUserValue(): LoginModel {
    return this.currentUserSubject.value;
  }

  // Get Otp for user dashboard //
  getdashotp(phone) {
    return this.http.post<any>(Api + 'getdashotpweb', phone)
  }

  // Login method for submitting login form data value

  login(signInData: LoginModel) {
    // console.log(usr_nm,usr_pwd);
    return this.http.post<any>(Api + 'getUserwebData', signInData)
      .pipe(map(user => {
        console.log(user);
        if (user.status == 202) {
          this.errorMessageAlert('Please Enter Valid OTP')
        }

        if (!user.data.length) {
          this.errorMessageAlert("You don’t have any permissions please contact Admin ");
          return user.data;
        }

        if (user.status == 200) {
          this.loginData = user.data;
          localStorage.setItem('currentUser', JSON.stringify(this.loginData));
          localStorage.setItem('user_id', user.usr_data[0].id);
          localStorage.setItem('role', '1');
          localStorage.setItem('companyuser_id', user.usr_data[0].id);
          localStorage.setItem('newdata', JSON.stringify(this.loginData))
          this.currentUserSubject.next(this.loginData);
          this.changeMessage(true);
          return this.loginData;
        }
      }));
  }

  checkstudentotp(phone) {
    return this.http.post<any>(Api + 'checkstudentotp', phone)
  }

  departmentget() {
    return this.http.get<any>(Api + 'departmentget')
  }




  errorMessageAlert(message) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      timer: 1500
    })
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    sessionStorage.clear();

    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.changeMessage(false);
    this.router.navigate(['/login']);
  }

  getdashotpstudent(phone) {
    return this.http.post<any>(Api + 'getotpstudent', phone)
  }


  studentlogin(signInData: LoginModel) {
    return this.http.post<any>(Api + 'getUserDatastudent', signInData)
      .pipe(map(user => {
        console.log(user);
        if (user.status == 202) {
          this.errorMessageAlert('Please Enter Valid OTP')
        }
        if (!user.data.length) {
          this.errorMessageAlert("You don’t have any permissions please contact Admin ");
          return user.data;
        }

        if (user.status == 200) {
          this.loginDatastudent = user.data;

          localStorage.setItem('currentUser', JSON.stringify(this.loginDatastudent));
          localStorage.setItem('user_id', user.usr_data[0].id);
          localStorage.setItem('role', '0');
          localStorage.setItem('student_id', user.usr_data[0].id);
          localStorage.setItem('date', user.usr_data[0].i_ts);
          this.changeMessage(true);
          this.currentUserSubject.next(this.loginDatastudent);

          return this.loginDatastudent;
        }


      }));
  }

  getrecruiterdata(data) {
    return this.http.post<any>(Api + `getrecruiterdata`, data)

  }

  jobcategories() {
    return this.http.get<any>(Api + `jobcategories`);
  }
}





