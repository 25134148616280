import { Component, ElementRef, EventEmitter, HostListener, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { first, take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { LoginserviceService } from 'src/app/components/pages/login/loginservice.service';
import { CreateaccountserviceService } from '../create-account/createaccountservice.service';
import Swal from 'sweetalert2';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
  apiKey: "AIzaSyCDrGgdvJnQkZ5GDfkG-lk-d2v8tsOKqCM",
  authDomain: "recruitin-bd725.firebaseapp.com",
  projectId: "recruitin-bd725",
  storageBucket: "recruitin-bd725.appspot.com",
  messagingSenderId: "693544639002",
  appId: "1:693544639002:web:c5fa4d5cfbe6b31cc4cfa3",
  measurementId: "G-335TTWXJ3X"
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],


})
export class LoginComponent implements OnInit {
  imgArrray: any = [];
  candidateSignup: FormGroup;
  recruiterSignup: FormGroup;
  showstudent: boolean = false;
  timer$: Observable<number>;            // Timer For Re-Sending OTP
  loginForm: FormGroup;
  loginstudentForm: FormGroup;               // Form Group for Login Form
  submitted: boolean = false;
  submittedstudent: boolean = false;         // Submitted Value for login form errors validations
  error: string = '';           // Error Message Stored value
  returnUrl: string;                   // Return url when user was not login
  public showPassword: boolean = false;  // password toggle for input feild
  intialvalues: any;                      // Form Intial Values For Restting the Form
  otpget: boolean = false;
  otpgetstudent: boolean = false;             // Show Form feild after getting otp
  refresh: boolean = false;
  refreshstudent: boolean = false;         // Refresh Boolean Value
  time: any;                              // Time For Showing Otp
  showrecruiter: boolean = false;
  company: any;
  newarray: string;
  index: any = 0;
  dynoclass: boolean = false;
  showOverlay: boolean = false;
  submitteddata: boolean = false;
  logoArrray: any = [];
  skillarr: any = [];
  industry: any[] = [];
  allcategories: any[] = [];
  onggoingtender;
  filetype;
  skillform: FormGroup;
  regform: boolean = false;
  imgArrray1: any = [];
  @ViewChild('phoneInput') phoneInput: NgxMatIntlTelInputComponent;

  @Output() appClickOutside = new EventEmitter<void>();


  constructor(private ngZone: NgZone, private elementRef: ElementRef, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private loginservice: LoginserviceService, private serv: CreateaccountserviceService) {
    this.route.queryParams.subscribe(queryParams => {
      var idd = queryParams.id;
      if (idd == 111) {
        this.logoutAlert();
      }
    })
    this.getJobCategories();

    this.candidateSignup = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      date_of_birth: ['', Validators.required],
      phone: ['', [Validators.required]],
      job_title: ['', Validators.required],
      address: ['', Validators.required],
      gender: ['', Validators.required],
      qulafication: ['', Validators.required],
      year_of_passout: ['', Validators.required],
      percentage: ['', Validators.required],
      // city_name: ['', Validators.required],
      experience: ['', Validators.required],
      industry: [[''], Validators.required],
      function: [[''], Validators.required],
      resume_pdf: ['', Validators.required]
      // year_of_passout: ['', Validators.required],
      //  percentage: ['', Validators.required],
    });

    this.recruiterSignup = this.formBuilder.group({
      recruiter_name: ['', Validators.required],
      recruiter_email: ['', Validators.required],
      mobile_number: ['', Validators.required],
      company_name: ['', Validators.required],
      recruiter_address: ['', Validators.required],
      industry_type: ['', Validators.required],
      recruiter_description: ['', Validators.required],
    });


    this.skillform = this.formBuilder.group({
      skills: ['', Validators.required],
    });

    this.newarray = localStorage.getItem('newdata')

    var index = this.route.snapshot.paramMap.get('index');
    if (index == '1') {
      this.index = index;
      this.dynoclass = false;
    }
    if (index == '2') {
      this.index = index;
      this.dynoclass = true;
    }
    this.route.params.subscribe(params => {
      console.log(params);
      var index = this.route.snapshot.paramMap.get('index');
      if (index == 'null' || index == null) {
        this.index = 0;
        this.dynoclass = false;
      }
    });


    this.getDepartmentList()
  }


  logoutAlert() {
    Swal.fire({
      title: 'Do You Want to Logout',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Do it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logoutData();
      }
    })
  }

  logoutData() {
    this.loginservice.logout()
  }

  //   isCountryCodeDropdownOpen = false
  //   @HostListener('window:click', ['$event'])
  //   onWindowClick(event: MouseEvent): void {
  //     if (
  //       this.phoneInput.nativeElement.contains(event.target) &&
  //       !event.target['classList'].contains('iti-arrow')
  //     ) {
  //       // Clicked inside the component, do nothing
  //     } else {
  //       // Clicked outside the component, close the country code dropdown
  //       this.phoneInput.nativeElement.querySelector('.iti-arrow').click();
  //     }
  //   }
  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.appClickOutside.emit();
    }
  }

  closeCountryCodeDropdown(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.appClickOutside.emit();
    }
  }
  ngOnInit(): void {
    firebase.initializeApp(config);

    this.loginForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      usr_pwd: ['', [Validators.required]],
    });

    this.intialvalues = this.loginForm.value;
    this.loginstudentForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      usr_pwd: ['', [Validators.required]],
    });
    this.intialvalues = this.loginstudentForm.value;
  }
  get addUsersFormCnt() { return this.candidateSignup.controls; }

  get addUsersFormCnt1() { return this.recruiterSignup.controls; }
  onFileupload(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.onggoingtender = reader.result as string
      console.log(event.target.files[0].name);
      var file_name = event.target.files[0].name;
      var bfile_type3 = event.target.files[0].name.split('.');
      this.filetype = bfile_type3[1];
      this.logoArrray.push({ img: this.onggoingtender, file_name: event.target.files[0].name, file_type: bfile_type3[1] })
      console.log(this.logoArrray);
    }
    reader.readAsDataURL((event.target as HTMLInputElement).files[0])
  }

  getJobCategories() {
    this.loginservice.jobcategories().subscribe(res => {
      console.log(res);
      this.industry = res.data[0];
      this.allcategories = res.data[1];

    }, error => {
      this.showOverlay = false;
      //console.log(error)
    })
  }


  makeMove(clas) {
    this.dynoclass = clas;
  }
  signupcandidate() {
    this.showstudent = true;
    this.showrecruiter = false;
  }
  signuprecruiter() {
    this.showrecruiter = true;
    this.showstudent = false;

  }
  // return login form controls for error handling
  get f() { return this.loginForm.controls; }

  get f1() { return this.loginstudentForm.controls; }


  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  // Start Time After Getting Otp //
  startTimer(counter: number) {
    this.refresh = false;
    this.timer$ = timer(0, 1000).pipe(
      take(counter),
      map(_ => --counter, console.log(counter),
      )
    );
    this.time = setTimeout(() => {
      this.refresh = true;
    }, 30000);
  }
  //  Form submit

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    } else {
      this.submitted = true;
      this.showOverlay = true;
      this.loginservice.login(this.loginForm.value).subscribe(data => {
        this.showOverlay = false;
        console.log(data);
        if (data.length) {
          this.router.navigate(['/faq']);
        }
      },
        error => {
          this.showOverlay = false;
          this.error = error ? error : '';
        });
    }
  }

  // On ResetPassword link click
  onResetpassword2() {
    this.otpget = false;
    this.loginForm.reset(this.intialvalues);
    clearTimeout(this.time)
  }

  singup(index) {
    this.router.navigate(['/create-account', { id: 1, index: index }]);
  }

  // Modal Dissmiss Function
  modalDismiss() {
    // this.modalService.dismissAll()
  }

  data: any = [];
  otpdata: any = [];
  reCaptchaVerifier: any; verify: any; mainarr: any = [];


  // getOtpstudent() {
  //   this.submitted = true;
  //   if (this.loginstudentForm.get('phone').invalid) {
  //     return;
  //   }
  //   this.showOverlay = true;
  //   const data = {
  //     number: this.loginstudentForm.value.phone
  //   };
  //   this.loginservice.getdashotpstudent(data).subscribe(response => {
  //     this.showOverlay = false;
  //     if (response.status === 200) {
  //       this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //         'recaptcha-container',
  //         {
  //           size: 'invisible'
  //         }
  //       );
  //       firebase
  //         .auth()
  //         .signInWithPhoneNumber(this.loginstudentForm.value.phone, this.reCaptchaVerifier)
  //         .then(confirmationResult => {
  //           localStorage.setItem(
  //             'verificationId',
  //             JSON.stringify(confirmationResult.verificationId)
  //           );
  //           this.ngZone.run(() => {
  //             this.otpget = true;
  //             this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
  //             this.startTimer(30);
  //           });
  //         })
  //         .catch(error => {
  //           alert(error.message);
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 5000);
  //         });
  //       // Firebase

  //     } else if (response.status === 601) {
  //       this.loginservice.errorMessageAlert(response.errors);
  //     } else {
  //       this.loginservice.errorMessageAlert('Please Enter Valid Mobile Number');
  //     }
  //     this.submitted = false;
  //   }, error => {
  //     this.showOverlay = false;
  //   });
  // }


  getOtpstudent() {
    this.submitted = true;
    if (this.loginstudentForm.get('phone').invalid) {
      Swal.fire('Please Check Mobile  Number')
      return;
    }
    this.showOverlay = true;
    const data = {
      number: this.loginstudentForm.value.phone
    };
    this.loginservice.getdashotpstudent(data).subscribe(response => {
      this.showOverlay = false;
      if (response.status === 200) {
        this.otpget = true;
      } else if (response.status === 601) {
        this.loginservice.errorMessageAlert(response.errors);
      } else {
        this.loginservice.errorMessageAlert('Please Enter Valid Mobile Number');
      }
      this.submitted = false;
    }, error => {
      this.showOverlay = false;
    });
  }

  // Start Time After Getting Otp //
  startTimerstudent(counter: number) {
    this.refresh = false;
    this.timer$ = timer(0, 1000).pipe(
      take(counter),
      map(_ => --counter, console.log(counter),
      )
    );
    this.time = setTimeout(() => {
      this.refresh = true;
    }, 30000);
  }

  //  Form submit
  // studentSubmit() {
  //   this.submitted = true;
  //   if (this.loginstudentForm.invalid) {
  //     return;
  //   } else {
  //     var credential = firebase.auth.PhoneAuthProvider.credential(
  //       this.verify,
  //       this.loginstudentForm.value.usr_pwd
  //     );
  //     firebase
  //       .auth()
  //       .signInWithCredential(credential)
  //       .then((response) => {
  //         this.ngZone.run(() => {
  //           this.dashlogin();
  //         });
  //       })
  //       .catch((error) => {
  //         alert(error.message);
  //       });
  //     //fire base
  //   }
  // }

  studentSubmit() {
    this.submitted = true;
    if (this.loginstudentForm.invalid) {
      return;
    } else {
      this.loginservice.checkstudentotp(this.loginstudentForm.value).subscribe(response => {
        this.showOverlay = false;
        if (response.status === 200) {
          this.dashlogin();
        } else if (response.status === 601) {
          this.loginservice.errorMessageAlert(response.errors);
        } else {
          this.loginservice.errorMessageAlert('Please Enter Valid  OTP');
        }
        this.submitted = false;
      }, error => {
        this.showOverlay = false;
      });
    }
  }


  dashlogin() {
    this.showOverlay = true;
    this.loginservice.studentlogin(this.loginstudentForm.value).subscribe(data => {
      this.showOverlay = false;
      if (data.length) {
        this.router.navigate(['/']);
      }
    });
  }

  // On ResetPassword link click
  studentResetpassword2() {
    this.otpget = false;
    this.loginstudentForm.reset(this.intialvalues);
    clearTimeout(this.time);
  }

  getrecruiterdatalist() {
    var data = {
      'companyuser_id': localStorage.getItem('companyuser_id'),
    }
    this.loginservice.getrecruiterdata(data).subscribe(data => {
      if (data.status == 200) {
        this.company = data.data;
        console.log(data.data);
      }
      localStorage.setItem('newdata', JSON.stringify(this.company))
    }, er => {
      console.log(er);
    })

  }

  senddata() {
    this.loginservice.changeMessage(true);
  }

  //recruiter signup posts
  recruiterSignupPost() {
    console.log(this.recruiterSignup, this.recruiterSignup.invalid);

    this.submitteddata = true;
    if (this.recruiterSignup.invalid) {
      return;
    }
    var data = {
      'form': this.recruiterSignup.value,
      'logoArrray': this.logoArrray,
    }
    this.serv.recruiterSignupData(data).subscribe(res => {
      if (res.status == 200) {
        this.recruiterSignup = this.formBuilder.group({
          recruiter_name: ['', Validators.required],
          recruiter_email: ['', Validators.required],
          mobile_number: ['', Validators.required],
          company_name: ['', Validators.required],
          recruiter_address: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9][0-9]{9}$'),])],
          industry_type: ['', Validators.required],
          recruiter_description: ['', Validators.required],
        });
        alert('Submit Sucessfully');
        location.reload();
      }
    });
  }

  candidateSignupPost() {
    this.submitted = true;
    if (this.candidateSignup.invalid) {
      return;
    } else if (this.imgArrray.length == 0) {
      Swal.fire('Please Upload Resume')
    } else {
      this.candidateSignup.value.function = JSON.stringify(this.candidateSignup.value.function)
      this.candidateSignup.value.industry = JSON.stringify(this.candidateSignup.value.industry)
      var data = {
        'form': this.candidateSignup.value,
        'skillarr': this.skillarr,
        'imgArrray': this.imgArrray,
        'imgArrray1': this.imgArrray1,
      }
      console.log(data);
      this.serv.candidateSignupData(data).subscribe(res => {
        if (res.status == 200) {
          this.candidateSignup = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])],
            date_of_birth: ['', Validators.required],
            phone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9][0-9]{9}$'),])],
            job_title: ['', Validators.required],
            address: ['', Validators.required],
            gender: ['', Validators.required],
            // city_name: ['', Validators.required],
            experience: ['', Validators.required],
            industry: ['', Validators.required],
            function: ['', Validators.required],
            qulafication: ['', Validators.required],
            year_of_passout: ['', Validators.required],
            percentage: ['', Validators.required],
          });
          Swal.fire('Submit Successfully');
          location.reload();
        }
      })
    }
  }

  submitskill() {
    if (this.skillform.invalid) {
      return;
    } else {
      this.skillarr.push(this.skillform.value)
      this.skillform.reset();
    }
  }

  deleteskillnot(skills: any) {
    this.skillarr.map(obj => {
      if (obj.skills == skills) {
        var index = this.skillarr.indexOf(obj);
        this.skillarr.splice(index, 1);
      }
    })
  }

  getDepartment:any

  getDepartmentList() {
    this.serv.departmentget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getDepartment = Result.data;
        console.log(this.getDepartment);
      }
    })
  }

  onFileChange(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.onggoingtender = reader.result as string
      console.log(event.target.files[0].name);
      var file_name = event.target.files[0].name;
      var bfile_type3 = event.target.files[0].name.split('.');
      this.filetype = bfile_type3[1];
      this.imgArrray.push({ img: this.onggoingtender, file_name: event.target.files[0].name, file_type: bfile_type3[1] })
    }
    reader.readAsDataURL((event.target as HTMLInputElement).files[0])
  }

  onFileChange1(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.onggoingtender = reader.result as string
      console.log(event.target.files[0].name);
      var file_name = event.target.files[0].name;
      var bfile_type3 = event.target.files[0].name.split('.');
      this.filetype = bfile_type3[1];
      this.imgArrray1.push({ img: this.onggoingtender, file_name: event.target.files[0].name, file_type: bfile_type3[1] })
    }
    reader.readAsDataURL((event.target as HTMLInputElement).files[0])
  }

  openform(any) {
    if (any == 1) {
      this.regform = false
    }
    else if (any == 2) {
      this.regform = true
    } else {
      alert('Server Down')
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}

