import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ServiceService } from './../service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    name: any;
    id:any;
    dynoclass: boolean  = true;
    serviceForm: FormGroup;
    initialValues:any;
    submitted:boolean = false;
    fireandsafety:boolean  = false;
  constructor(private route: ActivatedRoute, private formbuilder: FormBuilder, private serv: ServiceService) {
     this.name = this.route.snapshot.queryParamMap.get('name');
     const filter = this.route.snapshot.queryParamMap.get('filter');
    //  this.name = this.route.queryParams.
     console.log(this.name);
     this.route.queryParams.subscribe(queryParams => {
        this.name = queryParams.name;
        this.id = queryParams.id;
        if (this.id==3){
          this.fireandsafety=true;
        }else{
          this.fireandsafety= false;
        }
    })

     this.serviceForm = this.formbuilder.group({
        name: ["", Validators.required],
        email: ["",Validators.compose([ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])],
        number: ["", [Validators.required, Validators.minLength(10)]],
      });
      this.initialValues = this.serviceForm;
   }

  ngOnInit(): void {
  }

  get addUsersFormCnt(){
    return this.serviceForm.controls
  }

  servicedatasubmit(){
    this.submitted  = true;
    if(this.serviceForm.invalid){
      return;
    }
    this.serviceForm.value.id = this.route.snapshot.queryParamMap.get('id');
    this.serviceForm.value.sname = this.name;
      console.log(this.serviceForm.value)
      this.serv.servicedatasubmit(this.serviceForm.value).subscribe(
        res => {
          if (res.status == 200) {
            this.serviceForm.reset(this.initialValues);
            this.submitted  = false;
            // this.contactUs = this.form.group({
            //   name: ['', [Validators.required]],
            //   number: ['', [Validators.required]],
            // });
            this.sucessAlert('Data Submitted Sucessfully ');
            // alert('User Added Sucessfully');
          }

        }, err => {    this.submitted  = false;})

  }
  //validations
numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  sucessAlert(message) {
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  }
}
