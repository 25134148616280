import { Component, OnInit, ViewChild } from '@angular/core';
// import {MatProgressBarModule} from '@angular/material';

import { Options } from 'ng5-slider';
import { ServiceService } from '../service.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginserviceService } from '../login/loginservice.service';

@Component({
  selector: 'app-find-a-job',
  templateUrl: './find-a-job.component.html',
  styleUrls: ['./find-a-job.component.scss']
})
export class FindAJobComponent implements OnInit {

  jobassignpostform:FormGroup;
  getDepartment: any = [];
  getLocation: any = [];
  qualification: any = [];
  joblist: any = [];
  joblist1:any = [];
  globaljoblist: any = [];
  experiences: any = [];
  salary: any = [];
  departenments: any = [];
  locations: any = [];
  qualifications: any = [];
  industry: any[] = [];
  allcategories: any[] = [];
  postedu: any[] = [];
  phdedu: any[] = [];
  public alljobs: Array<any> = [
    // { img1: "assets/img/home-2/TECH-M.png", Companyname: "Tech Mahindra presents an excellent opportunity For Backend Developer", Salary: "250000 - 300000 P.A.", Location: "Hyderabad", Department: "Softwarejobs", Experience: "0-1years", Qualification: "B.tech" },
    // { img1: "assets/img/home-1/jobs/4.png", Companyname: "Aproova presents an excellent opportunity For Sales Manager", Salary: "300000 - 350000 P.A.", Location: "Chennai", Department: "Softwarejobs", Experience: "2-3years", Qualification: "M.tech" },
    // { img1: "assets/img/home-2/cognizant.png", Companyname: "Cognizant presents an excellent opportunity For Digital Marketing", Salary: "350000 - 400000 P.A.", Location: "Vizag", Department: "Softwarejobs", Experience: "3-4years", Qualification: "MCA" },
    // { img1: "assets/img/home-2/WIPRO.png", Companyname: "Wipro presents an excellent opportunity For Frontend Developer", Salary: "400000 - 450000 P.A.", Location: "Kakinada", Department: "Softwarejobs", Experience: "0-5years", Qualification: "BA" },
    // { img1: "assets/img/home-2/capgemini.png", Companyname: "Cap Gemini presents an excellent opportunity For  Business Development", Salary: "250000 - 300000 P.A.", Location: "Hyderabad", Department: "TechnicalSupport", Experience: "0-1years", Qualification: "B.COM" },
    // { img1: "assets/img/home-2/WIPRO.png", Companyname: "Wipro presents an excellent opportunity For Frontend Developer", Salary: "400000 - 450000 P.A.", Location: "Kakinada", Department: "BusinessDevelopment", Experience: "0-5years", Qualification: "Any Graduate" }
  ];
  @ViewChild('progressBar') progressBar: any;
  collapse: boolean = true;
  collapse1: boolean = true;
  collapse2: boolean = true;
  collapse3: boolean = true;
  collapse4: boolean = true;
  collapse5: boolean = true;
  collapse6: boolean = true;
  value: number = 50;
  maxvalue: number = 70;

  options: Options = {
    floor: 1,
    ceil: 10
  };

  value1: number = 123;
  expvalue: number = 456;

  option: Options = {
    floor: 0,
    ceil: 10
  };

  software: any = [];

  jobexperience: any;
  constructor(private serv: ServiceService, private fvv:FormBuilder, private router: Router, private loginservice: LoginserviceService) {
    this.getJobCategories();
  }
  ngOnInit(): void {
    this.getDepartmentList();
    this.getLocationList();
    this.geQualificationList();
    this.getjoblist();
    this.getSalaryList();
  }

  getJobCategories(){
    this.loginservice.jobcategories().subscribe(res => {
        console.log(res);
        this.industry = res.data[1];
        this.postedu = res.data[4];
        this.phdedu = res.data[5];
        this.allcategories =  res.data[1];

    }, error => {
        // this.showOverlay = false;
      //console.log(error)
    })
  }

  getdepartmentlist(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Department == e) {
        this.software.push(obj)
      }
    })
  }


  // getSelectedJobList(e) {
  //   this.joblist = []
  //   console.log(e);
  //   this.alljobs.map(obj => {
  //     if (obj.Department == e) {
  //       this.joblist.push(obj)
  //     }
  //   })
  // }
  getLocationlist(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Location == e) {
        this.software.push(obj)
      }
    })
  }
  getQualificationtList(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Qualification == e) {
        this.software.push(obj)
      }
    })
  }
  //veerababu code starts
  getSelectedJobList(jobtype, ind) {
    this.joblist = [];
    if (ind == 1) {
      this.globaljoblist.map(obj => {
        if (obj.department_name == jobtype) {
          this.joblist.push(obj)
        }

      })
    }
    else if (ind == 2) {
      this.globaljoblist.map(obj => {
        if (obj.location_name == jobtype) {
          this.joblist.push(obj)
        }

      })
    }
    else if (ind == 3) {
      this.globaljoblist.map(obj => {
        // console.log(obj.qualification_name +"=="+ jobtype);
        if (obj.qualification_name == jobtype) {
          this.joblist.push(obj)
        }
      })
    }
    else if (ind == 4) {
      this.globaljoblist.map(obj => {
        // console.log(obj.salary + "==" + jobtype);
        if (obj.salary == jobtype) {
          this.joblist.push(obj)
        }
      })
    }
    else if (ind == 5) {
        this.globaljoblist.map(obj => {
          // console.log(obj.salary + "==" + jobtype);
          if (obj.function_name == jobtype) {
            this.joblist.push(obj)
          }
        })
      }
      else if (ind == 6) {
        this.globaljoblist.map(obj => {
          // console.log(obj.salary + "==" + jobtype);
          if (obj.post_name == jobtype) {
            this.joblist.push(obj)
          }
        })
      }
      else if (ind == 7) {
        this.globaljoblist.map(obj => {
          // console.log(obj.salary + "==" + jobtype);
          if (obj.phd_name == jobtype) {
            this.joblist.push(obj)
          }
        })
      }
      else if (ind == 8) {
        var ind = jobtype.indexOf('-');
        var start = jobtype.slice(ind-1, ind) * 100000;
        console.log(jobtype);

        var end   = jobtype.slice(ind+1, ind+2) *100000;
        console.log(start, end);

        this.globaljoblist.map(obj => {
          // console.log(obj.salary + "==" + jobtype);
          if (obj.salary * 1 <= end && obj.salary * 1 >= start) {
            this.joblist.push(obj)
          }
        })
      }
  }

  getDepartmentList() {
    this.serv.departmentget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getDepartment = Result.data;
        console.log(this.getDepartment);
      }
    })
  }
  getLocationList() {
    this.serv.locationget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getLocation = Result.data;
        console.log(this.getLocation);
      }
    })
  }
  geQualificationList() {
    this.serv.qualificationget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.qualification = Result.data;
        console.log(this.qualification);
      }
    })
  }
  getSalaryList() {
    this.serv.getsalary().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.salary = Result.data;
        console.log(this.salary);
      }
    })
  }

  // getqualificationList() {
  //   this.serv.qualificationget().subscribe((Result: any) => {
  //     if (Result.status == 200) {
  //       this.experiences = Result.data;
  //       console.log(this.experiences);
  //     }

  //   })
  // }
  getjoblist() {
    this.serv.joblistget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.joblist = Result.data;
        console.log(this.joblist);

        this.globaljoblist = Result.data;
        console.log(this.globaljoblist);
      }
    })
  }

  getexperiencedata(value1) {
    this.joblist1 = [];
    this.serv.jobexperience(value1).subscribe((Result: any) => {
      if (Result.status == 200) {
        this.joblist = Result.data;
        console.log(this.joblist);
      }
    })
  }

  // getgloballist() {
  //   this.serv.joblistget().subscribe((Result: any) => {
  //     if (Result.status == 200) {

  //       console.log(this.globallist);
  //     }
  //   })
  // }


  // getSelectedJobList(e) {
  //   this.joblist = []
  //   console.log();
  //   this.joblist.map(obj => {
  //     if (obj.Department == e) {
  //       this.joblist.push(obj)
  //     }
  //   })
  // }
  //veerababu code ends

  jobassignstudent(company){

    if (localStorage.getItem('role') == '0') {
        var name;
        if (localStorage.getItem('currentUser')) {
           name =  JSON.parse(localStorage.getItem('currentUser'))[0].name
        } else {
            name = 'Testing'
        }
        var data = {
            'company' : company,
             'student_id' : localStorage.getItem('student_id'),
             'date'   : localStorage.getItem('date'),
             'name':name

          }
          console.log(data);

          this.serv.jobassignstudentdata(data).subscribe(res => {
            if (res.status == 300) {
              this.sucessAlert('Already Applied With This Job');
            }else{

               this.sucessAlert('Applied Sucessfully');

             }
        })
    } else {
        this.router.navigate(['/login', {index: 1}]);
    }


}

sucessAlert(message) {
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  }

}
