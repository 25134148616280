import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BlogDetailsComponent } from 'src/app/components/pages/blog-details/blog-details.component';

@Injectable({
  providedIn: 'root'
})
export class ChangesGuard implements CanDeactivate<unknown> {
  canDeactivate(
    component: BlogDetailsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            // if there are no pending changes, just allow deactivation; else confirm first
            return component.canDeactivate() ?
              true :
              // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
              // when navigating away from your angular app, the browser will show a generic warning message
              // see http://stackoverflow.com/a/42207299/7307355
              confirm('WARNING: You have not submitted the Test. Press Cancel to go back and submit the Test, or OK to the test submitted automatically .');
  }

}
