import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceService } from 'src/app/components/pages/packages/service.service';
import { LoginserviceService } from './../login/loginservice.service';
import Swal from 'sweetalert2';
declare var Razorpay: any;        //rezor pay
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  options: any = []        //rezor pay
  newarray: string;
  companyarr: any;
  companydetails: any;
  companydetailsimg: any;
  newpackagedatails: string;
  packagedatails: any;
  addjobpackageForm: FormGroup;
  departmentform: FormGroup;
  addjobLocationForm: FormGroup;
  addjobEducationForm: FormGroup;
  addjobpostEducationForm: FormGroup;
  addjobphdEducationForm: FormGroup;
  functionform: FormGroup;
  imagesData: any[] = [];
  company_name: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  description: string;
  rating: string;
  location_name: string;
  salary: string;
  experience: string;
  submitted: boolean = false;
  showicon: boolean = false;
  onggoingtender: string;
  filetype: any;
  imgArrray: any = [];
  banner;
  departments: any = [];
  registrationForm: FormGroup;
  department_name: string;
  selected: any = [];
  location: any = [];
  Education: any = [];
  websitelist: MatTableDataSource<unknown>;
  displayedColumns1: string[] = ['ID', 'dpmt_id', 'action'];
  displayedColumns2: string[] = ['ID', 'loc_id']
  Locations: any = [];
  locationlist: any;
  Educationlist: any;
  Educations: any = [];
  industry: any = [];
  allcategories: any = [];
  postEducations: any = [];
  PostEducation: any = [];
  PhdEducation: any = [];
  phdEducations: any = [];
  functionData: any = [];
  functiondata: any = [];
  companyuser_id: string;
  finalgrandtotal: number;
  join_type: string;
  showSpinner: boolean;


  constructor(private formbuilder: FormBuilder, private packageser: ServiceService, private loginservice: LoginserviceService) {
    this.getJobCategories();
    this.addjobpackageForm = this.formbuilder.group({
      job_title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      join_type: ['', [Validators.required]],
      location_name: ['', [Validators.required]],
      salary: ['', [Validators.required]],
      experience: ['', [Validators.required]],
      location_id: ['']
    })
    this.registrationForm = this.formbuilder.group({
      department_name: ['', [Validators.required]],
    })
    this.departmentform = this.formbuilder.group({
      department_name: ['', [Validators.required]],
    })
    this.addjobLocationForm = this.formbuilder.group({
      location_name: ['', [Validators.required]],
    })
    this.addjobEducationForm = this.formbuilder.group({
      Education_name: ['', [Validators.required]],
    })
    this.addjobpostEducationForm = this.formbuilder.group({
      name: ['', [Validators.required]],
    })
    this.addjobphdEducationForm = this.formbuilder.group({
      name: ['', [Validators.required]],
    })
    this.functionform = this.formbuilder.group({
      function_name: ['', [Validators.required]],
    })
    this.newarray = localStorage.getItem('newdata')
    this.newpackagedatails = localStorage.getItem('newpackage')
    this.packagedatails = JSON.parse(this.newpackagedatails);
    this.companyarr = JSON.parse(this.newarray)
  }

  ngOnInit(): void {
    this.getdepartments();
    this.getlocations();
    this.geteducation();
    this.getDepartmentList()
  }
  getJobCategories() {
    this.loginservice.jobcategories().subscribe(res => {
      console.log(res);
      this.industry = res.data[0];
      this.allcategories = res.data[1];
      this.postEducations = res.data[4];
      this.phdEducations = res.data[5];
      this.functiondata = res.data[1];
    })
  }

  onFileChange(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.onggoingtender = reader.result as string
      console.log(event.target.files[0].name);
      var file_name = event.target.files[0].name;
      var bfile_type3 = event.target.files[0].name.split('.');
      this.filetype = bfile_type3[1];
      this.imgArrray.push({ img: this.onggoingtender, file_name: event.target.files[0].name, file_type: bfile_type3[1] })
      console.log(this.imgArrray);
    }
    reader.readAsDataURL((event.target as HTMLInputElement).files[0])
  }

  resetForm() {
    this.submitted = false;
  }
  get addUsersFormCnt() { return this.addjobpackageForm.controls; }
  // Accept Input As a Number Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getdepartments() {
    this.packageser.getdepartment().subscribe(data => {
      if (data.status == 200) {
        this.departments = data.data;
        console.log(data.data);
      }
    }, er => {
      console.log(er);
    })

  }

  submitdepartmentdata() {
    console.log(this.departmentform.value);
    var departmentdata = this.departmentform.value.department_name.split(',');
    var coco = this.selected.filter(res => {
      return res.department_id == departmentdata[1]
    })
    if (coco.length || departmentdata[1] == 'undefined' || departmentdata[1] == undefined) {
      return;
    }
    this.selected.push({ department_name: departmentdata[0], department_id: departmentdata[1] })
  }

  getlocations() {
    this.packageser.getlocationdata().subscribe(data => {
      if (data.status == 200) {
        this.Locations = data.data;
      }
    }, er => {
      console.log(er);
    })

  }
  addjobEducation() {
    var Educationdata = this.addjobEducationForm.value.Education_name.split(',');
    var coco = this.Education.filter(res => {
      return res.Education_id == Educationdata[1]
    })
    if (coco.length || Educationdata[1] == 'undefined' || Educationdata[1] == undefined) {
      return;
    }
    this.Education.push({ Education_name: Educationdata[0], Education_id: Educationdata[1] })
  }

  addjobpostEducation() {
    var Educationdata = this.addjobpostEducationForm.value.name.split(',');
    var coco = this.PostEducation.filter(res => {
      return res.id == Educationdata[1]
    })
    if (coco.length || Educationdata[1] == 'undefined' || Educationdata[1] == undefined) {
      return;
    }
    this.PostEducation.push({ name: Educationdata[0], id: Educationdata[1] })
  }

  addjobphdEducation() {
    var Educationdata = this.addjobphdEducationForm.value.name.split(',');
    var coco = this.PhdEducation.filter(res => {
      return res.id == Educationdata[1]
    })
    if (coco.length || Educationdata[1] == 'undefined' || Educationdata[1] == undefined) {
      return;
    }
    this.addjobphdEducationForm.value.name = Educationdata[0]
    this.addjobphdEducationForm.value.id = Educationdata[1]
    this.PhdEducation.push({ name: Educationdata[0], id: Educationdata[1] })
  }

  submitfunctiondata() {
    var Educationdata = this.functionform.value.function_name
    var function_name = Educationdata.name
    var id = Educationdata.id
    var coco = this.functionData.filter(res => {
      return res.id == id
    })
    if (coco.length || id == 'undefined' || id == undefined) {
      return;
    }
    this.functionData.push({ function_name: function_name, id: id })
  }

  geteducation() {
    this.packageser.geteducationdata().subscribe(data => {
      if (data.status == 200) {
        this.Educations = data.data;
        console.log(data.data);
      }
    }, er => {
      console.log(er);
    })

  }

  deletedepartment(department_name: any) {
    this.selected.map(obj => {
      if (obj.department_name == department_name) {
        console.log(this.selected);
        console.log(obj.department_name == department_name);
        var index = this.selected.indexOf(obj);

        this.selected.splice(index, 1);
        console.log(this.selected);

      }
    })
  }

  deleteEducation(Education_name: any) {
    this.Education.map(obj => {
      if (obj.Education_name == Education_name) {
        var index = this.Education.indexOf(obj);
        this.Education.splice(index, 1);
      }
    })
  }

  deletepostEducation(name: any) {
    this.PostEducation.map(obj => {
      if (obj.name == name) {
        var index = this.PostEducation.indexOf(obj);
        this.PostEducation.splice(index, 1);
      }
    })
  }

  deletephdEducation(name: any) {
    this.PhdEducation.map(obj => {
      if (obj.name == name) {
        var index = this.PhdEducation.indexOf(obj);
        this.PhdEducation.splice(index, 1);
      }
    })
  }

  deletedfunction(function_name: any) {
    this.functionData.map(obj => {
      if (obj.function_name == function_name) {
        var index = this.functionData.indexOf(obj);
        this.functionData.splice(index, 1);
      }
    })
  }

  // submitbuypackage
  rezorpay() {

    var locationdata = this.addjobpackageForm.value.location_name.split(',')


    this.addjobpackageForm.value.location_name = locationdata[0]
    this.addjobpackageForm.value.location_id = locationdata[1]
    this.addjobpackageForm.value.order_from = 1;
    console.log(this.addjobpackageForm.value);


    var data = {
      
      'form': this.addjobpackageForm.value,
      'departmentarr': this.selected,
      'functionData': this.functionData,
      'educationarr': this.Education,
      'PostEducation': this.PostEducation,
      'PhdEducation': this.PhdEducation,
      'companyarr': this.companyarr,
      'packagedatails': this.packagedatails,
      'key': "rzp_test_yM0gx8bfRVzuRI",
      'secret_key': "G2qlkDNX6QiYeSDECjhU7ADq",
      'razorpay_ids': "F",
      "amount": (this.packagedatails.packageamount * 1) * (100 * 1)
    }
    console.log(data, "tested");
    this.packageser.addbuypackage(data).subscribe(res => {
      console.log(res)
      console.log(res.orderId.id)
      this.options = {
        "name": "Svenly",
        "description": "Online Payment",
        "image": "../../assets/images/temple/logo.png",
        "order_id": res.orderId.id,
        "handler": (transaction: any, response: any) => {
          console.log(transaction, response)
          this.ticketconfrim(transaction.razorpay_payment_id, "S", res.insertId)
          console.log(transaction.razorpay_payment_id,)
        },
        "prefill": {
          "name": this.companyarr[0].recruiter_name,
          "contact": this.companyarr[0].phone_number
        },
        "notes": {
          "address": "testing area",
          "email": "teast@gmail.com"
        },
        "theme": {
          "color": "rgb(56 167 69)"
        },
        modal: {
          ondismiss: () => {
            this.cancelled();
            window.location.reload();
          }
        }
      }
    })
  }
  ticketconfrim(payment_id: any, statuscode: any, id: any) {
    this.showSpinner = true;
    var data = {
      payment_id: payment_id,
      id: id,
      statuscode: statuscode,
    }
    console.log(data)
    this.packageser.RazarpayStatuscode(data).subscribe(res => {
      this.showSpinner = false;
      if (res.status == 200) {
        if (statuscode == "S") {
          Swal.fire(
            'Payment Confrimed .. !',
            'success'
          )
          this.addjobpackageForm.reset();
          this.registrationForm.reset()
          this.departmentform.reset()
          this.addjobLocationForm.reset()
          this.addjobEducationForm.reset()
          this.addjobpostEducationForm.reset()
          this.addjobphdEducationForm.reset()
          this.functionform.reset()
        }
      } else {
        alert('Invalid Data');
      }
    },
      error => {
        this.showSpinner = false;
      })
  }

  getDepartment: any

  getDepartmentList() {
    this.packageser.departmentget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getDepartment = Result.data;
        console.log(395, this.getDepartment);
      }
    })
  }

  cancelled() {
    Swal.fire(
      'Payment Cancelled .. !',
      'Cancelled'
    )
    this.addjobpackageForm.reset();
    this.registrationForm.reset()
    this.departmentform.reset()
    this.addjobLocationForm.reset()
    this.addjobEducationForm.reset()
    this.addjobpostEducationForm.reset()
    this.addjobphdEducationForm.reset()
    this.functionform.reset()
    window.location.reload();
  }

  ticketconfrims(payment_id: any, statuscode: any, id: any) {
    var data = {
      payment_id: payment_id,
      id: id,
      statuscode: statuscode,
    }
    console.log(data)
    this.packageser.RazarpayStatuscode1(data).subscribe(res => {
      this.showSpinner = false;
      if (res.status == 200) {
        if (statuscode == "C") {
          Swal.fire(
            'Payment Cancelled .. !',
          )
        }
      } else {
        alert('Invalid Data');
      }
    })
  }
  locationdata: any = []
  getlocation(event) {
    console.log(event.target.value);
    this.locationdata = this.addjobpackageForm.value.location_name.split(',')
    console.log(this.locationdata);
  }


  rezorpay2() {
    this.addjobpackageForm.value.location_name = this.locationdata[0]
    this.addjobpackageForm.value.location_id = this.locationdata[1]
    this.addjobpackageForm.value.order_from = 1;
    console.log(this.addjobpackageForm.value);

    var data = {
      'form': this.addjobpackageForm.value,
      'departmentarr': this.selected,
      'functionData': this.functionData,
      'educationarr': this.Education,
      'PostEducation': this.PostEducation,
      'PhdEducation': this.PhdEducation,
      'companyarr': this.companyarr,
      'packagedatails': this.packagedatails,
      'key': "rzp_live_gsBbyXLB9Li2J5",
      'secret_key': "DyddAzWwX4ju5MBx16pcB9ej",
      'status_razorpay': "F",
      "amount": (this.packagedatails.packageamount * 1) * (100 * 1),
      "paymentid": "1"
    }
    this.packageser.addbuypackage(data).subscribe(res => {
      this.options = {
        "key": "rzp_live_gsBbyXLB9Li2J5",
        "amount": (this.packagedatails.packageamount * 1) * (100 * 1),
        "name": "Recruitin",
        "description": "Online Payment",
        "image": "assets/img/logo/Footer-Logo.png",
        "order_id": "",
        "handler": (transaction: any, response: any) => {
          this.ticketconfrim(transaction.razorpay_payment_id, "S", res.insertId)
        },
        "prefill": {
          "name": this.companyarr[0].recruiter_name,
          "email": 'info@recruitin.in',
          "contact": this.companyarr[0].phone_number
        },
        "notes": {
          "address": ""
        },
        "theme": {
          "color": "rgb(56 167 69)"
        },
        modal: {
          ondismiss: (transaction: any) => {
            this.cancelled()
            this.ticketconfrims(transaction.razorpay_payment_id, "C", res.insertId);
          }
        }
      };
      var rzp1 = new Razorpay(this.options);
      rzp1.open();
    })
  }
}
