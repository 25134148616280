import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-securityadvice',
  templateUrl: './securityadvice.component.html',
  styleUrls: ['./securityadvice.component.scss']
})
export class SecurityadviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
