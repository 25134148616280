<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>1.Introduction</h2>
            <p>
                We Svenly Jobs Consultant Private Limited are committed to
                respecting your online privacy and recognize the need for
                appropriate protection and management of any personally
                identifiable information you share with us. This Privacy Policy
                ("Policy") describes how we collects, uses, discloses and
                transfers personal information of users through its websites and
                applications, including through www.recruitin.in, mobile
                applications and online services (collectively, the "Platform").
                This policy applies to those who visit the Platform, or whose
                information we otherwise receives in connection with its
                services (such as contact information of individuals associated
                with we including partners) (hereinafter collectively referred
                to as “Users”). For the purposes of the Privacy Policy, “You" or
                “Your” shall mean the person who is accessing the Platform.
            </p>
        </div>

        <div class="privacy-item">
            <h2>2.Types of Personal Information</h2>
            <p>
                Types of Personal Information collected by we "Personal
                information" (PI) - means any information relating to an
                identified or identifiable natural person including common
                identifiers such as a name, an identification number, location
                data, an online identifier or one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person and any other
                information that is so categorized by applicable laws. We
                collect information about you and/or your usage to provide
                better services and offerings.
            </p>
            <p>
                The Personal Information that we collect, and how we collect it,
                depends upon how you interact with us. We collect the following
                categories of Personal Information in the following ways:
            </p>

            <ul>
                <li>
                    <i class="icofont-simple-right"></i> we may collect your
                    Personal Information such as name, email address, password,
                    country/nationality, gender, current location, city, contact
                    number with country code and company/organization that you
                    are associated with, when the you sign up as a jobseeker or
                    an employer or you set up an alert on the Platform;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> information that one
                    would usually include in a resume, including name, contact
                    details including e-mail address and mobile number, date of
                    birth, work experience, educational qualifications, data
                    relating to your current and past employment and
                    remuneration or salary, a copy of your resume, photographs,
                    visa and work status etc. when you register on the Platform;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> information about the
                    services that you use and how you use them, including log
                    information and location information, when you are a user of
                    the services through the Platform
                </li>
                <li>
                    <i class="icofont-simple-right"></i> we may collect your
                    Personal Information such as name, age, contact details,
                    employment preferences, etc. through surveys and forms, when
                    you choose to participate in these surveys etc.;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> we may also collect
                    information about whether you are eligible for any
                    affirmative action programmes or policies, if you opt to
                    provide such information;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> when you communicate
                    with we or use the Platform to communicate with other Users
                    (such as partners), we collect information about your
                    communication and any information you choose to provide;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> when you visit the
                    Platform, we use cookies to automatically collect, store and
                    use technical information about your system and interaction
                    with our Platform;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> when you use a device
                    to access the Platform, we may collect technical or other
                    related information from the device as well as the device
                    location;
                </li>

                <li>
                    <i class="icofont-simple-right"></i> to the extent permitted
                    by law, we may record and monitor your communications with
                    us to ensure compliance with our legal and regulatory
                    obligations and our internal policies. This may include the
                    recording of telephone conversations.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> If you choose to sign
                    in with your social media account to access the Platform, or
                    otherwise connect your social media account with the
                    services of the Platform, you consent to our collection,
                    storage, and use, in accordance with this Privacy Policy, of
                    the information that you make available to us through the
                    social media interface. Please see your social media
                    provider's privacy policy and help Center for more
                    information about how they share information when you choose
                    to connect your account.
                </li>
            </ul>
        </div>

        <div class="privacy-item">
            <h2>3.How we may use your Personal Information</h2>
            <p>
                How we may use your Personal Information We will only use your
                personal data in a fair and reasonable manner, and where we have
                a lawful reason to do so. Our use of your personal data depends
                on the purpose for which you interact with us. We may process
                your Personal Information for the following purposes:
            </p>
            <p>
                You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with
                your browser, but please note that if you choose to erase or block your cookies, you will need to
                re-enter your original user ID and password to gain access to certain parts of the Platform.
            </p>
            <p>
                Tracking technologies may record information such as Internet domain and host names; Internet protocol
                (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times
                that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our
                Platform and the overall website experience. We may also analyse information that does not contain
                Personal Information for trends and statistics. For more information about our use of cookies please
                refer to our Cookie Policy .
            </p>


            <ul>
                <li>
                    <i class="icofont-simple-right"></i> Providing our services
                    and products to you including to send you job alerts,
                    relevant search results and recommended jobs and/or
                    candidates (as the case maybe);
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Providing career news & updates, career related promotions from
                    third party sites, premium services from partner sites via email/ SMS (Provided you subscribe to the
                    same. To unsubscribe, please visit your email preferences page)
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Protecting our Users and providing you with customer support;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> We use information collected from cookies and other
                    technologies, to improve your user experience and the overall quality of our services (for more
                    information please refer to paragraph 4 below). When showing you tailored ads, we will not associate
                    an identifier from cookies or similar technologies with sensitive categories, such as those based on
                    race, religion, sexual orientation or health.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Improving the Platform and its content to provide better
                    features and services.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Conducting market research and surveys with the aim of
                    improving our products and services.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Sending you information about our products and services for
                    marketing purposes and promotions;
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Preventing, detecting, investigating and taking action against
                    crimes (including but not limited to fraud and other financial crimes), any other illegal
                    activities, suspected fraud, or violations of our Terms of Use in any jurisdiction
                </li>
                <li>
                    <i class="icofont-simple-right"></i> To the extent required for identity verification, government
                    sanctions screening and due diligence checks.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Establishing, exercising or defending legal rights in
                    connection with legal proceedings (including any prospective legal proceedings) and seeking
                    professional or legal advice in relation to such legal proceedings.
                </li>
            </ul>
        </div>


        <div class="privacy-item">
            <h2>4.Cookies and Other Tracking Technologies</h2>
            <p>
                Some of our web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file
                that may be used, for example, to collect information about web-site activity. Some cookies and other
                technologies may serve to recall Personal Information previously indicated by a user. Most browsers
                allow you to control cookies, including whether or not to accept them and how to remove them.
            </p>
            <p>
                You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with
                your browser, but please note that if you choose to erase or block your cookies, you will need to
                re-enter your original user ID and password to gain access to certain parts of the Platform.
            </p>
            <p>
                Tracking technologies may record information such as Internet domain and host names; Internet protocol
                (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times
                that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our
                Platform and the overall website experience. We may also analyse information that does not contain
                Personal Information for trends and statistics. For more information about our use of cookies please
                refer to our Cookie Policy .
            </p>
        </div>

        <div class="privacy-item">
            <h2>5.The Basis/ Grounds which we rely on for collection and processing of your Personal Information:</h2>
            <p>
                The Basis/ Grounds which we rely on for collection and processing of your Personal Information:
                Your Personal Information is collected and processed by us based on the following legal grounds
                depending upon the nature of Personal Information and the purposes for which it is processed.

            </p>
            <p>
                Consent:
                we relies on your consent in order to process your Personal Information in certain situations. If we
                requires your consent to collect and process certain Personal Information, as per the requirements under
                the applicable data protection laws, your consent is sought at the time of collection of your Personal
                Information and such processing will only be performed where consent is secured.
            </p>
            <p>
                Compliance with a legal obligation:
                Your Personal Information may be processed by us, to the extent that such processing is necessary to
                allow us to comply with a legal obligation. An example of this would be if we is required to disclose
                your Personal Information to respond to a court order or is required to retain specific records for a
                fixed period to comply with requirements under any applicable law.

            </p>
        </div>

        <div class="privacy-item">
            <h2>6.Information Sharing and Disclosure</h2>
            <p>
                We restrict access to your Personal Information to employees who we believe reasonably need to know/or
                that information to fulfil their jobs to provide, operate, develop, or improve our products or services.

            </p>
            <p>
                we does not disclose, transfer or share your Personal Information with others except with:
            </p>

            <ul>
                <li>
                    <i class="icofont-simple-right"></i> Providing our services
                    and products to you including to send you job alerts,
                    relevant search results and recommended jobs and/or
                    candidates (as the case maybe);
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Providing career news & updates, career related promotions from
                    third party sites, premium services from partner sites via email/ SMS (Provided you subscribe to the
                    same. To unsubscribe, please visit your email preferences page)
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Protecting our Users and providing you with customer support;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Our affiliates and group companies to the extent required for
                    our internal business and/or administrative purposes and/or general corporate operations and for
                    provision of services;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Potential recruiters if we determine that your resume matches a
                    particular job description/ vacancy available with such recruiters. By registering on the Platform
                    and consenting to the terms of this Privacy Policy, you agree that we may contact you or forward
                    your resume to potential recruiters;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Third parties including enforcement, regulatory and judicial
                    authorities, if we determine that disclosure of your Personal Information is required to a) respond
                    to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend
                    against legal claims; or b) investigate, prevent, or take action regarding illegal activities,
                    suspected fraud, situations involving potential threats to the physical safety of any person,
                    violations of IEIL’s Terms of Use or as otherwise required by law;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> In the event of a merger, acquisition, financing, or sale of
                    assets or any other situation involving the transfer of some or all of our business assets we may
                    disclose Personal Information to those involved in the negotiation or transfer.
                </li>

                <li>
                    <i class="icofont-simple-right"></i> The Company does not provide any Personal Information to the
                    advertiser when you interact with or view a targeted advertisement. However, if you interact with or
                    view an advertisement, the advertiser may make certain assumptions and, in the process, learn
                    certain Personal Information about you. For instance, if you view and click an advertisement that is
                    targeted towards women in the age group 18- 24 from a specific geographic area, the advertiser may
                    assume that you meet the relevant criteria.
                    We do not intend to transfer Personal Information without your consent to third parties who are not
                    bound to act on our behalf unless such transfer is legally required.

                </li>
                <li>
                    <i class="icofont-simple-right"></i> If your Personal Information is transferred outside India, we
                    take the necessary steps to protect your Personal Information in accordance with applicable data
                    protection laws.
                </li>

            </ul>
        </div>


        <div class="privacy-item">
            <h2>7.Third Party Content:</h2>
            <p>
                Please be aware that the Platform sometimes contains links to other sites that are not governed by this
                Privacy Policy. Users may be directed to third-party sites for more information, such as advertisers,
                blogs, content sponsorships, vendor services, social networks, etc.
                We makes no representations or warranties regarding how your information is stored or used on
                third-party servers. We recommend that you review the applicable privacy statements and policies of each
                third-party site linked from the Platform to determine their use of your personal information.


            </p>
        </div>
        <div class="privacy-item">
            <h2>8.Children :</h2>
            <p>
                To use the Platform, you agree that you must be the minimum age (described in this paragraph below) or
                older.
                The minimum age for these purposes shall be eighteen (18), however if local laws require that you must
                be older for us to be able to lawfully provide the services over the Platform to you then that older age
                shall apply as the applicable minimum age.
                If you are under the age of 18 or the age of majority in your jurisdiction, you must use the Platform
                under the supervision of your parent, legal guardian or responsible adult.



            </p>
        </div>
        <div class="privacy-item">
            <h2>9.Retention of Personal Information:</h2>
            <p>
                Your Personal Information will not be retained by us, any longer than it is necessary for the purposes
                for which the Personal Information is processed and/or in accordance with legal, regulatory, contractual
                or statutory obligations as applicable.
                At the expiry of such periods, your Personal Information will be deleted or archived in compliance with
                applicable laws



            </p>
        </div>

        <div class="privacy-item">
            <h2>10.Controlling your personal information:</h2>
            <p>
                You have the right to invoke your rights which are available to data principals or data subjects (as per
                applicable laws and regulations) in relation to your Personal Information which is being processed by
                us.



            </p>
            <p>
                We provides you the ability to keep your Personal Information accurate and up-to-date. If at any time
                you would like to a) rectify, update or correct your Personal Information; b) obtain confirmation on
                whether or not your Personal Information is processed by it; c) access your Personal Information or
                exercise your right to data portability; or d) exercise your right to restrict the continuing disclosure
                of your Personal Information to any third party by us in certain circumstances, you are requested to
                contact us using the contact details mentions below. We will require you to provide a valid proof of
                your identity, in order to ensure that your rights are respected.



            </p>

            <p>
                For the exercise of certain rights, you may be required to approach the relevant authority / designated
                officer as per the provisions of the applicable data protection laws/ We may in accordance with the
                provisions of applicable data protection laws, charge a fee for fulfilling your request, in particular
                in case of excessive or manifestly unfounded request. Further you acknowledge that the abovementioned
                rights are not absolute and are subject to limitations as per the applicable data protection laws.
                At any time, if you wish to delete your account on recruitin.in, you may do so by using the settings
                available in your account.

            </p>
        </div>


        <div class="privacy-item">
            <h2>11.Confidentiality and Security:</h2>
            <p>
                The security and confidentiality of your Personal Information is important to us and we has invested
                significant resources to protect the safekeeping and confidentiality of your personal data. When using
                external service providers acting as processors, we require that they adhere to the same standards as we
                does. Regardless of where your Personal Information is transferred or stored, we take all steps
                reasonably necessary to ensure that personal data is kept secure

            </p>
            <p>
                We seek to ensure compliance with the requirements of the Information Technology Act, 2000 and Rules
                made there under to ensure the protection and preservation of your privacy. We have physical,
                electronic, and procedural safeguards that comply with the laws prevalent in India to protect your
                Personal Information. By accepting the terms of this Privacy Policy, you agree that the standards and
                practices being implemented by us, are reasonable and sufficient for the protection of your Personal
                Information.

            </p>

        </div>


        <div class="privacy-item">
            <h2>12.Social media:</h2>
            <p>
                We operates channels, pages and accounts on some social media sites to inform, assist and engage with
                customers. We monitors and records comments and posts made on these channels about itself in order to
                improve its products and services.
                Please note that you must not communicate the following information to us through such social media
                sites:


            </p>
            <p>
                sensitive personal data including (i) special categories of personal data meaning any information
                revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade
                union membership, and the processing of genetic data, biometric data for the purpose of uniquely
                identifying a natural person, data concerning health or data concerning a natural person's sex life or
                sexual orientation and (ii) other sensitive personal data such as criminal convictions and offences and
                national identification number ;

            </p>

            <p>
                Excessive, inappropriate, offensive or defamatory content.
                We are not responsible for any information posted on those sites other than the information posted by
                its employees on its behalf. We are only responsible for its own use of the Personal Information
                received through such sites.


            </p>

        </div>


        <div class="privacy-item">
            <h2>13.Changes to this Privacy Policy:</h2>
            <p>
                We reserves the right to update, change or modify this Privacy Policy at any time. The Privacy Policy
                shall come to effect from the date of publication of such update, change or modification.


            </p>
        </div>
        <div class="privacy-item">
            <h2>14.Disclaimer:</h2>
            <p>
                We does not store any account related information or any credit / debit card details. We are not be
                liable for any loss or damage sustained by Users as a result of any disclosure (inadvertent or
                otherwise) of any information concerning the User's account, credit cards or debit cards in the course
                of any online transactions or payments made for any products and/or services offered through the
                Platform.
                In case any Personal Information is shared by you with us, which is not requested by us during
                registration, (whether mandatorily or optionally), We will not be liable for any information security
                breach or disclosure in relation to such information.
                If you have any questions regarding this Privacy Policy or the protection of your Personal Information,
                please contact our Data Protection Officer/ Grievance Officer at the following:


            </p>
        </div>

        <div class="privacy-item">
            <h2>15.Data Protection Officer/ Grievance Officer:</h2>
            <p>
                In case you have any complaints and/or grievances in relation to the processing of your Personal
                Information you can send your complaints via e-mail to our grievance officer:


            </p>
        </div>
    </div>
</section>