import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appHost]'
})
export class HostDirective {

  constructor(private router: Router) { console.log('createddddd') }

//   @HostListener('window:click', ['$event'])
//   onClick(e: any) {
//     const path = e.composedPath() as Array<any>;
//     console.log('createddddd')
//     const firstAnchor = path.find(p => p.tagName && p.tagName.toLowerCase() === 'a');
//     if (firstAnchor && !firstAnchor.hasAttribute('routerlink')) {
//       const href = firstAnchor.getAttribute('href');

//       this.router.navigateByUrl(href);

//       e.preventDefault();
//     }
//   }

}
