<footer>
    <div class="container">
        <div class="row" style="padding-top: 50px">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo" /></a>
                        <p class="justy" *ngIf="langauge_id==1">
                            Recruitin makes collection of job vacancies easier for job seekers to decide on a specific
                            career. Equally , it can help employers pace up their hiring process.
                        </p>
                        <p class="justy" *ngIf="langauge_id==2">
                            Recruitin ले काम खोज्नेहरूलाई विशेष क्यारियरको बारेमा निर्णय गर्न सजिलो बनाउँछ । समान रूपमा
                            , यसले रोजगारदाताहरूलाई उनीहरूको भर्ती प्रक्रियालाई गति दिन मद्दत गर्न सक्छ ।
                        </p>
                        <p class="justy" *ngIf="langauge_id==3">
                            يُسهّل Recruitin جمع فرص العمل للباحثين عن عمل، مما يساعدهم في اتخاذ قرارات مدروسة بشأن
                        </p>
                        <p class="justy" *ngIf="langauge_id==4">
                            Recruitin ginagawang mas madali ang pagkolekta ng mga bakanteng trabaho para sa mga
                            naghahanap ng trabaho na magpasya sa isang tiyak
                            karera. Gayundin, makakatulong ito sa mga employer na pabilisin ang kanilang proseso sa
                            pag-hire.
                        </p>
                        <hr>
                        <ul>
                            <li><a target="_blank"><i class="icofont-youtube-play"></i></a></li>
                            <li><a href="https://www.facebook.com/share/YYyWARSWhEtaT6Qu/?mibextid=qi2Omg"
                                    target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/recruitin_official?igsh=YzljYTk1ODg3Zg=="
                                    target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-sm-6 col-lg-6">
                <div class="row" style="margin-top: 84px;">

                    <div class="col-sm-6 col-lg-6">
                        <div class="footer-item">

                            <div class="footer-category">
                                <ul *ngIf="langauge_id==1">
                                    <li><a routerLink="/"><img src="assets/img/dashboard/1.svg" style="width: 18px;">
                                            Home </a></li>
                                    <li> <a routerLink="/terms-and-conditions"><img src="assets/img/dashboard/2.svg"
                                                style="width: 18px;"> Terms& Conditions </a></li>
                                    <li> <a routerLink="/privacy-policy"><img src="assets/img/dashboard/3.svg"
                                                style="width: 18px;"> Privacy Policy </a></li>
                                    <li><a routerLink="/advice"><img src="assets/img/dashboard/4.svg"
                                                style="width: 18px;"> Security Advice </a></li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==2">
                                    <li><a routerLink="/"><img src="assets/img/dashboard/1.svg" style="width: 18px;">
                                            गृह</a></li>
                                    <li> <a routerLink="/terms-and-conditions"><img src="assets/img/dashboard/2.svg"
                                                style="width: 20px;"> नियम र सर्तहरू</a></li>
                                    <li> <a routerLink="/privacy-policy"><img src="assets/img/dashboard/3.svg"
                                                style="width: 18px;"> गोपनीयता नीति</a></li>
                                    <li><a routerLink="/advice"> <img src="assets/img/dashboard/4.svg"
                                                style="width: 18px;">
                                            सुरक्षा सल्लाह</a></li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==3">
                                    <li><a routerLink="/"><img src="assets/img/dashboard/1.svg" style="width: 18px;">
                                            الرئيسية</a></li>
                                    <li> <a routerLink="/terms-and-conditions"> <img src="assets/img/dashboard/2.svg"
                                                style="width: 20px;"> الشروط والأحكام</a></li>
                                    <li> <a routerLink="/privacy-policy"> <img src="assets/img/dashboard/3.svg"
                                                style="width: 18px;"> سياسة الخصوصية</a></li>
                                    <li><a routerLink="/advice"> <img src="assets/img/dashboard/4.svg"
                                                style="width: 18px;"> نصيحة أمنية</a></li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==4">
                                    <li><a routerLink="/"><img src="assets/img/dashboard/1.svg" style="width: 18px;">
                                            Unang Pahina</a></li>
                                    <li> <a routerLink="/terms-and-conditions"> <img src="assets/img/dashboard/2.svg"
                                                style="width: 20px;"> Mga Tuntunin at Kundisyon</a></li>
                                    <li> <a routerLink="/privacy-policy"><img src="assets/img/dashboard/3.svg"
                                                style="width: 18px;"> Pagkapribadong Patakaran</a></li>
                                    <li><a routerLink="/advice"><img src="assets/img/dashboard/4.svg"
                                                style="width: 18px;">
                                            Payo sa Seguridad</a></li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="footer-item">
                            <div class="footer-category">
                                <ul *ngIf="langauge_id==1">
                                    <li><a routerLink="/about"> <img src="assets/img/dashboard/5.svg"
                                                style="width: 18px;"> About Us</a></li>
                                    <li><a routerLink="/"> <img src="assets/img/dashboard/6.svg" style="width: 18px;">
                                            Testimonials</a></li>
                                    <li> <a routerLink="/contact"> <img src="assets/img/dashboard/7.svg"
                                                style="width: 18px;"> Report / Opinions</a> </li>
                                    <li style="display: flex; align-items: center;">
                                        <i style="font-size: 25px;" class="icofont-email"></i>
                                        <a href="mailto:info@recruitin.in ">&nbsp;&nbsp; info@recruitin.in
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==2">
                                    <li><a routerLink="/about"><img src="assets/img/dashboard/5.svg"
                                                style="width: 18px;"> हाम्रो बारेमा</a></li>
                                    <li><a routerLink="/"> <img src="assets/img/dashboard/6.svg" style="width: 18px;">
                                            प्रशंसापत्रहरू</a></li>
                                    <li> <a routerLink="/contact"> <img src="assets/img/dashboard/7.svg"
                                                style="width: 18px;"> प्रतिवेदन / राय</a> </li>
                                    <li style="display: flex; align-items: center;">
                                        <i style="font-size: 25px;" class="icofont-email"></i>
                                        <a href="mailto:info@recruitin.in "> &nbsp;&nbsp;info@recruitin.in
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==3">
                                    <li><a routerLink="/about"><img src="assets/img/dashboard/5.svg"
                                                style="width: 18px;"> عن الشركة</a></li>
                                    <li><a routerLink="/"> <img src="assets/img/dashboard/6.svg" style="width: 18px;">
                                            الشهادات والتوصيات</a></li>
                                    <li> <a routerLink="/contact"> <img src="assets/img/dashboard/7.svg"
                                                style="width: 18px;"> التقارير / الآراء</a> </li>
                                    <li style="display: flex; align-items: center;">
                                        <i style="font-size: 25px;" class="icofont-email"></i>
                                        <a href="mailto:info@recruitin.in ">&nbsp;&nbsp; info@recruitin.in
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                                <ul *ngIf="langauge_id==4">
                                    <li><a routerLink="/about"> <img src="assets/img/dashboard/5.svg"
                                                style="width: 18px;"> Tungkol sa Amin</a></li>
                                    <li><a routerLink="/"><img src="assets/img/dashboard/6.svg" style="width: 18px;">
                                            Mga testimonial</a></li>
                                    <li> <a routerLink="/contact"> <img src="assets/img/dashboard/7.svg"
                                                style="width: 18px;"> Ulat / Opinyon</a> </li>
                                    <li style="display: flex; align-items: center;">
                                        <i style="font-size: 25px;" class="icofont-email"></i>

                                        <a href="mailto:info@recruitin.in ">&nbsp;&nbsp; info@recruitin.in
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-sm-6 col-lg-4">
                        <div class="footer-item">
                            <div class="footer-find">
                                <ul>
                                    <li><a>
                                            Address:
                                            <br>D.No: 5-297/2 <br> Netaji Nagar <br>Opp Hindi college Bommuru</a></li>
                                    <li><a>Rajamahendravaram Rural <br>Andhra Pradesh - 533124</a></li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6 col-md-6" style="color: white">
                    <p *ngIf="langauge_id==1">
                        Designed and Developed By
                        <a href="https://www.amaravathisoftware.com/" target="_blank"><strong
                                style="color: white">Amaravathi Software Innovations</strong></a>
                    </p>
                    <p *ngIf="langauge_id==2">
                        द्वारा डिजाइन र विकसित
                        <a href="https://www.amaravathisoftware.com/" target="_blank"><strong
                                style="color: white">Amaravathi Software Innovations</strong></a>
                    </p>
                    <p *ngIf="langauge_id==3">
                        تصميم وتطوير بواسطة
                        <a href="https://www.amaravathisoftware.com/" target="_blank"><strong
                                style="color: white">Amaravathi Software Innovations</strong></a>
                    </p>
                    <p *ngIf="langauge_id==4">
                        Dinisenyo at Binuo Ni
                        <a href="https://www.amaravathisoftware.com/" target="_blank"><strong
                                style="color: white">Amaravathi Software Innovations</strong></a>
                    </p>
                </div>
            </div>
        </div> -->
    </div>
</footer>