<div class="page-title-area-exam">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Skill Test</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-twos pt-100" style="margin-top:35px ;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6" *ngFor="let i of getexamtest">
                <div class="job-item box">
                    <div *ngIf="i.completed == 1" class="ribbon ribbon-top-right"><span>Completed</span></div>
                    <img src="{{i.exam_imgurl}}" alt="img" style="width:60px; height: 60px;margin-top: 10%;">
                    <div class="job-inner align-items-center" style="padding-top: 35px;">
                        <div class="job-inner-left">
                            <h3 style="font-size:17px"><label>Exam Name:</label>
                                {{i.exam_name}}</h3>
                            <label>Total Questions:</label><span>{{i.total_qns}}</span>&nbsp;<br>
                            <label>Exam Time Minutes:</label><span>{{i.exam_duration}}</span>&nbsp;<br>
                            <ul>
                                <label>Total Marks:</label><span>{{i.total_marks}}</span>&nbsp;<br>
                                <label>Description:</label><span>{{i.description}}</span>&nbsp;<br>
                            </ul>
                        </div>
                        <div class="job-inner-right">
                            <ul>
                                <li class="curse"><a (click)="i.completed == 0 ? startTest(i.id, i.total_qns, i.exam_duration, i.exam_name, i.category) : startTestDone(i.id, i.total_qns, i.exam_duration, i.exam_name, i.category)">{{
                                        i.completed == 0 ? 'Start Test': 'View Test'}}</a></li>
                                <!-- <li class="curse"><a (click)="startTest(i.id, i.total_qns, i.exam_duration)">Start Test</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
