import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/core/services/login.service';
import { Subscription } from 'rxjs';
import { LoginserviceService } from 'src/app/components/pages/login/loginservice.service';
import Swal from 'sweetalert2';
import { ServiceService } from '../../pages/service.service';

declare const google: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  loginStatus: boolean = false;
  role: any;
  sdata: any[] = [];
  profilearr: any[] = [];
  private subscription: Subscription;

  langauge_id: any
  langauge: any = '';

  constructor(public login: LoginService, private loginserv: LoginserviceService, private profileservice: ServiceService) {

    this.langauge_id = localStorage.getItem('language_id')
    if (localStorage.getItem('user_id')) {
      this.loginStatus = true;
      this.role = localStorage.getItem('role');
      this.sdata = JSON.parse(localStorage.getItem('currentUser'));
      this.getStudentData();
      this.getloginprofile();
    }

  }

  ngOnInit(): void {
    this.subscription = this.loginserv.currentMessage.subscribe(message => {
      this.loginStatus = message, this.role = localStorage.getItem('role');
      this.getStudentData();
      this.getloginprofile();
    })

    if (localStorage.getItem('user_id')) {
      this.loginStatus = true;
      this.role = localStorage.getItem('role');
      this.sdata = JSON.parse(localStorage.getItem('currentUser'));
      this.getStudentData();
      this.getloginprofile();
    }
  }

  logoutAlert() {
    Swal.fire({
      title: 'Do You Want to Logout',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Do it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logoutData();
      }
    })
  }

  getloginprofile() {
    var data = {
      'companyuser_id': localStorage.getItem('companyuser_id'),
    }
    this.profileservice.getloginprofiledata(data).subscribe(data => {
      if (data.status == 200) {
        this.profilearr = data.data;
      }
    })
  }

  getStudentData() {
    var id = localStorage.getItem('user_id')
    this.profileservice.getStudentProfileData(id).subscribe(data => {
      this.sdata = data.data;
      console.log(data.data);
    })
  }

  logoutData() {
    this.loginserv.logout()
  }

  changelangauge(event: any): void {
    localStorage.setItem('language_id', event);
    location.reload();
  }

}



