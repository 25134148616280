import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Pipe, PipeTransform, HostListener } from "@angular/core";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  // @HostListener('window:beforeunload', ['$event'])

  @HostListener('window:beforeunload', ['$event'])





  timer$: Observable<number>;            // Timer For Re-Sending OTP
  show: boolean = false;
  getquestionpaper: any = [];
  displayquestion: any[] = [];
  displayReviewquestion: any[] = [];
  showresultquestions: any[] = [];
  counts; expand
  answer: any;
  correct_answer: any;
  now: string;
  countDownDate: any;
  submitted: boolean = false;
  answered: any;
  notanswered: any;
  skipped: any;
  review: any;
  score: any;
  time: any;                              // Time For Showing Otp
  exam_duration: any;
  ex_name: any;
  ex_cate:any;
  constructor(private serv: ServiceService, private activeRoute: ActivatedRoute) {
    var id = activeRoute.snapshot.paramMap.get('id');
    var limit = activeRoute.snapshot.paramMap.get('limit');
    this.exam_duration = activeRoute.snapshot.paramMap.get('it');
    this.ex_name =activeRoute.snapshot.paramMap.get('ex_name');
    this.ex_cate = activeRoute.snapshot.paramMap.get('ex_cate');
    this.questionPaper(id, limit);
    setInterval(() => {
      this.now = new Date().toString().split(' ')[4];
    }, 1);
  }

  ngOnInit(): void {
    if (!this.submitted) {
      window.onbeforeunload = () => this.ngOnDestroy();
    }
  }

  ngOnDestroy() {
    if (!this.submitted) {
      this.submitallanswer();
    }
  }

  addUserSubmit() {
    this.show = true;
  }
  // Start Time After Getting Otp //
  startTimer(counter: number) {
    this.submitted = false;
    this.timer$ = timer(0, 1000).pipe(
      take(counter),
      map(_ =>
        --counter,
      )
    );
    this.time = setTimeout(() => {

      this.submitallanswer()
    }, 1000 * counter);
  }
  questionPaper(id, limit) {

    var data = {
      id: id,
      user_id: localStorage.getItem('user_id'),
      limit: limit
    }
    console.log(89, data);

    this.serv.questionpaperList(data).subscribe((Result: any) => {
      console.log(Result);

      if (Result.status == 202) {
        Result.data.map((pac, toe) => {
          pac.index = toe;
          pac.status = 0;

          if (pac.answer) {
            pac.answer = pac.answer.trim();
          }


          if (pac.correct_answer) {
            pac.correct_answer = pac.correct_answer.trim()
          }

        })
        this.displayquestion = [Result.data[0]]
        this.getquestionpaper = Result.data;

        this.startTimer(60 * limit);
        //console.log(this.getquestionpaper, this.displayquestion);
      } else {
        Result.data.map((pac, toe) => {
          pac.index = toe;
          if (pac.answer) {
            pac.answer = pac.answer.trim();
          }


          if (pac.correct_answer) {
            pac.correct_answer = pac.correct_answer.trim()
          }
          // pac.status = 0;
        })



        this.displayquestion = [Result.data[0]]
        this.getquestionpaper = Result.data;
        this.showresultquestions = this.getquestionpaper;
        console.log(this.showresultquestions);

        // console.log(this.getquestionpaper);



        this.score = this.getquestionpaper.filter(repo => repo.status == 3 && repo.answer.trim() == repo.correct_answer.trim()).length;
        this.answered = this.getquestionpaper.filter(repo => repo.status == 3).length;
        this.notanswered = this.getquestionpaper.filter(repo => repo.status == 0).length;
        this.skipped = this.getquestionpaper.filter(repo => repo.status == 1).length;
        this.review = this.getquestionpaper.filter(repo => repo.status == 2).length;
        this.submitted = true;
      }
    })
  }

  submitNext(index) {
    //console.log(index, this.getquestionpaper[index]);
    this.displayquestion = [this.getquestionpaper[index]];
  }

  saveandReviewNext(index, status) {
    //console.log(index, this.getquestionpaper[index]);
    if (status == 2) {
      this.getquestionpaper[index].status = status;
      if ((this.getquestionpaper.length - 1) == index) {
        return;
      } else {
        this.displayquestion = [this.getquestionpaper[index + 1]];
      }
    } else {
      this.getquestionpaper[index].status = status;
    }
  }
  submitAnswer(index, answer, answer_ind) {
    this.getquestionpaper[index].status = 3;
    this.getquestionpaper[index].answer = answer;
    this.getquestionpaper[index].answer_ind = answer_ind;
  }

  submitallanswers() {
    Swal.fire({
      title: 'Do You Want Submit your Test ? ',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Do it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitallanswer();
      }
    })
  }

  submitallanswer() {
    var data = {
      id: this.activeRoute.snapshot.paramMap.get('id'),
      user_id: localStorage.getItem('user_id'),
      answers: this.getquestionpaper,
      score: this.getquestionpaper.filter(repo => repo.status == 3 && repo.answer.trim() == repo.correct_answer.trim()).length,
      date: new Date().getTime()
    }
    this.serv.submitAnswers(data).subscribe(res => {
      clearTimeout(this.time)
      console.log(159, this.time);

      this.submitted = true;
      this.sucessAlert('Test Completed Sucessfully');
      this.questionPaper(this.activeRoute.snapshot.paramMap.get('id'), this.activeRoute.snapshot.paramMap.get('it'))
    }, error => {
      clearTimeout(this.time)
      this.submitted = true;
    })

  }
  result() {

  }

  //timer code starts
  reviewQuestion(index) {
    this.displayReviewquestion = [this.getquestionpaper[index]];
  }

  showStatusQustions(status) {
    if (status == 5) {
      this.showresultquestions = this.getquestionpaper.filter(res => res.status == 3 && res.answer.trim() == res.correct_answer.trim());
      this.displayReviewquestion = [];
      return;
    }
    if (status == 6) {
      this.showresultquestions = this.getquestionpaper.filter(res => res.status == 3 && res.answer.trim() != res.correct_answer.trim());
      this.displayReviewquestion = [];
      return;
    }
    if(status == 0){
        this.showresultquestions = this.getquestionpaper.filter(res => res.status == 0 || res.status == 1 );
        this.displayReviewquestion = [];
        return;
    }
    if (status == 4) {
      this.showresultquestions = this.getquestionpaper;
      this.displayReviewquestion = [];
    } else {
      this.showresultquestions = this.getquestionpaper.filter(res => res.status == status);
      this.displayReviewquestion = [];
    }
  }

  sucessAlert(message) {
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 3000
    })
  }

  unloadNotification($event: any) {
    if (!this.submitted) {
      clearTimeout(this.time);
      $event.returnValue = true;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.submitted) {
      return false;
    }
    return true;
  }

  hasUnsavedData() {

  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    return (
      ("00" + hours).slice(-2) + ":" + ("00" + minutes).slice(-2) + ":" + ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
