import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobListComponent } from './components/pages/job-list/job-list.component';
import { FavouriteJobComponent } from './components/pages/favourite-job/favourite-job.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { CandidateListComponent } from './components/pages/candidate-list/candidate-list.component';
import { CandidateDetailsComponent } from './components/pages/candidate-details/candidate-details.component';
import { SingleResumeComponent } from './components/pages/single-resume/single-resume.component';
import { SubmitResumeComponent } from './components/pages/submit-resume/submit-resume.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { CompanyListComponent } from './components/pages/company-list/company-list.component';
import { CompanyDetailsComponent } from './components/pages/company-details/company-details.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CreateAccountComponent } from './components/pages/create-account/create-account.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SingleProfileComponent } from './components/pages/single-profile/single-profile.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { PackageprofileComponent } from './components/pages/packageprofile/packageprofile.component';
import { LoginGuard } from './core/core/gaurds/login.guard';
import { ChangesGuard } from './core/core/gaurds/changes.guard';
import { SecurityadviceComponent } from './components/pages/securityadvice/securityadvice.component';
import { ReportComponent } from './components/pages/report/report.component';
import { CertComponent } from './core/cert/cert.component';
import { FindAJobComponent } from './components/pages/find-a-job/find-a-job.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'jobs', component: JobListComponent},
    {path: 'favourite-job', component: FavouriteJobComponent},
    {path: 'job-details', component: JobDetailsComponent},
    {path: 'post-a-job', component: PostAJobComponent},
    {path: 'candidate-list', component: CandidateListComponent},
    {path: 'candidate-details', component: CandidateDetailsComponent},
    {path: 'single-resume', component: SingleResumeComponent},
    {path: 'submit-resume', component: SubmitResumeComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'company-list', component: CompanyListComponent},
    {path: 'company-details', component: CompanyDetailsComponent},
    {path: 'login', component: LoginComponent ,canActivateChild: [LoginGuard]},
    {path: 'create-account', component: CreateAccountComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'single-profile', component: SingleProfileComponent},
    {path: '404', component: ErrorComponent},
    {path:'packages',component:PackagesComponent},
    {path:'packageprofile',component:PackageprofileComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'walkins', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent,  canDeactivate: [ChangesGuard]},
    {path: 'contact', component: ContactComponent},
    {path:'service',component:ServicesComponent},
    {path:'advice',component:SecurityadviceComponent},
    {path:'report',component:ReportComponent},
    {path:'cert',component:CertComponent},
    {path:'find-a-job',component:FindAJobComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
