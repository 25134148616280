import { Component, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { CreateaccountserviceService } from "./createaccountservice.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import $ from "jquery";
import { Observable, timer } from "rxjs";
import { LoginserviceService } from "../login/loginservice.service";
import { take, map } from "rxjs/operators";
import { SerService } from "src/app/components/pages/feq/ser.service";
import firebase from "firebase/compat/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";

var config = {
    apiKey: "AIzaSyCDrGgdvJnQkZ5GDfkG-lk-d2v8tsOKqCM",
    authDomain: "recruitin-bd725.firebaseapp.com",
    projectId: "recruitin-bd725",
    storageBucket: "recruitin-bd725.appspot.com",
    messagingSenderId: "693544639002",
    appId: "1:693544639002:web:c5fa4d5cfbe6b31cc4cfa3",
    measurementId: "G-335TTWXJ3X",
};

// import { CountryISO } from 'ngx-mat-intl-tel-input';
@Component({
    selector: "app-create-account",
    templateUrl: "./create-account.component.html",
    styleUrls: ["./create-account.component.scss"]
})
export class CreateAccountComponent implements OnInit {
    imgArrray: any = [];
    logoArrray: any = [];
    filetype;
    imagedata: any[] = [];
    imagesData: any;
    showicon: boolean = false;
    candidateSignup: FormGroup;
    recruiterSignup: FormGroup;
    otpget: boolean = false;
    loginForm: FormGroup;
    show: boolean = false;
    showvalue: boolean = false;
    exper: boolean = false;
    exper1: boolean = false;
    minPrice: number = 0;
    maxPrice: number = 120000;
    showrecruiter: boolean = false;
    name;
    email;
    date_of_birth;
    mobile_number;
    searchValue;
    address;
    gender;
    qualification;
    branch;
    institute_name;
    year_of_passout;
    percentage;
    skills_adding;
    skills;
    onggoingtender;
    job_title;
    id: any;
    skillform: FormGroup;
    skillarr: any = [];
    submitted: boolean = false;
    showOverlay: boolean = false;
    refresh: boolean = false;
    timer$: Observable<number>; // Timer For Re-Sending OTP
    time: any; // Time For Showing Otp
    error: string = ""; // Error Message Stored value
    intialvalues: any; // Form Intial Values For Restting the Form
    masterdata: any;
    company: any;
    companyuser_id: string;
    submitteddata: boolean = false;
    index: any = 0;
    dynoclass: boolean = false;

    packages: any;
    packagedatails: any;
    loginStatus: boolean = false;
    role;
    regform: boolean = false;
    //   public preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada]; // Add your preferred countries here
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private signupcandidates: FormBuilder,
        private loginservice: LoginserviceService,
        private service: SerService,
        private ngZone: NgZone,
        private viewScroller: ViewportScroller,
        private serv: CreateaccountserviceService
    ) {
        if (localStorage.getItem("user_id")) {
            this.loginStatus = true;
            this.role = localStorage.getItem("role");
        }
        this.companyuser_id = localStorage.getItem("companyuser_id");

        this.getpackagedata();

        var index = this.route.snapshot.paramMap.get("index");
        if (index == "1") {
            this.index = index;

            $(document).ready(function () {
                $(".register-info-box").fadeIn();
                $(".login-info-box").fadeOut();

                $(".white-panel").removeClass("right-log");

                $(".login-show").addClass("show-log-panel");
                $(".register-show").removeClass("show-log-panel");
            });
            // $('.register-info-box').fadeIn();
            // $('.login-info-box').fadeOut();

            // // $('.white-panel').removeClass('right-log');

            // $('.login-show').addClass('show-log-panel');
            // $('.register-show').removeClass('show-log-panel');
        }
        if (index == "2") {
            this.index = index;

            $(document).ready(function () {
                $(".register-info-box").fadeOut();
                $(".login-info-box").fadeIn();

                $(".white-panel").addClass("right-log");
                $(".register-show").addClass("show-log-panel");
                $(".login-show").removeClass("show-log-panel");
            });

            // $('.login-info-box').fadeIn();

            // $('.white-panel').addClass('right-log');

            // $('.login-show').removeClass('show-log-panel');
        }
        // console.log(this.index);

        this.route.params.subscribe((params) => {
            console.log(params);
            // this.product = this.route.snapshot.data['product'];
            var index = this.route.snapshot.paramMap.get("index");
            if (index == "null" || index == null) {
                this.index = 0;
                $(document).ready(function () {
                    $(".login-info-box").fadeOut();
                    $(".login-show").addClass("show-log-panel");
                });
            }
            console.log(index);
        });

        this.route.queryParams.subscribe((params) => {
            this.minPrice = params.minPrice ? params.minPrice : this.minPrice;
            this.maxPrice = params.maxPrice ? params.maxPrice : this.maxPrice;
        });

        this.loginForm = this.signupcandidates.group({
            phone: ["", [Validators.required]],
            usr_pwd: ["", [Validators.required]],
        });
        this.intialvalues = this.loginForm.value;

        this.candidateSignup = this.signupcandidates.group({
            name: ["", Validators.required],
            email: ["", Validators.compose([Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),]),],
            date_of_birth: ["", Validators.required],
            phone: ["", Validators.compose([Validators.required])],
            job_title: ["", Validators.required],
            address: ["", Validators.required],
            gender: ["", Validators.required],
            city_name: ["", Validators.required],
            year_of_passout: ["", Validators.required],
            percentage: ["", Validators.required],
        });

        this.recruiterSignup = this.signupcandidates.group({
            recruiter_name: ["", Validators.required],
            recruiter_email: ["", Validators.compose([Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),]),],
            phone: ["", Validators.required],
            company_name: ["", Validators.required],
            recruiter_address: ["", Validators.required],
            industry_type: ["", Validators.required],
            recruiter_description: ["", Validators.required],
        });

        this.skillform = this.signupcandidates.group({
            skills: ["", Validators.required],
        });
        
        this.getcompanydetails();
        this.getrecruiterdatalist();
    }
    get f() {
        return this.loginForm.controls;
    }

    ngOnInit(): void {
        firebase.initializeApp(config);
        this.companyuser_id = localStorage.getItem("companyuser_id");
        this.route.paramMap.subscribe((params) => {
            this.id = params.get("id"); //+ string to number
            console.log(this.id);
        });
    }

    gotoLogiinpage() {
        var index = this.route.snapshot.paramMap.get("index");
        this.router.navigate(["/login", { index: index }]);
    }

    signup() {
        throw new Error("Method not implemented.");
    }

    changeCard() {
        if ($("#log-login-show").is(":checked")) {
            $(".register-info-box").fadeOut();
            $(".login-info-box").fadeIn();

            $(".white-panel").addClass("right-log");
            $(".register-show").addClass("show-log-panel");
            $(".login-show").removeClass("show-log-panel");
        } else if ($("#log-reg-show").is(":checked")) {
            $(".register-info-box").fadeIn();
            $(".login-info-box").fadeOut();

            $(".white-panel").removeClass("right-log");

            $(".login-show").addClass("show-log-panel");
            $(".register-show").removeClass("show-log-panel");
        }
    }

    signupcandidate() {
        this.show = true;
        this.showrecruiter = false;
    }
    signuprecruiter() {
        this.showrecruiter = true;
        this.show = false;
        console.log(this.id);
    }

    submitskill() {
        this.skillarr.push(this.skillform.value);
    }

    experience() {
        this.exper = true;
        this.exper1 = false;
    }
    fresher() {
        this.exper1 = true;
        this.exper = false;
    }

    // Append filter value to Url
    updateFilter(tags: any) {
        // console.log(tags);

        tags.page = null; // Reset Pagination
        this.router
            .navigate([], {
                relativeTo: this.route,
                queryParams: tags,
                queryParamsHandling: "merge", // preserve the existing query params in the route
                skipLocationChange: false, // do trigger navigation
            })
            .finally(() => {
                this.viewScroller.setOffset([120, 120]);
                this.viewScroller.scrollToAnchor("allitemsdata"); // Anchore Link
            });
    }
    //candidate signup post
    // candidateSignupPost() {
    //   console.log(this.candidateSignup);

    //   console.log(this.candidateSignup.value)

    //    this.serv.candidateSignupData(this.candidateSignup.value).subscribe(
    //     res => {
    //      console.log(res);
    //      },
    //      error => {

    //      });
    // }
    get addUsersFormCnt() {
        return this.candidateSignup.controls;
    }

    get addUsersFormCnt1() {
        return this.recruiterSignup.controls;
    }

    candidateSignupPost() {
        this.submitted = true;
        console.log(this.candidateSignup);

        if (this.candidateSignup.invalid) {
            return;
        }
        var data = {
            form: this.candidateSignup.value,
            skillarr: this.skillarr,
            imgArrray: this.imgArrray,
        };
        console.log(data);

        // this.serv.candidateSignupData(data).subscribe(res => {
        //   if (res.status == 200) {
        //     this.candidateSignup = this.signupcandidates.group({
        //       name: ['', Validators.required],
        //       email: ['',Validators.compose([ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])],
        //       date_of_birth: ['', Validators.required],
        //       mobile_number: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9][0-9]{9}$'),])],
        //       job_title: ['', Validators.required],
        //       address: ['', Validators.required],
        //       gender: ['', Validators.required],
        //       city_name: ['', Validators.required],
        //       year_of_passout: ['', Validators.required],
        //       percentage: ['', Validators.required],

        //     });
        //     alert('Submit Sucessfully');
        //     location.reload();

        //   }

        // })
    }
    onFileChange(event) {
        const reader = new FileReader();
        reader.onload = () => {
            this.onggoingtender = reader.result as string;
            console.log(event.target.files[0].name);
            var file_name = event.target.files[0].name;
            var bfile_type3 = event.target.files[0].name.split(".");
            this.filetype = bfile_type3[1];
            this.imgArrray.push({
                img: this.onggoingtender,
                file_name: event.target.files[0].name,
                file_type: bfile_type3[1],
            });
            console.log(this.imgArrray);
        };
        reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
    }
    // onFileChange(e) {
    //   const reader = new FileReader();
    //   if (e.target.files && e.target.files.length) {
    //     const [file] = e.target.files;
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       this.onggoingtender = reader.result as string;
    //       var bfile_typeEmpl = e.target.files[0].name.split('.');
    //       this.filetype = bfile_typeEmpl[1];
    //       var imagedata = [{
    //         reviewimg: this.onggoingtender,
    //         filetype: this.filetype,
    //       }]
    //       this.imgArrray = imagedata;
    //       // this.showicon = true;
    //       console.log( this.imgArrray);
    //     };
    //   }
    // }
    numericOnly(event): boolean {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    //recruiter signup posts
    recruiterSignupPost() {
        console.log(this.recruiterSignup, this.recruiterSignup.invalid);
        const phoneValue = this.recruiterSignup.get("phone").value;
        console.log("Phone:", phoneValue);
        this.submitteddata = true;
        if (this.recruiterSignup.invalid) {
            return;
        }
        var data = {
            form: this.recruiterSignup.value,
            logoArrray: this.logoArrray,
        };
        console.log(data);
        this.serv.recruiterSignupData(data).subscribe((res) => {
            if (res.status == 200) {
                this.recruiterSignup = this.signupcandidates.group({
                    recruiter_name: ["", Validators.required],
                    recruiter_email: [
                        "",
                        Validators.compose([
                            Validators.required,
                            Validators.pattern(
                                "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"
                            ),
                        ]),
                    ],
                    phone: ["", Validators.required],
                    company_name: ["", Validators.required],
                    recruiter_address: [
                        "",
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(10),
                            Validators.pattern("^[6-9][0-9]{9}$"),
                        ]),
                    ],
                    industry_type: ["", Validators.required],
                    recruiter_description: ["", Validators.required],
                });
                alert("Submit Sucessfully");
                this.submitteddata = false;
                this.router.navigate(["create-account", { index: 2 }]);
                location.reload();
            }
        });
    }
    //   getrecruiterdatalist() {
    //     this.serv.getrecruiterdata(this.companyuser_id).subscribe(res => {
    //       if (res.status == 200) {
    //         this.company = res.data;
    //         console.log( this.company);
    //       }
    //       localStorage.setItem('newdata', JSON.stringify( this.company))
    //       console.log( this.company);

    //   })
    // }

    getrecruiterdatalist() {
        var data = {
            companyuser_id: localStorage.getItem("companyuser_id"),
        };
        this.serv.getrecruiterdata(data).subscribe(
            (data) => {
                if (data.status == 200) {
                    this.company = data.data;
                    console.log(data.data);
                }
                localStorage.setItem("newdata", JSON.stringify(this.company));
            },
            (er) => {
                console.log(er);
            }
        );
    }

    // data(data: any): string {
    //   throw new Error('Method not implemented.');
    // }

    // this.selectedmoduleslist.map(obj => {
    //   if(obj.module_id==projectdata.module_id) {
    //     var index = this.selectedmoduleslist.indexOf(obj);
    //     this.selectedmoduleslist.splice(index, 1);

    //     projectdata.reportdata.map(obj123 => {
    //       obj123.check_sub_menu = false;
    //     })
    //   }
    // })

    deleteskillnot(skills: any) {
        console.log(skills);
        this.skillarr.map((obj) => {
            if (obj.skills == skills) {
                var index = this.skillarr.indexOf(obj);
                console.log(index);

                this.skillarr.splice(index, 1);
            }
        });
    }

    onFileupload(event) {
        const reader = new FileReader();
        reader.onload = () => {
            this.onggoingtender = reader.result as string;
            console.log(event.target.files[0].name);
            var file_name = event.target.files[0].name;
            var bfile_type3 = event.target.files[0].name.split(".");
            this.filetype = bfile_type3[1];
            this.logoArrray.push({
                img: this.onggoingtender,
                file_name: event.target.files[0].name,
                file_type: bfile_type3[1],
            });
            console.log(this.logoArrray);
        };
        reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
    }

    getcompanydetails() {
        this.serv.getcandidatesignuppost().subscribe((res) => {
            //     this.masterdata = res.data;
            //     console.log(this.masterdata);
            //     localStorage.setItem('newdata', JSON.stringify(this.masterdata))
            //   console.log(this.masterdata);
            //   localStorage.setItem('comid', this.masterdata[0].id);
            //   console.log(this.masterdata[0].id);
        });
    }

    // Get Otp For User Login //

    getOtp() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.f['phone'].invalid) {
            //console.log('form is not valid');
            return;
        }
        var data = {
            number: this.loginForm.value.phone
        }
        //console.log(this.loginForm.value.phone);
        this.showOverlay = true;
        this.loginservice.getdashotp(data).subscribe(response => {
            this.showOverlay = false;
            //console.log(response);
            if (response.status == 200) {
                this.otpget = true;
                this.startTimer(30);
            } else {
                this.loginservice.errorMessageAlert('Please Enter Valid Mobile Number')
            }
            this.submitted = false;
        }, error => {
            this.showOverlay = false;
            //console.log(error)
        })
    }


    // getOtp() {
    //     this.submitted = true;
    //     if (this.loginForm.get("phone").invalid) {
    //         return;
    //     }
    //     this.showOverlay = true;
    //     const data = {
    //         number: this.loginForm.value.phone,
    //     };
    //     console.log(data);

    //     this.loginservice.getdashotp(data).subscribe(
    //         (response) => {
    //             console.log(response);
    //             this.showOverlay = false;

    //             if (response.status === 200) {
    //                 // Firebase
    //                 this.reCaptchaVerifier =
    //                     new firebase.auth.RecaptchaVerifier(
    //                         "recaptcha-container",
    //                         {
    //                             size: "invisible",
    //                         }
    //                     );

    //                 firebase
    //                     .auth()
    //                     .signInWithPhoneNumber(
    //                         this.loginForm.value.phone,
    //                         this.reCaptchaVerifier
    //                     )
    //                     .then((confirmationResult) => {
    //                         localStorage.setItem(
    //                             "verificationId",
    //                             JSON.stringify(
    //                                 confirmationResult.verificationId
    //                             )
    //                         );
    //                         this.ngZone.run(() => {
    //                             this.otpget = true;
    //                             this.verify = JSON.parse(
    //                                 localStorage.getItem("verificationId") ||
    //                                     "{}"
    //                             );
    //                             this.startTimer(30);
    //                         });
    //                     })
    //                     .catch((error) => {
    //                         // Handle Firebase error
    //                         console.error(error);
    //                         alert(error.message);
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 5000);
    //                     });
    //                 // Firebase
    //             } else if (response.status === 601) {
    //                 this.loginservice.errorMessageAlert(response.errors);
    //             } else {
    //                 this.loginservice.errorMessageAlert(
    //                     "Please Enter Valid Mobile Number"
    //                 );
    //             }

    //             this.submitted = false;
    //         },
    //         (error) => {
    //             this.showOverlay = false;
    //         }
    //     );
    // }

    // Start Time After Getting Otp //
    startTimer(counter: number) {
        this.refresh = false;
        this.timer$ = timer(0, 1000).pipe(
            take(counter),
            map((_) => --counter, console.log(counter))
        );
        this.time = setTimeout(() => {
            this.refresh = true;
        }, 30000);
    }

    dashlogin() {
        this.showOverlay = true;
        this.loginservice.login(this.loginForm.value).subscribe((data) => {
            this.showOverlay = false;
            if (data.length) {
                this.router.navigate(["/"]);
            }
        }, (error) => {
            this.error = error ? error : "";
        }
        );
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            console.log(this.loginForm.value)
            return;
        } else {
            this.submitted = true;
            this.showOverlay = true;
            this.loginservice.login(this.loginForm.value).subscribe(data => {
                this.showOverlay = false;
                Swal.fire('Successfully Login')
                if (data.length) {
                    this.router.navigate(['/']);
                }
            }, error => {
                this.showOverlay = false;
                this.error = error ? error : '';
            });
        }
    }

    data: any = [];
    otpdata: any = [];
    reCaptchaVerifier: any;
    verify: any;
    mainarr: any = [];
    // onSubmit() {
    //     console.log(this.loginForm.value.phone);
    //     if (this.loginForm.value.phone.invalid) {
    //       return;
    //     }
    //     this.data = {
    //       phone: this.loginForm.value.phone
    //     }
    //     this.loginservice.login(this.data).subscribe((response: any) => {
    //       this.otpdata = response;
    //       if (response.status == 200) {
    //         this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //           'sign-in-button',
    //           {
    //             size: 'invisible',
    //           }
    //         );
    //         // console.log(this.reCaptchaVerifier);
    //         // console.log(this.loginForm.value.phone);
    //         firebase
    //           .auth()
    //           .signInWithPhoneNumber(this.loginForm.value.phone, this.reCaptchaVerifier)
    //           .then((confirmationResult) => {
    //             localStorage.setItem(
    //               'verificationId',
    //               JSON.stringify(confirmationResult.verificationId)
    //             );
    //             this.ngZone.run(() => {
    //               this.otpget = true;
    //               this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
    //             });
    //           })
    //           .catch((error) => {
    //             //console.log(error.message);
    //             alert(error.message);
    //             setTimeout(() => {
    //               window.location.reload();
    //             }, 5000);
    //           });
    //       }
    //       if (response.status == 202) {
    //         alert("Please Sign Up  to Login");
    //         this.loginForm.reset();

    //       }
    //     })
    //   }
    // On ResetPassword link click
    onResetpassword2() {
        this.otpget = false;
        this.loginForm.reset(this.intialvalues);
        clearTimeout(this.time);
        // this.router.navigate(['reset-password2'], { relativeTo: this.route.parent });
    }

    getpackagedata() {
        console.log("tulasi");
        this.service.getpackagedatalist().subscribe(
            (data) => {
                if (data.status == 200) {
                    this.packages = data.data;

                    console.log(data);
                    console.log(data.data);
                }
            },
            (er) => {
                console.log(er);
            }
        );
    }

    submitbuynow(packagedatails) {
        localStorage.setItem("newpackage", JSON.stringify(packagedatails));
        console.log(localStorage.getItem("companyuser_id"));

        if (
            localStorage.getItem("role") == "0" ||
            localStorage.getItem("companyuser_id") == "0" ||
            localStorage.getItem("companyuser_id") == undefined ||
            localStorage.getItem("companyuser_id") == null ||
            localStorage.getItem("companyuser_id") == ""
        ) {
            console.log(localStorage.getItem("companyuser_id"));
            this.router.navigate(["/create-account", { index: 2 }]);
        } else {
            this.router.navigate(["/packages"]);
        }
    }

    openform(any) {
        if (any == 1) {
            this.regform = false;
        } else if (any == 2) {
            this.regform = true;
        } else {
            alert("Server Down");
        }
    }

    scroll(el: HTMLElement) {
        setTimeout(() => {
            el.scrollIntoView({ behavior: "smooth" });
        });
    }
}
