<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate List</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="profile-area profile-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-1/profile/1.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Business Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-1/profile/2.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jac Jacson</h3>
                        <span>Web Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-1/profile/3.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Tom Potter</h3>
                        <span>UX/UI Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="assets/img/home-1/profile/4.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Shane Mac</h3>
                        <span>SEO Consultant </span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-1/profile/5.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Liam Noah</h3>
                        <span>Marketing Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-1/profile/6.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>William James</h3>
                        <span>Management Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-1/profile/7.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Lucas Mason</h3>
                        <span>Networking Consultant</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="assets/img/home-1/profile/8.jpg" alt="Profile">

                    <div class="profile-inner">
                        <h3>Daniel Henry</h3>
                        <span>Data Consultant </span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>