<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Terms and Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Terms and Conditions</li>

                    </ul>

                </div>

            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <h2 style="text-align: center;
    font-style: inherit;
    font-family: ui-monospace;
    font-weight: 700;">Please read this document carefully</h2><br>
    <div class="container">
        <div class="privacy-item">
            <h2>1.Introduction</h2>
            <p>Recruitin.in (the “Site”) and the Licensed Mobile Application for Accessing the Site (including all
                associated media and online or electronic documentation) (“the Application”), are owned and operated by
                Svenly Jobs Consultant Private Limited (“Company”). The Site and Application are hereinafter
                collectively referred to as “Platform”. For the purposes of the Terms of Use, “You" shall mean the
                person who is accessing the Platform.
                The Company makes available its services and products to you, through the Platform on the condition that
                you abide by these Terms of Use (hereinafter referred to as “Terms of Use”), as updated from time to
                time. If you do not accept the Terms of Use or you do not meet or comply with their provisions, you
                should not use or access the Platform and/or avail of the Company’s services or products via the
                Platform.
                .</p>
        </div>

        <div class="privacy-item">
            <h2>2.Purpose</h2>
            <p>The Platform is made available for use only by: (a) individuals who are searching for job opportunities
                and vacancies and/or are interested in availing of other career enhancement services which are provided
                might be provided by the Company (“Job Seekers”); and (b) by individuals and/or organizations desirous
                of making available information pertaining to job vacancies including but not limited to agencies acting
                for multiple employers / organizations in order to fill the job vacancies available with those
                employers/organizations(“Recruiters”).
                Job Seekers and Recruiters as hereinafter collectively referred to as “Users”.
                The Platform is intended to serve only as a preliminary medium of contact and exchange of information
                between the Users, who have a bona fide intention to contact and/or be contacted for the purposes
                related to genuine existing job vacancies and for other career enhancement services (“Purpose”).
                .</p>

        </div>

        <div class="privacy-item">
            <h2>3.Binding Agreement</h2>
            <p>The Terms of Use constitute a binding legal agreement (“Agreement”) between you and the Company. To the
                extent that you are accessing this Platform in your capacity as a representative of or as an employee
                of, a Recruiter, you represent and warrant that you are agreeing to these Terms of Use on your own
                behalf as well as on behalf of the Recruiter and have the authority to bind such Recruiter to this
                Agreement.
                YOUR ACCESS TO OR USE OF THE PLATFORM, ANY PART THEREOF OR USE AND/OR DOWNLOADING OF ANY CONTENT
                AVAILABLE ON IT, INDICATES YOUR ACCEPTANCE OF THESE TERMS OF USE AND IS CONTINGENT ON YOUR COMPLIANCE
                WITH THE TERMS OF USE. YOU AGREE TO USE THE PLATFORM OF YOUR OWN FREE WILL AND AT YOUR OWN RISK

            </p>
        </div>
        <div class="privacy-item">
            <h2>4.Eligibility</h2>
            <p>By using and accessing the platform, you represent and warrant that: a) you are competent to enter into a
                valid binding contract as per Indian law; and b) you are eligible to conduct the Purpose lawfully.
            </p>
        </div>
        <div class="privacy-item">
            <h2>5.Use of the Platform</h2>
            <p>The Platform and the services and products offered via the Platform (which includes both paid and free
                services/ products) are meant for only for legitimate and lawful uses which fall within the scope of the
                Purpose. The Company has the sole and absolute right to determine whether a specific type of action or
                use falls within the scope of the Purpose or not.

            </p>
            <ul>
                <li>
                    <i class="icofont-simple-right"></i> The following actions will inter alia constitute a misuse of
                    the Platform and are strictly prohibited:
                </li>
                <li>
                    <i class="icofont-simple-right"></i>Copying, extracting, downloading, sharing, modifying, selling,
                    storing, distributing, making derivate works from or otherwise exploiting any content, data,
                    information, including profiles, curriculum vitae, photographs and/or graphics, available on the
                    Platform and/or any services or products of the Company, in any manner or for any purpose which is
                    not, consistent with the Purpose and/or in accordance with the Terms of Use.
                    Users are expressly prohibited from using or exploiting the Platform and/or any content or data
                    provided therein for:
                </li>
                <li>
                    <i class="icofont-simple-right"></i> any commercial purposes such as creating alternate databases,
                    extending access to the Platform to third parties without prior written consent of the Company;
                    and/or
                </li>
                <li>
                    <i class="icofont-simple-right"></i> We use information collected from cookies and other
                    technologies, to improve your user experience and the overall quality of our services (for more
                    information please refer to paragraph 4 below). When showing you tailored ads, we will not associate
                    an identifier from cookies or similar technologies with sensitive categories, such as those based on
                    race, religion, sexual orientation or health.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> undertaking any business activity which is in competition
                    with the business of the Company; and/or
                </li>
                <li>
                    <i class="icofont-simple-right"></i>for seeking fees in any name whatsoever; and/or
                </li>
                <li>
                    <i class="icofont-simple-right"></i> approaching candidates/jobseekers for any purposes other than
                    for specific existing vacancies; and/or
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Sharing access with persons who are not contracted with the
                    Company.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Reselling the products/services offered by the Company.
                </li>



                <li>
                    <i class="icofont-simple-right"></i> (Using or attempting to use any automated program, software
                    or system or any similar or equivalent process (including spiders, robots, crawlers etc.) to access,
                    navigate, search, copy, monitor, download, scrape, crawl or otherwise extract in any manner, any
                    data or content including but not limited to adding or downloading profiles, contact details, or
                    send or redirect messages from the Platform;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Gaining or attempting to gain unauthorized access (inter alia
                    by hacking, password “mining” or any other means) to: (a) any portion or feature of the Platform or
                    any of the services or products offered on or through the Platform which are not intended for you;
                    (b) any server, website, program or computer systems of the Company or any other third parties
                    and/or Users;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Modifying the services provided through the Platform or their
                    appearance using any technology or overlay any additional offering on top of such services or
                    simulate the Platform’s services or its functions in any manner whatsoever without explicit consent
                    obtained in writing by approaching us at legal@recruitin.in
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Accessing the Platform through interfaces other than those
                    expressly provided by Company;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Attempting to breach or breaching any security or
                    authentication measures set up by the Company in relation to the Platform and/or attempting to
                    probe, scan or test the vulnerability of the Company’s system or network;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Scraping, downloading (including bulk- downloading),
                    replicating or otherwise extracting any information or data from the Platform (by any process,
                    whether automatic or manual) to offer any products or services which are similar to or may in any
                    manner compete with the products or services of the Company;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Reverse engineering, decompiling, disassembling, deciphering
                    or otherwise attempting to do any of the aforesaid or deriving the source code for the Site or
                    Application or any related technology or any part thereof;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Circumventing or attempting to circumvent any technological
                    protections used or employed by the Company or by any third party in order to protect the content on
                    the Platform and/or to exclude robots, spiders etc. from crawling and /or scraping content from the
                    Platform.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Bypassing or Circumventing or trying to circumvent any
                    service limits including but not limited to Search limits, Captcha limits and occurrences on
                    different triggers
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Interfering with or disrupting or attempting to interfere
                    with or disrupt (including by using any device, software or routine), the use of the Platform or any
                    computer networks connected to the Platform, by any other User;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Developing, using or attempting to use any automated program,
                    scripts, robots, third party software or system or any similar or equivalent process (including
                    spiders, robots, crawlers, browser plug-ins/extensions/add-ons, iframes on third party sites,
                    mirroring, HTML parsers etc.) to access, navigate, search, copy, monitor, download, scrape, crawl or
                    otherwise extract or modify in any manner, any data or content from the Platform without explicit
                    consent obtained in writing by approaching us at legal@recruitin.in
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Impersonating any other person or entity, or making any
                    misrepresentation as to your employment by or affiliation with any person or entity;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Forging headers or in any manner manipulating identifiers in
                    order to disguise the origin of any user information;
                </li>


                <li>
                    <i class="icofont-simple-right"></i> Stalking, threatening, or in any manner harassing any other
                    User;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Imposing an unreasonable or disproportionately large load on
                    the Platform infrastructure;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Engaging in “framing,” “mirroring,” or otherwise simulating
                    the appearance or function of the Platform (or any part thereof) and providing deeplinks into this
                    Platform (or any part thereof) without prior permission of Company;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Spamming the Platform/Company or any other Users including by
                    uploading, posting, emailing, SMS, transmitting or otherwise making available either directly or
                    indirectly, any unsolicited bulk e-mail or unsolicited commercial e-mail.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Using the Platform or the contact details of Users for
                    transmitting marketing and promotional mailers/ Offensive messages/ messages or communications using
                    any media that are unrelated to recruitment or sending unsolicited commercial communications with
                    misleading subject lines in order to intentionally obfuscate the original message.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Hosting, modifying, uploading, posting, transmitting,
                    publishing, or distributing any material or information that:
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Violates any applicable local, provincial, state, national or
                    international law, statute, ordinance, rule or regulation for the time being in force;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Belongs to another person and to which you have no right;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Infringes, breaches or otherwise contravenes the rights of
                    the Company or any third party, including any copyright, trademark, patent, rights of privacy or
                    publicity or any other proprietary rights;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Contains computer viruses, or other computer code, files or
                    programs designed to disrupt, destroy or interfere with or limit the functioning of the Platform, or
                    any other computer system or resource;
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Is grossly harmful, harassing, invasive of another's privacy,
                    hateful, disparaging, relating to or encouraging money laundering or gambling in any manner, any
                    content which is or may potentially be perceived as being harmful, threatening, abusive, harassing,
                    defamatory, libelous, blasphemous, vulgar, pornographic, paedophilic, obscene, or racially,
                    ethnically, or otherwise unlawful in any manner whatsoever;
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Which constitutes or encourages conduct that would constitute a
                    criminal offence, give rise to other liability, or otherwise violate applicable law;
                </li>

                <li>
                    <i class="icofont-simple-right"></i> That deceives or misleads the addressee about the origin of
                    such messages or communicates any information which is grossly offensive or menacing in nature;
                </li>

                <li>
                    <i class="icofont-simple-right"></i>That harms minors in any way;
                </li>

                <li>
                    <i class="icofont-simple-right"></i> In addition to the above, depending upon the nature / services
                    chosen, certain additional conditions may apply with respect to JOBSEEKER SOLUTIONS and RECRUITMENT
                    SOLUTIONS. The Users acknowledge that these additional conditions are also an integral part of these
                    Terms of Use.
                </li>

                <li>
                    <i class="icofont-simple-right"></i>NOTICE
                    THE SERVICES PROVIDED BY THE PLATFORM DO NOT EXTEND TO ACTING AS AN AGENT (EXPRESS OR IMPLIED) OR AS
                    LABOUR CONSULTANTS ON BEHALF OF ANY SUBSCRIBER OR USER.
                    WE HAVE NO AGENTS AND DO NOT OPERATE THROUGH ANY AGENTS

                </li>

                <li>
                    <i class="icofont-simple-right"></i>In case of any misuse of the Platform the Company reserves its
                    rights to take any actions as it may deem fit, including (i) suspending or terminating your access
                    to the Platform and/or the Company’s products and/or service, forthwith and without any notice; (ii)
                    removing or disabling any content which is non-compliant with the Terms of Use; and/or (iii)
                    reporting the incident/ misuse to relevant authorities and/or initiating any legal proceedings. In
                    case your misuse of the Platform results in or is likely to result in any loss or depletion of
                    revenue, reputation or customer base of the Company, the Company additionally reserves its right to
                    seek adequate relief against the said misuse, including relief by way of damages, injunction, etc.

                </li>

                <li>
                    <i class="icofont-simple-right"></i>In case of any misuse of the Platform the Company reserves its
                    rights to take any actions as it may deem fit, including (i) suspending or terminating your access
                    to the Platform and/or the Company’s products and/or service, forthwith and without any notice; (ii)
                    removing or disabling any content which is non-compliant with the Terms of Use; and/or (iii)
                    reporting the incident/ misuse to relevant authorities and/or initiating any legal proceedings. In
                    case your misuse of the Platform results in or is likely to result in any loss or depletion of
                    revenue, reputation or customer base of the Company, the Company additionally reserves its right to
                    seek adequate relief against the said misuse, including relief by way of damages, injunction, etc.

                </li>
            </ul>
        </div>

        <div class="privacy-item">
            <h2>6.Limitations on Use</h2>
            <p>You agree to strictly abide by any limitations imposed on usage of the Platform by the Company, including
                limitations in terms of geography or location (e.g. of access) or quantity (e.g. number of resume
                accessed or downloaded in a day). Any violation entitles the Company to stop your access or service and
                initiate action as it may deem fit.

            </p>
        </div>
        <div class="privacy-item">
            <h2>7.Accuracy and Correctness of User Information</h2>
            <p>While using, registering on and/or creating a profile on the Platform you undertake to provide only true
                and correct information and further undertake to, at all times, keep the information up to date. Users
                are prohibited from impersonating or masquerading as any other person, whether living or dead.
                The Company reserves the right to remove/suspend any content or profile which is violative of these
                Terms of Use including any profile providing false, incorrect, incomplete or outdated information. The
                Company also reserves the right to terminate any User’s access to the Platform, if he or she fails to
                furnish accurate or updated contact details.


            </p>
        </div>

        <div class="privacy-item">
            <h2>8.Security of Log in Credentials</h2>
            <p>You are requested not to share passwords to your account(s) on the Platform with any other individual
                /entity and be responsible in what you post.
                If you have registered on the Platform, the safety and security of your log-in credentials (i.e. your
                account access information and passwords) is your responsibility. You should not share your log-in
                credentials with any other person. You are fully responsible for all uses of your log-in credentials
                including all activities that occur under your account through the Platform. The Company, however,
                undertakes to take all reasonable precautions at its end to ensure that there is no compromise of login
                credentials provided to you.
                You agree to immediately notify the Company of any unauthorized use of your account and/or log-in
                credentials.



            </p>
        </div>

        <div class="privacy-item">
            <h2>9.Links to Third-Party Sites</h2>
            <p>The Platform may make available links to other websites. When you click on such links, you may leave our
                Platform. These links are provided solely as convenience to you and the presence of these links shall
                not under any circumstances be considered as an endorsement of the contents of the same by the Company.
                The Company is not responsible for the content on such linked third party websites and does not make any
                representations relating to the content on such websites. If you chose to access these websites you do
                so at your own risk.



            </p>
        </div>


        <div class="privacy-item">
            <h2>10.Disclaimers</h2>
            <p>The Platform may make available links to other websites. When you click on such links, you may leave our
                Platform. These links are provided solely as convenience to you and the presence of these links shall
                not under any circumstances be considered as an endorsement of the contents of the same by the Company.
                The Company is not responsible for the content on such linked third party websites and does not make any
                representations relating to the content on such websites. If you chose to access these websites you do
                so at your own risk.



            </p>
            <ul>
                <li>
                    <i class="icofont-simple-right"></i> THE COMPANY IS AN INTERMEDIARY AS DEFINED UNDER SECTION 2(1)(W)
                    OF THE INFORMATION TECHNOLOGY ACT, 2000.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> The Company shall not be responsible for any content posted by
                    any Users / third parties on the Platform. The Platform primarily serves as a portal for online
                    distribution and publication of information submitted by Users. Although, the Company is not
                    obligated to screen any communications or information posted by Users. Views expressed by the Users
                    are their own and the Company does not endorse the same.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> In case any inaccurate or otherwise improper content which does
                    not conform to these Terms of Use is sighted on the Platform, Users are requested to notify the
                    Company of the same using the contract details provided below. If the Company finds such content to
                    be in violation of the Terms of Use, the Company may in its sole discretion take down or request the
                    removal of such content, in accordance with applicable laws.
                    and/or
                </li>
                <li>
                    <i class="icofont-simple-right"></i> Any mention of non-Company products or services is provided on
                    the Platform is for informational purposes only. Nothing on the Platform shall be considered an
                    endorsement, representation or warranty to any User or third party, whether in regards to its web
                    site, products, services, hiring, experience, employment or recruiting practices, or otherwise.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> ALL INFORMATION AVAILABLE ON THE PLATFORM (INCLUDING
                    INFORMATION RELATING TO THE COMPANY, ITS PRODUCTS AND/OR SERVICES) IS PROVIDED ON AN 'AS IS' BASIS
                    WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. THE COMPANY, TO THE FULLEST EXTENT PERMITTED
                    BY LAW, DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY,
                    TITLE, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY
                    MAKES NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE SITES.
                </li>
                <li>
                    <i class="icofont-simple-right"></i>THE COMPANY DOES NOT WARRANT THAT THE PLATFORM WILL OPERATE
                    ERROR-FREE OR THAT THE SITE, APPLICATION AND THEIR SERVER(S) ARE FREE OF COMPUTER VIRUSES OR OTHER
                    HARMFUL MECHANISMS. IF YOUR USE OF PLATFORM RESULTS DIRECTLY OR INDIRECTLY IN THE NEED FOR SERVICING
                    OR REPLACING EQUIPMENT OR DATA, THE COMPANY IS NOT RESPONSIBLE FOR THOSE COSTS.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> THE COMPANY MAKES NO REPRESENTATIONS REGARDING THE
                    TRUTHFULNESS, ACCURACY, COMPLETENESS, TIMELINESS OR RELIABILITY OF ANY CONTENT POSTED BY USERS, OR
                    OF ANY OTHER FORM OF COMMUNICATION ENGAGED IN BY USERS
                </li>

                <li>
                    <i class="icofont-simple-right"></i> • THE COMPANY MAKES NO REPRESENTATIONS REGARDING THE
                    EFFECTIVENESS OF THE SERVICES OR TIMELINESS OF THE PRODUCTS OR SERVICES OFFERED THROUGH THE
                    PLATFORM. THE COMPANY DOES NOT GUARANTEE THAT THE SERVICES OR PRODUCTS OFFERED THROUGH THE PLATFORM
                    WILL RESULT IN CANDIDATES BEING HIRED AND/OR VACANCIES BEING FILLED, AND IS NOT RESPONSIBLE OR
                    LIABLE FOR ANY BUSINESS, EMPLOYMENT, HIRING AND/OR SALARY DECISIONS, FOR WHATEVER REASON MADE, MADE
                    BY YOU.
                </li>
            </ul>
        </div>


        <div class="privacy-item">
            <h2>11.Make your Own Decision</h2>
            <p>YOU AGREE THAT ANY RELIANCE ON CONTENT OR INFORMATION POSTED ON THE PLATFORM INCLUDING BY OTHER USERS, OR
                ON ANY OTHER FORM OF COMMUNICATION OR INTERACTION WITH OTHER USERS, WILL BE AT YOUR OWN RISK. USERS ARE
                REQUESTED TO CONDUCT INDEPENDENT CHECKS BEFORE PROCEEDING WITH ANY TRANSACTION OR EXPLORING ANY
                EMPLOYMENT OPPORTUNITY, BASED ON THE DATA AVAILABLE ON THE PLATFORM.</p>


            <p>THE COMPANY DOES NOT GUARANTEE THE AUTHENTITCITY, NATURE AND/OR QUANTUM OF THE RESPONSES TO ANY POST OR
                APPLICATION UPLOADED BY A USER OR THAT THERE WILL BE ANY RESPONSE AT ALL.
            </p>
            <p>All or any decisions made would be entirely your prerogative and company does not claim to offer any
                advice, either legal or financial, neither does it provide any guarantees or warrantees.
            </p>
        </div>

        <div class="privacy-item">
            <h2>12.Limitation of Liability</h2>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAW, COMPANY
                SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
                BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, REVENUE, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
                (EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). THE COMPANY PROVIDES NO GUARANTEES
                OF SERVER UPTIME OR APPLICATIONS WORKING PROPERLY. IN NO EVENT WILL COMPANY’S AGGREGATE LIABILITY TO YOU
                FOR ANY CLAIMS, INCLUDING UNDER TORT, CONTRACT OR OTHERWISE, EXCEED THE AMOUNT OF FEES PAID BY YOU TO
                THE COMPANY. THESE LIMITATIONS AND EXCLUSIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
                PURPOSE OF ANY LIMITED REMEDY.</p>

        </div>

        <div class="privacy-item">
            <h2>13.Privacy</h2>
            <p>PLEASE USE THE PRIVACY SETTINGS AVAILABLE ON THE PLATFORM TO PROTECT YOUR INFORMATION.
                The Privacy Policy of the Platform explains how we may use your personal data, we will at all times
                respect and ensure adherence to the privacy policy on a best efforts basis, additionally various
                settings are provided to help you to be able to control the manner in which others may be able to view
                your information as chosen by you to be displayed on your profile and the manner in which you may have
                chosen to be contacted. Any feedback provided by a User shall be deemed as non-confidential to the User.
                When You share or upload or store information others may be able to view, copy or download such
                information.
                You give us permission to use the information about actions that you have taken on Recruitin.in, in
                connection with ads, offers and other sponsored content that we display across our services, without any
                compensation to you. We use data and information about you to make relevant suggestions and
                recommendation to you and others.
            </p>

        </div>

        <div class="privacy-item">
            <h2>14.Payment Terms & Refund Policy</h2>
            <p>YOU ARE REQUESTED TO HONOUR YOUR PAYMENT OBLIGATIONS TO THE COMPANY.
                Payments for the services offered by the Platform shall be on a 100% advance basis. The payment for
                service once subscribed to by you is not refundable and any amount paid shall stand appropriated.
                Refund, if any, will be at the sole discretion of the Company.
                User hereby irrevocably accepts to receive the tax invoice as soft copy through emails.
                Notwithstanding anything contained in any other agreement or arrangement, by whatever name called, the
                performance obligation of the Company (service provider) is to provide access of its on-line portal to
                the customer for the duration of the subscription period & reference to any usage, by whatever name
                called or any other performance obligation, if any, is to provide the upper limit for consumption, which
                by itself, does not create any additional performance obligation upon the Company
                The Company offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the
                Customers card/bank accounts.
                In the event of any suspension or termination of services on account of non-compliance of these Terms of
                Use, any payment made to the Company by you shall stand forfeited with immediate effect.
                The User acknowledges and agrees that Company/Platform, at its sole discretion and without prejudice to
                other rights and remedies that it may have under the applicable laws, shall be entitled to set off the
                amount excess paid by a subscriber/user against any amount(s) payable by User to Company under any other
                agreement or commercial relationship towards other products/services. The Company gives no guarantees of
                server uptime or applications working properly. All is on a best effort basis and liability is limited
                to refund of amount only. We undertake no liability for free services.
                The User or customer shall be required to promptly provide copy of TDS certificate to the Company as
                mandated under law for tax deducted at source from the payments made to the Company. Any loss on account
                of non availment or disallowance of such TDS credit in view of any lapse as per this clause or as
                required under applicable law on the part of User or customer shall entitle the company to seek
                reimbursement or indemnification Users, buying the products online are redirected to third party
                gateways for completing payment transactions. These transactions happen on third party network and hence
                not controlled by Company.
                A User through his user identification and shall be solely responsible for carrying out any online or
                off-line transaction involving credit cards / debit cards or such other forms of instruments or
                documents for making such transactions and Company assumes no responsibility or liability for their
                improper use of information relating to such usage of credit cards / debit cards used by the subscriber
                online / off-line
                The Company shall not be liable for any loss or damage sustained by reason of any disclosure
                (inadvertent or otherwise) of any information concerning the user's account and / or information
                relating to or regarding online transactions using credit cards / debit cards and / or their
                verification process and particulars nor for any error, omission or inaccuracy with respect to any
                information so disclosed and used whether or not in pursuance of a legal process or otherwise.


            </p>

        </div>

        <div class="privacy-item">
            <h2>15.Amendment to these Terms of Use</h2>
            <p>COMPANY RESERVES ITS RIGHT TO AMEND / ALTER OR CHANGE ALL OR ANY DISCLAIMERS OR TERM(S) OR CONDITION(S)
                OF AGREEMENTS AT ANY TIME WITHOUT ANY PRIOR NOTICE. SUCH AMENDED TERMS SHALL BE POSTED ON THE PLATFORM.
                Businesses, environment and technology evolve and in order to accommodate the changing nature of the
                environment in which we operate as well to provide for any additional features that may be introduced in
                future, these Terms may need modifications or additions. Such modifications or additions shall be
                effective immediately upon posting of the modified Terms of Use on the Site.
                You are advised to review the modified Terms of Use periodically to be aware of such modifications or
                additions and your continued access or use of the Site, shall be deemed conclusive proof of your
                acceptance of these Terms of Use, as amended/modified from time to time.



            </p>

        </div>

        <div class="privacy-item">
            <h2>16.Intellectual Property Rights</h2>
            <p>YOU ARE REQUESTED TO RESPECT OUR PROPRIETARY RIGHTS AND BRANDS
                All rights, title and interest in and to the Platform including the intellectual property rights
                therein, which shall include but not be limited to proprietary database, trademarks, service marks,
                logo, copyright, know-how in and to the Platform, and any copies You are permitted to make herein, are
                owned by the Company and is protected by applicable laws. You are granted a limited, revocable, personal
                license to use and access the Platform for the Purpose, provided that you are at all times in compliance
                with these Terms of Use. Users shall under no circumstance sublicense, assign, or transfer the license,
                and any attempt at such sublicense, assignment or transfer is void. No rights are granted to you other
                than a limited permission to use the Platform upon the terms expressly set forth in these Terms of Use.
                Any misuse of the Platform or the Company’s intellectual property for a competing business or in any
                other manner whatsoever shall be actionable by the Company. The structure, sequence, organization and
                source code of the Platform are valuable trade secrets and copyright of the Company.
                By opting to access the Site and use the services, you hereby acknowledge and agree that any information
                or material provided to the Company will be deemed NOT to be confidential and proprietary to you. By
                providing the Company any information or other material, You grant Company a non-exclusive,
                unrestricted, irrevocable, perpetual, royalty-free, worldwide, assignable, transferrable, sublicensable,
                right and license, in all formats or media, whether now known or hereafter devised or discovered, to
                use, reproduce, display, transmit, translate, rent, sell, modify, disclose, publish, create derivative
                works from and distribute that material or information, and You also agree that the Company is free to
                use any ideas, concepts, know-how or techniques that You send us for any purpose. You further agree that
                such license would not lapse in any circumstances, notwithstanding the provisions of any law in force,
                including without limitation, the Indian Copyright Act, 1957. You further agree to indemnify, defend and
                hold the Company harmless from any liability arising due to the use or distribution of any such material
                or information.
                The above provisions and rights granted regarding title and intellectual property rights associated with
                the Platform shall survive termination of these Terms of Use.
            </p>

        </div>

        <div class="privacy-item">
            <h2>17.Local Laws </h2>
            <p>You are responsible for compliance with applicable local laws including but not limited to the export and
                import regulations of other countries, while consuming the information and the services as available
                through the Platform.
            </p>

        </div>
        <div class="privacy-item">
            <h2>18.IndemnificationLocal Laws </h2>
            <p>BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO INDEMNIFY AND OTHERWISE HOLD HARMLESS COMPANY, ITS
                DIRECTORS, OFFICERS, EMPLOYERS, AGENTS, SUBSIDIARIES, AFFILIATES AND OTHER PARTNERS FROM ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF, RELATING TO, OR
                RESULTING FROM YOUR USE OF THE PLATFORM INCLUDING BUT NOT LIMITED TO INFORMATION PROVIDED BY YOU OR ANY
                OTHER MATTER RELATING TO THE PLATFORM.
            </p>

        </div>

        <div class="privacy-item">
            <h2>19.Taxes</h2>
            <p>ANY REFERENCE TO DUTIES AND TAXES ETC IN THESE TERMS OF USE SHALL INCLUDE GOODS AND SERVICES TAX (“GST)
                OR VALUE ADDED TAX (VAT) AS PER LOCAL JURISDICTION OR ANY OTHER SIMILAR TAX OR DUTY, BY WHATEVER NAME
                CALLED (HEREIN REFERRED AS GST) AND SHALL BE CHARGED IN ADDITION TO THE BASIC AMOUNT, IN ACCORDANCE WITH
                RESPECTIVE LAWS & REGULATIONS.
                YOU ARE REQUIRED TO PROVIDE TRUE & CORRECT INFORMATION AS REQUIRED UNDER TAX LAWS, INCLUDING BUT NOT
                LIMITED TO SEZ/FREE ZONE UNIT OR USAGE THEREIN & IEIL SHALL BE ENTITLED TO CONSIDER ANY INFORMATION
                AVAILABLE AS PER ITS RECORDS TO BE CORRECT & TRUE & DISCHARGE ITS TAX OBLIGATIONS ACCORDINGLY. IN CASE
                OF ANY DISCREPANCY OR CHANGE, THE USER IS REQUIRED TO PROMPTLY INTIMATE THE CORRECT OR UPDATED
                PARTICULARS TO THE COMPANY. THE COMPANY SHALL BE ENTITLED TO SEEK INDEMNIFICATION FROM USERS IF IT IS
                REQUIRED TO PAY ANY TAX, DUTY, FEE, INTEREST OR PENALTY IN VIEW OF INCORRECT OR INCOMPLETE INFORMATION
                OR DATA FURNISHED BY USER OR NOT GOT RECTIFIED/ UPDATED BY USER TIMELY.
            </p>

        </div>


        <div class="privacy-item">
            <h2>20.Termination and Suspension</h2>
            <p>COMPANY MAY, WITHOUT NOTICE IN ITS SOLE DISCRETION, AND AT ANY TIME, TERMINATE OR RESTRICT YOUR USE OR
                ACCESS TO THE PLATFORM (OR ANY PART THEREOF) FOR ANY REASON, INCLUDING, WITHOUT LIMITATION, THAT THE
                COMPANY BASED ON ITS JUDGEMENT AND PERCEPTION BELIEVES YOU HAVE VIOLATED OR ACTED INCONSISTENTLY WITH
                THE LETTER OR SPIRIT OF THESE TERMS OF USE.
                IN THE EVENT OF SUSPENSION OR TERMINATION OF SERVICES, ANY LIMITED RIGHTS GRANTED TO YOU UNDER THESE
                TERMS OF USE SHALL REVERT TO THE COMPANY FOR ALL PURPOSES. FURTHER, YOU WILL RETURN TO THE COMPANY, ANY
                UNUSED DATA OR INFORMATION IN YOUR POSSESSION, WITH IMMEDIATE EFFECT. THE TERMS OF USE SHALL APPLY TO
                ANY DATA OR INFORMATION TO WHICH YOU HAD ACCESS PRIOR TO THE SAID SUSPENSION OR TERMINATION AND ANY
                ABUSE OR MISUSE THEREOF, SHALL BE ACTIONABLE IN ACCORDANCE WITH THE TERMS OF USE. [DURING AND BEYOND THE
                VALIDITY OF THIS AGREEMENT, YOU SHALL TREAT ANY PROPRIETARY DATA (INCLUDING WITHOUT LIMITATION, THE
                PRICING TERMS, INFORMATION RELATING TO OUR SERVICES AND BETA STAGE TRIALS ETC.) SHARED WITH YOU AS
                CONFIDENTIAL, IF IT WAS UNDERSTOOD AS CONFIDENTIAL BY THE NATURE OF THE INFORMATION OR THE CIRCUMSTANCES
                OF THE DISCLOSURE.]

            </p>

        </div>

        <div class="privacy-item">
            <h2>21.Dispute Settlement</h2>
            <p>If any dispute arises between a User/Users and Company arising out of use of the Platform or thereafter,
                in connection with the validity, interpretation, implementation or alleged breach of any provision of
                these Terms of Use, the dispute shall be referred to a sole arbitrator who shall be an independent and
                neutral third party identified by the Company. The decision of the arbitrator shall be final and binding
                on both the parties to the dispute.
                The Company will not be party to any legal proceedings between a User (e.g. a subscriber) and a party
                contacted through the Platform site. In case Company is made a party in any legal proceedings, costs
                will be recovered from the party on whose behest the Company is involved in the matter, to which it
                otherwise has no control. Company however will abide with any court order served on it through due
                process.

            </p>

        </div>

        <div class="privacy-item">
            <h2>22.Governing Law</h2>
            <p>These Terms of Use shall be governed by the laws of Republic of India.

            </p>

        </div>


        <div class="privacy-item">
            <h2>23.Entire Agreement</h2>
            <p>THESE TERMS OF USE AS AMENDED FROM TIME TO TIME CONSTITUTE THE ENTIRE AGREEMENT BETWEEN YOU AND COMPANY
                REGARDING THE PLATFORM.
                These Terms of Use supersede all terms and conditions contained in any purchase order, order
                acknowledgment form, invoice or other business form submitted by You.
                No advertisements, catalogues or other publications or statements, whether written or oral, regarding
                the performance of the Platform permitted under these Terms of Use shall form part of these Terms of
                Use.


            </p>

        </div>


        <div class="privacy-item">
            <h2>24.Severability</h2>
            <p>VOID PROVISIONS SHALL HAVE NO EFFECT ON THE VALIDITY OF OTHER PARTS OF THESE TERMS OF USE
                If any provision of these Terms of Use are held to be invalid or unenforceable by a court of competent
                jurisdiction, such provision will be changed and interpreted so as to best accomplish the objectives of
                the original provision to the fullest extent allowed by law and the remaining provisions of these Terms
                of Use shall remain in full force and effect.



            </p>

        </div>

        <div class="privacy-item">
            <h2>25.Assignment and Delegation</h2>
            <p>The access to this Platform is being providing only to You, for your own use in accordance with these
                Terms of Use You may not assign or delegate your rights under these Terms of Use or the limited license
                that has been extended to you, and any assignment and/or delegation of these Terms of Use or any sub
                licensing by you will be null and void, in case any case of this nature is brought to our notice in
                addition to any other remedies that we may have under the law, we reserve our rights to claim damages
                and seek an injunction against You.



            </p>

        </div>
        <div class="privacy-item">
            <h2>26.Availability of Services</h2>
            <p>Services are provided on an AS IS and AS AVAILABLE basis, however, there are no server uptime guarantees.
                Any regulations, guidelines, legislations or lawful orders of a court or a quasi-judicial body may
                require us to change alter or stop our services. Similarly, any force majeure events which are beyond
                our reasonable control, may cause an interruption in the services or non-availability of services or
                certain features thereof.




            </p>

        </div>

        <div class="privacy-item">
            <h2>27.Activation</h2>
            <p>Due to a high incidence of credit card fraud, your services purchased online using a credit card may not
                be activated without a proper verification.

            </p>

        </div>


        <div class="privacy-item">
            <h2>28.Contact information</h2>
            <p>YOU MAY CONTACT US BY APPROACHING US AT ANY OF THE FOLLOWING EMAIL ADDRESSES DEPENDING ON THE NATURE OF
                THE QUERY/ COMPLAINT THAT YOU WISH TO ADDRESS TO US:
                • General queries related to the functioning of the website: feedback@recruitin.in
                • Fraudulent listings on the Site may be reported at: feedback@recruitin.in
                • Complaints related to content on the Platform/Site may be made to the Grievance Officer appointed by
                the Company using the interface available here.


            </p>

        </div>


        <div class="privacy-item">
            <h2>29.Conditions Applicable to Jobseeker Solutions</h2>
            <p>By creating a jobseeker account on the Platform, you agree to:
            </p>
            <p> Making your resume searchable to all Users of the Platform who subscribe to Employer Job Portal
                (“Subscribers”). You may be contacted by Subscribers via email, telephone and/or post. We recommend that
                you read the privacy settings carefully to make your profile accessible to recruiters/ employers as per
                your preferences. The Site will not be held responsible for contacts/mails received by you.
            </p>
            <p> Receive promotional mailers/ special offers/recommendations by the Platform on its behalf or on behalf
                of third parties. You may remove yourself from the promotional mailer database by resetting this option
                on the Privacy Setting section of your resume.
                The resume/ insertion or information/data fed by you can be updated by You alone, without any additional
                cost, except for a few fields which are automatically updated by the Platform based on the information
                that is provided by you, in order to enhance your user experience.

            </p>
            <p> Certain portions/functions of the Platform may not require a mandatory registration, if You are
                unregistered and You are using such portion of the Platform , You undertake that

            </p>

            <p> The data/information fed by You is true and correct in all respects

            </p>
            <p> You will not disseminate false/objectionable/offensive material using these services or any material
                which is violative of the Terms of Use of the Platform;

            </p>
            <p> You shall use the services exclusively for the purposes of bona fide job applications; Usage of the
                interface on the Platform for any other purpose is strictly prohibited.
                Please note that The Platform may reach out to You, based on the interests expressed by You while using
                the Platform.


            </p>

            <p> When You indicate your interest in a job listing on the Platform, you are sending your CV and
                application information including relevant documents to the Platform, and you are requesting and
                authorizing the Platform to make available such application/ information to the applicable advertiser(s)
                for such job listing(s).


            </p>



            <p> By using the Platform, You agree that the Company is not responsible for the content of the
                Recruiter/employer’s application form, messages, screener questions, testing assessments; required
                documents, or their format or method of delivery.


            </p>
            <p> You consent to your application, documents and any responses sent to you by the Recruiter/employer or
                vice versa through the Platform being processed and analyzed by the Company according to these Terms of
                Use and the Platform’s Privacy Policy . The Platform shall store and process such information regardless
                of whether a job vacancy has been filled. The Platform may use your application materials (including
                public CVs and responses to employer’s questions) to determine whether you may be interested in a job
                listing, and the Platform may reach out to you about such job listing.


            </p>
            <p> Information you post in public areas of the Platform or applications or make visible in the resume and
                profile database, may be accessed, used, and stored by others around the world, including those in
                countries that might not have a legislation that guarantees adequate protection of personal information
                as defined by your country of residence. While the Platform takes measures to safeguard your information
                from unauthorized access or inappropriate use, the Platform does not control these third parties and we
                are not responsible for their use of information you give to us. Accordingly, you should not post
                sensitive information or any other information you would not want made public, to any portion of the
                Platform or to a public website.


            </p>
            <p> The Company merely offers a platform, therefore the Company neither guarantees nor offers any warranty
                about the credentials, bona fides, status or otherwise of the prospective
                Recruiter/employer/organization which downloads the resume/ insertion or information/data and uses it to
                contact you.


            </p>
            <p> The Company will not be held liable for loss of any data technical or otherwise, or of the resume/
                insertion or information/data or particulars supplied by You due to acts of god as well as reasons
                beyond its control like corruption of data or delay or failure to perform as a result of any cause(s) or
                conditions that are beyond the Company’s reasonable control including but not limited to strikes, riots,
                civil unrest, Govt. policies, tampering of data by unauthorized persons like hackers, distributed denial
                of service attacks, virus attacks, war and natural calamities.


            </p>
            <p> You are advised not make any payments to any Recruiters and are further advised to check the
                authenticity of all or any response received pursuant to the resume/ insertion or information/data being
                fed into the network system of the Platform by you, prior to going out of station or in station for any
                job or interview. The Company assumes no responsibility in respect thereof and expressly disclaims any
                liability for any act, deed or thing which you may do, pursuant to the receipt of the response, if any,
                to the resume/ insertion or information/ data being fed into the network system of the Platform.


            </p>
            <p> The Company reserves its right to reject and delete any resume/ insertion or information/data fed in by
                you without assigning any reason.


            </p>
            <p> In case You have opted for any paid services designed to enhance your job search, depending on the
                services opted, the following shall also apply:


            </p>
            <p> The Company will commence providing services only upon receipt of amount/charges upfront either from
                You or from a third party on Your behalf and once the receipt of the payment has been identified in
                terms of sales order.


            </p>
            <p> Services once subscribed are not transferable.


            </p>
            <p> Payment once received stands appropriated, the refund policy of the Site shall govern.


            </p>
            <p> In case you have subscribed to Resume writing/development services, You must respond to the queries
                raised (sent by email) by us within 7 days of such a query being raised failing which, the Company shall
                stand absolved of all its obligations.


            </p>
            <p>The Platform uses an automated algorithm to match jobs against the keywords/attributes present in the
                profile submitted by the user or based on the preferences set by a user, and hence offers no guarantee
                nor warranties that the jobs sent in the job mail will be relevant to the profile. You are advised to
                independently verify the authenticity of the credentials of the employers/recruiters.


            </p>

        </div>

        <div class="privacy-item">
            <h2>30.Conditions Applicable to Recruitment Solutions</h2>
            <p>• The services provided to You are aimed at providing recruitment solutions and should be restricted to
                contacting suitable candidates for genuine jobs in existence. Mailing practices such as transmitting
                marketing and promotional mailers/ offensive messages/ messages with misleading subject lines in order
                to intentionally obfuscate the original message, are strictly prohibited. Similarly, posting jobs
                without authorization from the end employer is an incorrect practice and is prohibited. We reserve the
                right to terminate services, without prior notice, to the originator of spam. No refund shall be
                admissible under such circumstances.

            </p>


            <ul>
                <li>
                    <i class="icofont-simple-right"></i> You undertake that the jobs sought to be advertised on any
                    section of the Platform or job opportunities with regard to which candidates are contacted through
                    database are in existence, genuine and You have the authority to advertise or act as a recruiter to
                    ensure that the job vacancies are closed.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> A User shall be subject to Quotas as applicable and as
                    advised. The number of transactions i.e. CV access/views/downloads/job postings per day shall also
                    be restricted as per the current prevalent limit per log in, each log in is required to be specific
                    to identifiable one individual user alone.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> Unless You are utilizing the Platform in the capacity of a
                    consultant fulfilling the hiring requirements of an end employer, the transfer/ movement of
                    Resumes/CVs out of Recruiter’s establishment is not permissible.
                    and/or
                </li>
                <li>
                    <i class="icofont-simple-right"></i> You shall ensure compliance with all applicable laws for the
                    protection of the personal details of the users whose profiles are accessed by You through the
                    Platform, including but not limited to compliance with any laws/regulations relating to unsolicited
                    communications etc, the Telecom Commercial Communications Customer Preference Regulations, 2010 as
                    also rules, regulations, guidelines, bye laws and notifications made thereunder, while availing
                    services of the Company on the Platform.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> You will comply with all applicable data protection laws in
                    relation to the processing of personal data; and not process personal data in an unlawful manner and
                    excessive with regard to agreed purposes as defined in the privacy policy and these terms and
                    conditions
                </li>
                <li>
                    <i class="icofont-simple-right"></i> You agree to provide reasonable assistance as is necessary to
                    facilitate the handling of any Data Security Breach (as applicable under GDPR or any other
                    applicable Data Security law) in an expeditious and compliant manner
                </li>
                <li>
                    <i class="icofont-simple-right"></i> You shall implement adequate technical and organizational
                    controls to protect the shared personal data obtained from the Company against unauthorised or
                    unlawful processing and against accidental loss, destruction, damage, alteration or disclosure
                </li>

                <li>
                    <i class="icofont-simple-right"></i> You agree that the responsibility for complying with a data
                    subject request lies with the party which holds/processes the personal data collected/shared
                </li>

                <li>
                    <i class="icofont-simple-right"></i> You warrant and represent that the institution shall not
                    disclose or transfer personal data obtained from the Company to any sub-processors without ensuring
                    that adequate and equivalent safeguards to the personal data.
                </li>
                <li>
                    <i class="icofont-simple-right"></i> You shall retain or process shared personal data for no
                    longer than is necessary to carry out the agreed purposes which in any case shall not exceed 90 day
                    (in the case of response to a listing 180 days) and thereafter destroy these. The Company reserves
                    it right to seek a certificate to this effect from You.
                </li>

                <li>
                    <i class="icofont-simple-right"></i> Any piece of personal data that is downloaded by You from the
                    Platform, shall be used only for fulfilling hiring requirements, any usage for any other purposes is
                    strictly prohibited.
                    The Platform and the Recruiters etc. (as applicable) would act as independent controllers in their
                    respective capacity.

                </li>

                <li>
                    <i class="icofont-simple-right"></i> You agree that you are responsible for the documents,
                    screening questions, comment threads, and additional information asked from the job seekers when
                    they apply to a job posted by you through the Platform. You shall ensure that any additional
                    information collected from the job seekers during their interaction with you in any form through the
                    interface provided by the Platform shall not include special categories of information (such as
                    racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union
                    membership, genetic data, biometric data, sexual orientation, passwords, financial information and
                    health information) until explicit consent is taken from the job seekers (data subjects).
                    In such cases where the Platform is processing information exclusively on your behalf, it acts in
                    the capacity of a data processor with you acting as the data controller.


                </li>




                <li>
                    <i class="icofont-simple-right"></i> The Company shall be entitled to place the information
                    relating to vacancies in specified sections on the Platform and such other mirror or parallel
                    website(s) or in allied publications as the Company may deem fit and proper but such additional
                    publication shall be without any extra cost to You.


                </li>


                <li>
                    <i class="icofont-simple-right"></i> Depending on the type of listing that has been subscribed, the
                    features and the fields may vary.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> By posting/uploading a job posting/listing on the Platform,
                    You confirm that You have obtained all licenses/permits as are necessary for the said activity and
                    to indemnify the Company against all claims, damages arising out of actions/claims that may be made
                    by third parties in relation to the same.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> The Company has the right to make all such
                    modifications/editing of the vacancy details in order to fit its data structure.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> The Company reserves its right to reject any insertion or
                    information/data provided by you without assigning any reason either before uploading or after
                    uploading the vacancy details.


                </li>


                <li>
                    <i class="icofont-simple-right"></i> The Company will commence providing the services only upon
                    receipt of amount/charges upfront either from You or from a third party on Your behalf


                </li>

                <li>
                    <i class="icofont-simple-right"></i> In case You have opted for shortlisting services, the Company
                    will make best efforts to use the parameters provided by You to short list, but takes no
                    responsibility for the accuracy of the short listing based on the parameters for selection as
                    specified by You. The Company does not act as an agent of the Recruiter.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> The Company offers no guarantee nor warranties that there
                    would be a satisfactory response or any response at all once the job vacancy is put on display or
                    for any responses received/contacts made through the database.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> The Company shall in no way be held liable for any
                    information obtained/received by You and it shall be Your sole responsibility to check, authenticate
                    and verify the information/response received at Your own cost and expense.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> Any actions taken by an employer/recruiter on the basis of
                    the background check report or otherwise, are the employer’s/recruiter's responsibility alone and
                    the Company will not be liable in any manner for the consequences of such action taken by the user.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> No fee shall be charged by the Recruiter from any person who
                    responds to jobs advertised on the Platform. You further undertake to use the database to contact
                    candidates for jobs only.


                </li>


                <li>
                    <i class="icofont-simple-right"></i> The Company reserves its right to change the look, feel,
                    design, prominence, depiction, classification of the Platform or any portion thereof at any time
                    without providing any prior intimation to you.


                </li>

                <li>
                    <i class="icofont-simple-right"></i> Usage from third party premises/networks without prior
                    written permission of the Company is not permitted.


                </li>
                <li>
                    <i class="icofont-simple-right"></i> You are entitled to store job applications/uploaded CVs on
                    the Platform up to the prescribed allowable limit during the validity period of the services. The
                    Company will not be responsible for restoring data against services that have expired or for job
                    vacancies that have been removed.


                </li>
            </ul>



        </div>

    </div>
</section>