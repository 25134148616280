import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  Api = environment.Api
  constructor(private httpservice: HttpClient) { }


  departmentget() {
    return this.httpservice.get<any>(this.Api + `departmentget`).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }
  locationget() {
    return this.httpservice.get<any>(this.Api + `locationget`).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }
  qualificationget() {
    return this.httpservice.get<any>(this.Api + `qualificationget`).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }
  getsalary() {
    return this.httpservice.get<any>(this.Api + `salaryget`).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }

  joblistget() {
    return this.httpservice.get<any>(this.Api + `joblist`).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }

  jobexperience(value1) {
    return this.httpservice.get<any>(this.Api + `jobexperience/` + value1).pipe(map(res => {

      return res;
    }, (error: any) => {

      return error;
    }));
  }
  //training get starts
  couseProvideGet() {
    return this.httpservice.get<any>(this.Api + `coursesProvide`).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }
  //training get ends
  //examtypelist get starts
  ExamTypeList(user_id) {
    return this.httpservice.get<any>(this.Api + `examTableList/` + user_id).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }

  questionpaperList(data) {
    return this.httpservice.post<any>(this.Api + `questionpaper`, data).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }

  submitAnswers(data) {
    return this.httpservice.post<any>(this.Api + `submitAnswers`, data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  //examtypelist get starts
  jobassignstudentdata(data) {
    return this.httpservice.post<any>(this.Api + `jobassignstudentdata`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  //registration post starts
  registrationPost(data: any) {
    return this.httpservice.post<any>(this.Api + `trainingPostRegistration`, data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  //new data
  newDataget() {
    return this.httpservice.get<any>(this.Api + `newDataGet`).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }

  //recent data get
  getfulltime() {
    return this.httpservice.get<any>(this.Api + `getfulltimeDataGet`).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }

  getparttime() {
    return this.httpservice.get<any>(this.Api + `getparttimeDataGet`).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }

  //popularcomanies get starts
  popularDataget() {
    return this.httpservice.get<any>(this.Api + `popularDataGet`).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  contactUspost(data: any) {
    return this.httpservice.post<any>(this.Api + `ContactUsPost`, data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  getloginprofiledata(data) {
    return this.httpservice.post<any>(this.Api + `getloginprofiledatalist`, data)
  }

  getpaymentprofiledata(data) {
    return this.httpservice.post<any>(this.Api + `getpaymentprofiledatalist`, data)
  }

  getwebsitecount() {
    return this.httpservice.get<any>(this.Api + `getwebsitecount`)
  }

  getStudentProfileData(id) {
    return this.httpservice.get<any>(this.Api + `getStudentProfileData/` + id)
  }

  servicedatasubmit(data) {
    return this.httpservice.post<any>(this.Api + `servicedatasubmit`, data);
  }

  upadtestudentdetails(data) {
    return this.httpservice.post<any>(this.Api + `upadtestudentdetails`, data);
  }

  upadteemployeedetails(data){
    return this.httpservice.post<any>(this.Api  + 'upadteemployeedetails', data)
  }

}


