<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Security Advice</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Security Advice</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <h2 style="text-align: center;
    font-style: inherit;
    font-family: ui-monospace;
    font-weight: 700;">Please read this document carefully</h2><br>
    <div class="container">
        <div class="privacy-item">
            <p>
                As you may be aware, Recruitin.in is only a platform that brings together Jobseekers and Employers. We
                are neither a recruitment firm nor do we act as labor consultants to, or partners of, any employer. The
                portal is used by numerous corporate, placement companies, and recruitment firms. The usage of our site
                is bound by a set of Terms and Conditions that the employers must agree and adhere to. One of the
                primary objectives of these Terms and Conditions is to discourage and prevent misuse and fraud.
            </p>
        </div>

        <div class="privacy-item">

            <p>
                Regrettably, sometimes, false job postings are listed online, and non-existing job offers are sent via
                email to illegally collect personal information and/or money from unsuspecting job seekers. Please note
                that Recruitin.in does not approve of, or represent any employer or recruiter sending such fraudulent
                communication, which in fact are a violation of the Terms and Conditions.
            </p>
            <p>
                The Personal Information that we collect, and how we collect it,
                depends upon how you interact with us. We collect the following
                categories of Personal Information in the following ways:
            </p>
        </div>

        <div class="privacy-item">

            <p>
                In such cases, we remain vigilant every day - When we receive complaints from Jobseekers about
                fraudulent or suspicious emails, we promptly notify the concerned employer client and, if necessary,
                block them from using our services.
            </p>
            <p>
                On basis of past complaints from jobseekers, we encourage you to be wary of emails that
                - Ask your personal, non-work related information such as Credit Card numbers or Bank information over
                phone or email.

            </p>
            <p>
                - Do not provide a call back phone number or provide contact information that is outside the country.
            </p>

        </div>


        <div class="privacy-item">

            <p>
                Ask for monetary transactions, money-transfers, or payment for Job Applications, conducting interviews,
                or any other such employment/recruitment related purpose.
                - Promise a guaranteed job.
                - Promise emigration and ask for money to process visa, etc.
                - Before you respond to such emails, we suggest you to do a discreet enquiry and be sure to verify the
                legitimacy of the employer with whom you are interacting.

            </p>
            <p>
                If you have received (or receive) any such suspicious email communication from a possible Recruitin.in
                client, do report it to us at legal@recruitin.in We value the trust you place on us and are committed to
                making your job search a safe and fraud-free experience on our site.
            </p>
        </div>

        <div class="privacy-item">
            <h2>Educate yourself about Internet and Email Fraud/Scams</h2>
            <p>
                In our effort to curb the practice of fraudulent usage of information over the Internet, we want our
                users to be aware of the most common kinds of Internet fraud. We encourage you to read the following and
                make yourself aware of the warning signs. There are two types of email scams – 'phishing' and
                'spoofing'. In both the cases, the 'from address' is forged to make it appear as if it came from a
                source that it actually did not come from.

            </p>

        </div>

        <div class="privacy-item">
            <h2>What is Phishing?</h2>
            <p>
                Phishing is an attempt by fraudsters to 'fish' for your personal details. A phishing attempt is usually
                in the form of an e-mail, which encourages you to click a link that takes you to a fraudulent log-on
                page designed to capture your account/password/personal details. These emails can also be used to lure
                the recipient into downloading harmful software. Please note that Recruitin.in will never ask you to
                download software in order to access your account. More information about Phishing scams and how you can
                protect yourself against them is available here http://apwg.org/reportphishing/

            </p>
        </div>


        <div class="privacy-item">
            <h2>What is Spoofing?</h2>
            <p>
                Spoof emails usually include a fraudulent offer of employment and/or an invitation to perform a monetary
                transaction. Such email scams are, unfortunately, common across the world and could target anyone -
                including unsuspecting jobseekers who have registered with Recruitin.in The sender's address is often
                disguised and/or the sender may not have provided the entire contact information, such as, the correct
                physical address, phone numbers and email ID.
                The precautionary measures jobseekers could take to protect themselves against suspected spoof emails
                have been mentioned above.
            </p>
        </div>
        
       
    </div>
</section>