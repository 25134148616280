<div class="banner-area banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Find your <span>Dream</span> Job now</h1>
                    <p>Jobs, Employment & Future Career Opportunities</p>
                    <div class="banner-form-area">
                        <form [formGroup]="departmentform">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <select class="form-select" *ngIf="getDepartment.length" style="width:100%"
                                            formControlName="department_name"
                                            (change)="getSelectedJobList($event.target.value, 1)">
                                            <option value="" disabled selected>Choose Job Title</option>
                                            <option *ngFor="let p of getDepartment" [value]="p.department_name">
                                                {{p.department_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <select class="form-select" *ngIf="getLocation.length"
                                            formControlName="location_name" style="width:100%"
                                            (change)="getSelectedJobList($event.target.value, 2)">
                                            <option value="" disabled selected>Location</option>
                                            <option *ngFor="let q of getLocation" [value]="q.location_name"
                                                class="center">{{q.location_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <select class="form-select" *ngIf="qualification.length"
                                            formControlName="qualification" style="width:100%"
                                            (change)="getSelectedJobList($event.target.value, 3)">
                                            <option value="" disabled selected>Education</option>
                                            <option *ngFor="let r of qualification" [value]="r.qualification"
                                                class="center">{{r.qualification}}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <button type="submit" (click)="searchData();changecolor(0);"
                                class="btn banner-form-btn">Search</button>
                        </form>
                    </div>
                    <div class="banner-btn">
                        <a *ngIf="!loginStatus" style="color: #30d05f; border: 1px solid #30d05f;"
                            routerLink="/login">Create Your Profile</a>
                        <a *ngIf="loginStatus && role == 0" style="color: #30d05f; border: 1px solid #30d05f;"
                            routerLink="/walkins">Skill Test</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="account-area" *ngIf="!loginStatus">
    <div class="container">
        <div class="row account-wrap">
            <div class="col-sm-6 col-lg-4" (click)="loginpage()">
                <div class="account-item">
                    <i class="flaticon-approved"></i>
                    <span>Register Your Account</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4" (click)="loginpage()">
                <div class="account-item">
                    <i class="flaticon-cv"></i>
                    <span>Upload Your Resume</span>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4" (click)="loginpage()">
                <div class="account-item account-last">
                    <i class="flaticon-customer-service"></i>
                    <span>Apply for Dream Job</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area pb-70">
    <div class="container">
        <div class="section-title">
        </div>
        <div class="sorting-menu">
            <ul>
                <li class="filter active" [ngClass]=" {'green' : testbtn == 1 } " data-filter="all"
                    (click)="getjoblist(); changecolor(1);">ALL</li>
                <li class="filter" [ngClass]=" {'green' : testbtn == 2 } " data-filter=".web"
                    (click)="new(); changecolor(2);">WALK INS</li>
                <li class="filter" [ngClass]=" {'green' : testbtn == 3 } " data-filter=".ux"
                    (click)="getfulltime(); changecolor(3);">FULL TIME</li>
                <li class="filter" [ngClass]=" {'green' : testbtn == 4 } " data-filter=".wp"
                    (click)="getparttime(); changecolor(4);">PART TIME</li>
            </ul>
        </div>

        <div id="container">
            <div class="row" id="content">
                <div class="col-lg-6 mix web ui" *ngFor="let i of joblist | slice:0:6">
                    <div class="job-item">
                        <img src="{{i.img_url}}" style="width: 80px;" alt="Job"
                            onerror="this.src='assets/img/job-search.svg';">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>{{i.company_name}}</h3>
                                <a routerLink="/company-details">{{i.department_name}}</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> Salary: {{i.salary}}</li>
                                    <li><i class="icofont-location-pin"></i> Location: {{i.location_name}}</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a (click)="jobassignstudent(i)">Apply</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row" *ngFor="let p of websitecount">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3 class="hell"><span class="counter">{{p.available_jobs}}</span>+</h3>
                    <p>Jobs Available</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3 class="hell"><span class="counter">{{p.cv_submitted}}</span>+</h3>
                    <p>Profiles</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3 class="hell"><span class="counter">{{p.total_companies}}</span>+</h3>
                    <p>Companies</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3 class="hell"><span class="counter">{{p.appoitment_jobs }}</span>+</h3>
                    <p>Placed In jobs</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="companies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Top Featured companies</h2>
        </div>

        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of popular">
                <ng-template carouselSlide [id]="slide.id">
                    <div class="companies-item" style="height: 330px;">
                        <img src="{{slide.file}}" alt="img" onerror="this.src='assets/img/server-job.svg';">
                        <h5><a class="colos">{{slide.company_name}}</a></h5>
                        <p><i class="icofont-location-pin"></i>{{slide.address}}</p>
                        <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>


<div class="app-area" style="margin-bottom: 100px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="app-item app-left">
                    <img src="assets/img/home-1/6.png" alt="Mobile">
                    <img src="assets/img/home-1/7.png" alt="Mobile">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="app-item">
                    <div class="section-title text-start">
                        <h2>Download Recruitin Mobile App</h2>
                    </div>

                    <p class="nam">
                        Recruitin Mobile App makes the job search and application process easier and smooth to look for
                        new
                        vacancies daily in a specific industry. As now a days most people have a Resume or CV uploaded
                        on their
                        mobile phones hence Recruitin also provides you too view your interested company page on your
                        smart
                        phones and apply them for the vacancy using your mobile by simply registering online.
                    </p>

                    <!-- <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> -->
                    <div class="app-btn">
                        <a class="app-btn-one" href="https://apps.apple.com/in/developer/aneel-chintha/id1465200947">
                            <i class="flaticon-apple"></i>
                            <span>Download on the</span>
                            <p>App Store</p>
                        </a>
                        <a class="app-btn-two"
                            href="https://play.google.com/store/search?q=amaravathi%20software&c=apps">
                            <i class="flaticon-playstore"></i>
                            <span>ANDROID APP ON</span>
                            <p>Google Play</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>