import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cert',
  templateUrl: './cert.component.html',
  styleUrls: ['./cert.component.scss']
})
export class CertComponent implements OnInit {

    userdata:any[] = [];
    // showOverlay:boolean = true;
    @HostListener('window:afterprint')  onafterprint() {
        sessionStorage.clear();
        this.location.back();
      }

      constructor(private location: Location) { }

  ngOnInit(): void {


  }

  ngAfterViewInit() {
    if(sessionStorage.getItem('certdata')){
        this.userdata = JSON.parse(sessionStorage.getItem('certdata'));
        this.print();
    }
  }

  print(){

    setTimeout( () => {
        // this.showOverlay = false;
        window.print();
    } ,5000)
    // setTimeout(() => {
    //   window.print();
    // }, 2000);

  }

}
