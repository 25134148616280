<div class="page-title-area-services dyno prof"
    [style.background-image]="'url(https://www.recruitin.in/app_images/'+id+'.gif)'">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>{{name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-title-area-services dyno prof-res"
    [style.background-image]="'url(https://www.recruitin.in/app_images/'+id+'.png)'">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>{{name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<br>

<div class="container" *ngIf="fireandsafety">
    <div class="col-12">
        <!-- <div *ngIf="dynoclass"> -->
        <!-- <div class="wrapper"> -->
        <div class="authentication-reset-password d-flex align-items-center justify-content-center back" style="border-radius: 25px;">
            <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:2px 0px 0px 0px">
                <div class="job-inner align-items-center">
                    <div class="row">
                        <div class="col-12 col-lg-10 mx-auto">
                            <div class="dodo">
                                <img src="../../../../assets/img/fire.png" style="border-radius:10px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="login-area pt-100" style="padding-top:40px; padding-bottom :60px">
    <div class="container">

        <div class="containers close" [ngClass]="dynoclass ? 'right-panel-active' : '' " id="container">

            <div class="form-container sign-in-container">

                <!-- <div *ngIf="dynoclass"> -->
                <!-- <div class="wrapper"> -->
                <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:70px 0px;">
                        <div class="job-card" style="color: #b3b9ba;">
                            <div class="job-inner align-items-center">
                                <div class="row">
                                    <div class="col-12 col-lg-10 mx-auto">
                                        <div>
                                            <h3 style="text-align: start;">Contact Us</h3>
                                            <h4 style="text-align: start;font-size: 18px;">Our executive will get in
                                                touch with you soon . </h4>
                                        </div>
                                        <form (ngSubmit)="servicedatasubmit()" [formGroup]="serviceForm">
                                            <div class="row contact-wrap">
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Name</b><span style="color:red">*</span></label> -->
                                                        <input type="text" name="fullname" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;" id="fullname"
                                                            class="form-control fms" formControlName="name"
                                                            placeholder="Full Name"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}">
                                                        <div *ngIf="submitted && addUsersFormCnt['name'].errors"
                                                            class="invalid-feedback">
                                                            <span *ngIf="addUsersFormCnt['name'].errors.required">Please
                                                                Enter User Name.</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Email</b><span style="color:red">*</span></label> -->
                                                        <input type="email" id="email" name="email"
                                                            class="form-control fms" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;"
                                                            formControlName="email"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['email'].errors}"
                                                            placeholder="Email">
                                                        <div *ngIf="submitted && addUsersFormCnt['email'].errors"
                                                            class="invalid-feedback">
                                                            <span
                                                                *ngIf="addUsersFormCnt['email'].errors.required">Please
                                                                Enter Email.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Mobile Number</b><span style="color:red">*</span></label> -->
                                                        <input type="text" id="number" name="number"
                                                            class="phone_number" class="form-control fms"
                                                            formControlName="number" style="background-color: transparent;border: 1px solid;
                                                                    border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                    border-image-slice: 1;border-radius: 0;"
                                                            placeholder="Mobile Number" (keypress)="numericOnly($event)"
                                                            minlength="10" maxlength="10"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['number'].errors}">
                                                        <div *ngIf="submitted && addUsersFormCnt['number'].errors"
                                                            class="invalid-feedback">
                                                            <span
                                                                *ngIf="addUsersFormCnt['number'].errors.required">Please
                                                                Enter Mobile Number.</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-12 col-lg-12" class="bottom">
                                                    <div class="text-center">
                                                        <!-- <button type="submit" class="btn contact-btn" >Submit</button> -->
                                                        <button type="submit" class="btn create-ac-btn"
                                                            [disabled]="!serviceForm.valid">Call me back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>

            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">
                        <div class="dodo">
                            <container-element [ngSwitch]="id">
                                <div *ngSwitchCase="1">
                                    <h3 style="color: white;">Personality Development Training</h3>
                                    <h4 style="font-size: 18px;color: white;">Increases your self-awareness, your
                                        self-esteem, increases your skills, and fulfills your aspirations.</h4>
                                </div>
                                <div *ngSwitchCase="2">
                                    <h3 style="color: white;">English Communication Classes</h3>
                                    <h4 style="font-size: 18px;color: white;">Powerful Learning Methods to Speak English
                                        with Confidence.</h4>
                                </div>
                                <div *ngSwitchCase="3">
                                    <h3 style="color: white;">Fire & Safety Training</h3>
                                    <h4 style="font-size: 18px;color: white;">Fire and safety can be a great career
                                        opportunity for anyone looking for a challenging and competitive career.</h4>
                                </div>
                                <div *ngSwitchCase="4">
                                    <h3 style="color: white;">Resume writing</h3>
                                    <h4 style="font-size: 18px;color: white;">Enhance your Resume to Impress Recruiters
                                        showcase your skills & achievements with a professionally written resume.</h4>
                                </div>
                                <div *ngSwitchCase="5">
                                    <h3 style="color: white;">Mock Interview sessions</h3>
                                    <h4 style="font-size: 18px;color: white;">Gain confidence to crack even the toughest
                                        interviews.</h4>
                                </div>

                                <!-- <some-element *ngSwitchDefault>...</some-element> -->
                            </container-element>
                            <!-- <h3><a class="anc" style="border-bottom: 1px solid;">Talk to our Experts</a> </h3> -->
                            <!-- <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message,here – instead, register with us and our
                                 Human Resources department will contact you after their review of your submitted information. </p> -->
                        </div>
                        <div>
                            <img src="https://www.recruitin.in/app_images/cust.gif" style="height: 390px;" alt="">
                        </div>
                        <div>
                            <!-- Toll Free No: 987654321{{id}} -->
                        </div>

                        <!-- <button type="button" class="ghost" (click)="dynoclass = false; " id="signIn">Sign In</button> -->
                    </div>
                    <!-- <div class="overlay-panel overlay-right">
                        <div>
                            <h2 style="text-align: center;">GET IN TOUCH</h2>
                        </div>

                        <div>
                            <h5><a class="anc" style="border-bottom: 1px solid;">Message Us</a> </h5>
                            <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message,here – instead, register with us and our
                                 Human Resources department will contact you after their review of your submitted information. </p>
                        </div>
                        <button type="button" class="ghost" (click)="dynoclass = true; " id="signUp">Sign In</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="containers-res filter row" id="container">
            <div class="col-12 ">
                <!-- <div *ngIf="dynoclass"> -->
                <!-- <div class="wrapper"> -->
                <div class="authentication-reset-password d-flex align-items-center justify-content-center back">
                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s"
                        style="padding:2px 0px 0px 0px">
                        <div class="job-card" style="color: #b3b9ba;">
                            <div class="job-inner align-items-center">
                                <div class="row">
                                    <div class="col-12 col-lg-10 mx-auto">
                                        <div class="dodo">
                                            <container-element [ngSwitch]="id">
                                                <div *ngSwitchCase="1">
                                                    <h4 style="color: white;">Personality Development
                                                        Training</h4>
                                                    <h5 style="font-size: 18px;color: white;">Increases your
                                                        self-awareness, your self-esteem, increases your
                                                        skills, and fulfills your aspirations.</h5>
                                                </div>
                                                <div *ngSwitchCase="2">
                                                    <h4 style="color: white;">English Communication Classes
                                                    </h4>
                                                    <h5 style="font-size: 18px;color: white;">Powerful
                                                        Learning Methods to Speak English with Confidence.
                                                    </h5>
                                                </div>
                                                <div *ngSwitchCase="3">

                                                    <h4 style="color: white;">Fire & Safety Training</h4>

                                                    <h5 style="font-size: 18px;color: white;">Fire and
                                                        safety can be a great career opportunity for anyone
                                                        looking for a challenging and competitive career.
                                                    </h5>
                                                </div>
                                                <div *ngSwitchCase="4">
                                                    <h4 style="color: white;">Resume writing</h4>
                                                    <h5 style="font-size: 18px;color: white;">Enhance your
                                                        Resume to Impress Recruiters showcase your skills &
                                                        achievements with a professionally written resume.
                                                    </h5>
                                                </div>
                                                <div *ngSwitchCase="5">
                                                    <h4 style="color: white;">Mock Interview sessions</h4>
                                                    <h5 style="font-size: 18px;color: white;">Gain
                                                        confidence to crack even the toughest interviews.
                                                    </h5>
                                                </div>

                                                <!-- <some-element *ngSwitchDefault>...</some-element> -->
                                            </container-element>
                                            <!-- <h3><a class="anc" style="border-bottom: 1px solid;">Talk to our Experts</a> </h3> -->
                                            <!-- <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message,here – instead, register with us and our
                                                         Human Resources department will contact you after their review of your submitted information. </p> -->
                                        </div>
                                        <div>
                                            <img src="https://www.recruitin.in/app_images/cust.gif"
                                                style="height: 390px;" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>

            <div class="  col-12">
                <!-- <div *ngIf="dynoclass"> -->
                <!-- <div class="wrapper"> -->
                <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:70px 0px;">
                        <div class="job-card" style="color: #b3b9ba;">
                            <div class="job-inner align-items-center">
                                <div class="row">
                                    <div class="col-12 col-lg-10 mx-auto">
                                        <div>
                                            <h3 style="text-align: start;">Contact Us</h3>
                                            <h4 style="text-align: start;font-size: 18px;">Our executive
                                                will get in touch with you soon</h4>
                                        </div>
                                        <form (ngSubmit)="servicedatasubmit()" [formGroup]="serviceForm">
                                            <div class="row contact-wrap">
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Name</b><span style="color:red">*</span></label> -->
                                                        <input type="text" name="fullname" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;" id="fullname"
                                                            class="form-control fms" formControlName="name"
                                                            placeholder="Full Name"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}">
                                                        <div *ngIf="submitted && addUsersFormCnt['name'].errors"
                                                            class="invalid-feedback">
                                                            <span *ngIf="addUsersFormCnt['name'].errors.required">Please
                                                                Enter User Name.</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Email</b><span style="color:red">*</span></label> -->
                                                        <input type="email" id="email" name="email"
                                                            class="form-control fms" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;"
                                                            formControlName="email"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['email'].errors}"
                                                            placeholder="Email">
                                                        <div *ngIf="submitted && addUsersFormCnt['email'].errors"
                                                            class="invalid-feedback">
                                                            <span
                                                                *ngIf="addUsersFormCnt['email'].errors.required">Please
                                                                Enter Email.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Mobile Number</b><span style="color:red">*</span></label> -->
                                                        <input type="text" id="number" name="number"
                                                            class="phone_number" class="form-control fms"
                                                            formControlName="number" style="background-color: transparent;border: 1px solid;
                                                                    border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                    border-image-slice: 1;border-radius: 0;"
                                                            placeholder="Mobile Number" (keypress)="numericOnly($event)"
                                                            minlength="10" maxlength="10"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['number'].errors}">
                                                        <div *ngIf="submitted && addUsersFormCnt['number'].errors"
                                                            class="invalid-feedback">
                                                            <span
                                                                *ngIf="addUsersFormCnt['number'].errors.required">Please
                                                                Enter Mobile Number.</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-12 col-lg-12" class="bottom">
                                                    <div class="text-center">
                                                        <!-- <button type="submit" class="btn contact-btn" >Submit</button> -->
                                                        <button type="submit" class="btn create-ac-btn"
                                                            [disabled]="!serviceForm.valid">Call me
                                                            back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>

        </div>

    </div>
</div>