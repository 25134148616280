<div class="page-title-area-recruit">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Create Account</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Create Account</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area close" style="padding-top: 40px; padding-bottom: 60px" *ngIf="!loginStatus || role != 1">
    <div class="create-account-area pt-100 pb-100" style="padding-top: 195px">
        <div class="container">
            <div class="login-reg-panel">
                <div class="login-info-box">
                    <h1 style="color: white">Already have an account?</h1>
                    <p style="color: white">Please Sign In here</p>
                    <label id="label-register" for="log-reg-show">Sign In
                    </label>
                    <input type="radio" class="login-radio" (click)="changeCard(); openform(1)" name="active-log-panel"
                        id="log-reg-show" checked="checked" />
                </div>

                <div class="register-info-box">
                    <h1 style="color: white">Don't have an account?</h1>
                    <p style="color: white">Please Sign up as here</p>
                    <label id="label-login" for="log-login-show">Register
                    </label>
                    <input type="radio" class="login-radio" (click)="changeCard(); openform(2); scroll(div)"
                        name="active-log-panel" id="log-login-show" />
                </div>

                <div class="white-panel">
                    <div class="login-show">
                        <div class="wrapper"
                            style=" position: relative;  height: 440px;width: 100%; overflow-x: hidden;">
                            <div class="job-card" style="color: #b3b9ba" style="z-index: 0;">
                                <div class="job-inner" style="z-index: 0;">
                                    <div class="row" style="z-index: 0;">
                                        <div class="col-lg-12">
                                            <div class="create-information-new bds">
                                                <h4 class="font-weight-bold"> Login to continue </h4>
                                                <p class="text-muted"> Please Enter Recruiter Mobile Number! </p>
                                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                                    <div class="mb-3" *ngIf="!otpget">
                                                        <div id="recaptcha-container"></div>
                                                        <div id="show_hide_password">
                                                            <label>Phone Number</label>
                                                            <mat-form-field>
                                                                <ngx-mat-intl-tel-input class="form-control"
                                                                    formControlName="phone" [enablePlaceholder]=" true "
                                                                    [enableSearch]=" true" name="phone" #phone
                                                                    [preferredCountries]="['in']"
                                                                    describedBy="phoneInput">
                                                                </ngx-mat-intl-tel-input>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3" *ngIf="otpget">
                                                        <div class="position-relative has-icon-left">
                                                            <label for="password" class="form-label">OTP</label>
                                                            <div class="input-group" id="show_hide_password">
                                                                <input type="text" class="form-control border-end-0"
                                                                    formControlName="usr_pwd" id="password"
                                                                    class="form-control" placeholder="Enter OTP"
                                                                    (keypress)=" numericOnly($event) "
                                                                    (paste)=" $event.preventDefault()"
                                                                    [ngClass]="{'is-invalid': submitted && f[ 'usr_pwd' ].errors}" />
                                                            </div>
                                                            <div *ngIf=" submitted &&f['usr_pwd'].errors "
                                                                class="invalid-feedback">
                                                                <span *ngIf=" f['usr_pwd'].errors.required">
                                                                    Please Enter Your OTP
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cust-resend" *ngIf="otpget">
                                                        <!-- <div class="icheck-material-primary" (click)="getOtp()">
                                                            <span class="resend" *ngIf="refresh">
                                                                <span class="material-icons cust-icon">
                                                                    replay_30
                                                                </span>
                                                                <span>Resend</span>
                                                            </span>
                                                            <span *ngIf="timer$ | async as timer ">
                                                                Re Send OTP in <span>{{ timer }} Sec</span>
                                                            </span>
                                                        </div> -->
                                                        <div class="icheck-material-primary"
                                                            (click)="onResetpassword2()">
                                                            <a href="JavaScript:;">Wrong Number</a>
                                                        </div>
                                                    </div>
                                                    <div class="d-grid gap-2">
                                                        <button *ngIf="!otpget" type="button" (click)="getOtp()"
                                                            style="width: 100%"
                                                            class="btn btn-primary btn-block waves-effect waves-light log-btn curse"
                                                            [disabled]="submitted">
                                                            Request OTP
                                                        </button>
                                                        <button *ngIf="otpget" type="submit" style="width: 100%"
                                                            class="btn btn-primary btn-block waves-effect waves-light log-btn curse">
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="register-show">
                        <div class="wrapper" style="
                                position: relative;
                                height: 440px;
                                width: 100%;
                                overflow-x: hidden;
                                overflow-y: scroll; ">
                            <div class="job-card" style="color: #b3b9ba">
                                <div class="job-inner">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div>
                                                <div style="width: 100%">
                                                    <div class="static-list">
                                                        <div class="create-information bds">
                                                            <h6 class="hed">
                                                                On registering,
                                                                you can
                                                            </h6>
                                                            <ul>
                                                                <li>
                                                                    <p>
                                                                        Build
                                                                        your
                                                                        profile
                                                                        and let
                                                                        recruiters
                                                                        find you
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p>
                                                                        Get job
                                                                        postings
                                                                        delivered
                                                                        right to
                                                                        your
                                                                        email
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p>
                                                                        Find a
                                                                        job and
                                                                        grow
                                                                        your
                                                                        career
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                            <!-- </div> -->
                                                            <h3 style="
                                                                    text-align: center;
                                                                ">
                                                                Create Recruiter
                                                                Profile in Below
                                                                Form
                                                            </h3>
                                                            <div style="
                                                                    text-align: center;
                                                                ">
                                                                <i style="
                                                                        color: #37e15e;
                                                                        font-size: 37px;
                                                                    " (click)="
                                                                        scroll(
                                                                            div
                                                                        )
                                                                    " class="fa fa-arrow-circle-down"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-12">
                                            <div class="create-information bds">
                                                <h2>Login Your Account</h2>
                                                <h3 style="text-align: center;"> Create Recruiter Profile</h3>
                                                <form (ngSubmit)="recruiterSignupPost()" [formGroup]="recruiterSignup">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label> Recruiter Name</label>
                                                            <input type="text" class="form-control form-control-custom"
                                                                id="validationCustom05" formControlName="recruiter_name"
                                                                required
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['recruiter_name'].errors}">

                                                            <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_name'].errors"
                                                                class="invalid-feedback">
                                                                <span
                                                                    *ngIf="addUsersFormCnt1['recruiter_name'].errors.required">Please
                                                                    Enter Your
                                                                    Recruiter Name </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label> Email</label>
                                                            <input type="email" class="form-control form-control-custom"
                                                                id="validationCustom05"
                                                                formControlName="recruiter_email" required
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['recruiter_email'].errors}">
                                                            <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_email'].errors"
                                                                class="invalid-feedback">
                                                                <span
                                                                    *ngIf="addUsersFormCnt1['recruiter_email'].errors.required">Please
                                                                    Enter Your
                                                                    Email</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" style="padding-top:10px">
                                                        <div class="col-lg-6">
                                                            <label>Mobile Number</label>
                                                            <input type="text" class="form-control form-control-custom"
                                                                id="validationCustom05" formControlName="mobile_number"
                                                                required (keypress)="numericOnly($event)" minlength="10"
                                                                maxlength="10"
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['mobile_number'].errors}">
                                                            <div *ngIf="submitteddata && addUsersFormCnt1['mobile_number'].errors"
                                                                class="invalid-feedback">
                                                                <span
                                                                    *ngIf="addUsersFormCnt1['mobile_number'].errors.required">Please
                                                                    Enter Your Mobile Number</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <label>Company Name</label>
                                                            <input type="text" class="form-control form-control-custom"
                                                                id="validationCustom05" formControlName="company_name"
                                                                required
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['company_name'].errors}">
                                                            <div *ngIf="submitteddata && addUsersFormCnt1['company_name'].errors"
                                                                class="invalid-feedback">
                                                                <span
                                                                    *ngIf="addUsersFormCnt1['company_name'].errors.required">Please
                                                                    Enter Your Company Name</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label>Industry Type</label><br>
                                                            <input type="text" class="form-control form-control-custom"
                                                                id="validationCustom05" formControlName="industry_type"
                                                                required
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['industry_type'].errors}">
                                                            <div *ngIf="submitteddata && addUsersFormCnt1['industry_type'].errors"
                                                                class="invalid-feedback">
                                                                <span
                                                                    *ngIf="addUsersFormCnt1['industry_type'].errors.required">Please
                                                                    Enter Your Industry Type</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Address</label>
                                                            <textarea id="your_message" class="form-control form-control-custom"
                                                                formControlName="recruiter_address"
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['recruiter_address'].errors}">
                                                                                <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_address'].errors"
                                                                                class="invalid-feedback">
                                                                                <span *ngIf="addUsersFormCnt1['recruiter_address'].errors.required">Please
                                                                                    Enter Your Address</span>
                                                                </div>
                                                             </textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-lg-12 col-xl-12">
                                                            <div class="form-group">
                                                                <label for="input-2" style="color: #ff7555;">Please
                                                                    Upload Your
                                                                    Logo
                                                                </label>
                                                                <input class="image"
                                                                    class="form-control form-control-custom" type="file"
                                                                    id="formFile" (change)="onFileupload($event)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label>Description</label>
                                                            <textarea id="your_message"
                                                                class="form-control form-control-custom"  formControlName="recruiter_description"
                                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['recruiter_description'].errors}">
                                                                <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_description'].errors"
                                                                        class="invalid-feedback">
                                                                    <span *ngIf="addUsersFormCnt1['recruiter_description'].errors.required">Please
                                                                        Enter Your Description</span>
                                                                </div>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div style="text-align: center;">
                                                        <button type="submit"
                                                            style="margin-top:50px; border-radius: 40px;"
                                                            class="btn create-ac-btn">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
</div>

<div #div>
    <div class="create-account-area pt-100" *ngIf="regform">
        <div class="container">
            <div class="job-card" style="color: #b3b9ba">
                <div class="job-inner">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="create-information bds">
                                <h3 style="text-align: center">
                                    Create Recruiter Profile
                                </h3>
                                <form (ngSubmit)="recruiterSignupPost()" [formGroup]="recruiterSignup">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label> Recruiter Name</label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="recruiter_name" required
                                                [ngClass]="{
                                                    'is-invalid':  submitteddata && addUsersFormCnt1[ 'recruiter_name' ].errors }" />

                                            <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_name' ].errors "
                                                class="invalid-feedback">
                                                <span *ngIf=" addUsersFormCnt1[ 'recruiter_name'].errors.required
                                                    ">Please Enter Your Recruiter Name </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label> Email</label>
                                            <input type="email" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="recruiter_email" required
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'recruiter_email' ].errors }" />
                                            <div *ngIf=" submitteddata && addUsersFormCnt1[ 'recruiter_email'].errors "
                                                class="invalid-feedback">
                                                <span
                                                    *ngIf=" addUsersFormCnt1[ 'recruiter_email'].errors.required">Please
                                                    Enter Your Email</span>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4">
                                            <label>Mobile Number</label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="mobile_number" required
                                                (keypress)="numericOnly($event)" minlength="10" maxlength="10"
                                                [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['mobile_number'].errors}">
                                            <div *ngIf="submitteddata && addUsersFormCnt1['mobile_number'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt1['mobile_number'].errors.required">Please
                                                    Enter Your Mobile Number</span>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-4">
                                            <label>Phone number</label>
                                            <mat-form-field>
                                                <!-- <mat-label>Phone Number</mat-label> -->
                                                <ngx-mat-intl-tel-input formControlName="phone"
                                                    [enablePlaceholder]="true" [enableSearch]="true" name="phone" #phone
                                                    [preferredCountries]="['in']"
                                                    class="form-control form-control-custom">
                                                </ngx-mat-intl-tel-input>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>Company Name</label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="company_name" required
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'company_name'
                                                        ].errors }" />
                                            <div *ngIf="
                                                    submitteddata &&
                                                    addUsersFormCnt1[
                                                        'company_name'
                                                    ].errors
                                                " class="invalid-feedback">
                                                <span *ngIf="
                                                        addUsersFormCnt1[
                                                            'company_name'
                                                        ].errors.required
                                                    ">Please Enter Your Company
                                                    Name</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Industry Type</label><br />
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="industry_type" required
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'industry_type'
                                                        ].errors
                                                }" />
                                            <div *ngIf="
                                                    submitteddata &&
                                                    addUsersFormCnt1[
                                                        'industry_type'
                                                    ].errors
                                                " class="invalid-feedback">
                                                <span *ngIf="
                                                        addUsersFormCnt1[
                                                            'industry_type'
                                                        ].errors.required
                                                    ">Please Enter Your Industry
                                                    Type</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>Address</label>
                                            <textarea id="your_message" class="form-control form-control-custom"
                                                formControlName="recruiter_address" [ngClass]="{
                                                    'is-invalid':
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'recruiter_address'
                                                        ].errors
                                                }">
                                            <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_address'].errors"
                                                            class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt1['recruiter_address'].errors.required">Please
                                                    Enter Your Address</span>
                                            </div>
                                        </textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label>Description</label>
                                            <textarea id="your_message" class="form-control form-control-custom"
                                                formControlName="recruiter_description" [ngClass]="{
                                                    'is-invalid':
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'recruiter_description'
                                                        ].errors
                                                }">
                                                <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_description'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt1['recruiter_description'].errors.required">Please
                                                    Enter  Description</span>
                                                </div>
                                            </textarea>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="input-2" style="color: #ff7555">Please Upload Your Logo
                                                </label>
                                                <input class="image" class="form-control form-control-custom"
                                                    type="file" id="formFile" (change)="
                                                        onFileupload($event)
                                                    " />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="text-align: center">
                                        <button type="submit" style="
                                                margin-top: 50px;
                                                border-radius: 40px;
                                            " class="btn create-ac-btn">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ////////////////////////////////////////////////////mobile Responsive/////////////////////////////////////////////// -->

<div class="login-area pt-100 filter" style="padding-top: 40px; padding-bottom: 60px" *ngIf="!loginStatus || role != 1">
    <div class="container">
        <div class="containers-res" [ngClass]="dynoclass ? 'right-panel-active-res' : ''" id="container">
            <div class="form-container sign-up-container-res">
                <div class="wrapper" style="
                        position: relative;
                        height: 500px;
                        width: 100%;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        padding: 10px;
                    ">
                    <div class="job-card" style="color: #b3b9ba">
                        <div class="job-inner align-items-center">
                            <div class="row">
                                <div class="col-12 col-lg-10 mx-auto">
                                    <div class="text-start">
                                        <img src="assets/images/Logo (5).png" width="180" alt="" />
                                    </div>

                                    <form (ngSubmit)="recruiterSignupPost()" [formGroup]="recruiterSignup">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label> Recruiter Name</label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="recruiter_name" required
                                                    [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'recruiter_name'
                                                            ].errors
                                                    }" />

                                                <div *ngIf="
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'recruiter_name'
                                                        ].errors
                                                    " class="invalid-feedback">
                                                    <span *ngIf="
                                                            addUsersFormCnt1[
                                                                'recruiter_name'
                                                            ].errors.required
                                                        ">Please Enter Your
                                                        Recruiter Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label> Email</label>
                                                <input type="email" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="recruiter_email" required
                                                    [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'recruiter_email'
                                                            ].errors
                                                    }" />
                                                <div *ngIf="
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'recruiter_email'
                                                        ].errors
                                                    " class="invalid-feedback">
                                                    <span *ngIf="
                                                            addUsersFormCnt1[
                                                                'recruiter_email'
                                                            ].errors.required
                                                        ">Please Enter Your
                                                        Email</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" style="padding-top: 10px">
                                            <div class="col-lg-6">
                                                <label>Mobile Number</label>
                                                <!-- <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="mobile_number" required
                                                    (keypress)="numericOnly($event)" minlength="10" maxlength="10"
                                                    [ngClass]="{'is-invalid': submitteddata && addUsersFormCnt1['mobile_number'].errors}">
                                                <div *ngIf="submitteddata && addUsersFormCnt1['mobile_number'].errors"
                                                    class="invalid-feedback">
                                                    <span
                                                        *ngIf="addUsersFormCnt1['mobile_number'].errors.required">Please
                                                        Enter Your Mobile Number</span>
                                                </div> -->
                                                <mat-form-field>
                                                    <!-- <mat-label >PhoneNumber</mat-label> -->
                                                    <ngx-mat-intl-tel-input class="form-control" formControlName="phone"
                                                        class="form-control form-control-custom"
                                                        [enablePlaceholder]=" true " [enableSearch]=" true" name="phone"
                                                        #phone [preferredCountries]="['us']" describedBy="phoneInput">
                                                    </ngx-mat-intl-tel-input>
                                                </mat-form-field>
                                            </div>

                                            <!-- <mat-form-field>
                                                <mat-label
                                                    >Phone Number</mat-label
                                                >
                                                <ngx-mat-intl-tel-input
                                                    formControlName="phone"
                                                    [enablePlaceholder]="true"
                                                    [enableSearch]="true"
                                                    name="phone"
                                                    #phone
                                                    [preferredCountries]="[
                                                        'us'
                                                    ]"
                                                    placeholder="Your Phone Number"
                                                    describedBy="phoneInput"
                                                >
                                                </ngx-mat-intl-tel-input>
                                                <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>
                                            </mat-form-field> -->
                                            <!-- <input type="text" id="mobile_code" class="form-control" placeholder="Phone Number" name="name"> -->
                                            <!-- <div class="mb-2">
                                                <ngx-intl-tel-input [cssClass]="''" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                                                    [phoneValidation]="true" [separateDialCode]="true" name="phone" formControlName="phone">
                                                </ngx-intl-tel-input>
                                            </div> -->
                                            <div class="col-lg-6">
                                                <label>Company Name</label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="company_name" required
                                                    [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'company_name'
                                                            ].errors
                                                    }" />
                                                <div *ngIf="
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'company_name'
                                                        ].errors
                                                    " class="invalid-feedback">
                                                    <span *ngIf="
                                                            addUsersFormCnt1[
                                                                'company_name'
                                                            ].errors.required
                                                        ">Please Enter Your
                                                        Company Name</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>Industry Type</label><br />
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="industry_type" required
                                                    [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'industry_type'
                                                            ].errors
                                                    }" />

                                                <div *ngIf="
                                                        submitteddata &&
                                                        addUsersFormCnt1[
                                                            'industry_type'
                                                        ].errors
                                                    " class="invalid-feedback">
                                                    <span *ngIf="
                                                            addUsersFormCnt1[
                                                                'industry_type'
                                                            ].errors.required
                                                        ">Please Enter Your
                                                        Industry Type</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Address</label>
                                                <textarea id="your_message" class="form-control form-control-custom"
                                                    formControlName="recruiter_address" [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'recruiter_address'
                                                            ].errors
                                                    }">
                                                                <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_address'].errors"
                                                                class="invalid-feedback">
                                                                <span *ngIf="addUsersFormCnt1['recruiter_address'].errors.required">Please
                                                                    Enter Your Address</span>
                                                            </div>
                                                            </textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-12 col-xl-12">
                                                <div class="form-group">
                                                    <label for="input-2" style="color: #ff7555">Please Upload Your Logo
                                                    </label>
                                                    <input class="image" class="form-control form-control-custom"
                                                        type="file" id="formFile" (change)="
                                                            onFileupload($event)
                                                        " />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label>Description</label>
                                                <textarea id="your_message" class="form-control form-control-custom"
                                                    formControlName="recruiter_description" [ngClass]="{
                                                        'is-invalid':
                                                            submitteddata &&
                                                            addUsersFormCnt1[
                                                                'recruiter_description'
                                                            ].errors
                                                    }">
                                                            <div *ngIf="submitteddata && addUsersFormCnt1['recruiter_description'].errors"
                                                                class="invalid-feedback">
                                                                <span *ngIf="addUsersFormCnt1['recruiter_description'].errors.required">Please
                                                                    Enter Your Description</span>
                                                            </div>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div style="text-align: center">
                                            <button type="submit" style="
                                                    margin-top: 50px;
                                                    border-radius: 40px;
                                                " class="btn create-ac-btn">
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="login-sign-in">
                                <ul>
                                    <li>Don’t Have Account ?</li>
                                    <li><a>Sign Up Here</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container sign-in-container-res" style="z-index: 0;">
                <div *ngIf="!dynoclass">
                    <div class="wrapper">
                        <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                            <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                <div class="job-card" style="color: #b3b9ba">
                                    <div class="job-inner align-items-center">
                                        <div class="row">
                                            <div class="col-12 col-lg-10 mx-auto">
                                                <div class="text-start">
                                                    <img src="assets/images/Logo (5).png" width="180" alt="" />
                                                </div>
                                                <h5 class="font-weight-bold">
                                                    Login as Recruiter With OTP
                                                </h5>
                                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                                    <div class="mb-3" *ngIf="!otpget">
                                                        <div class="position-relative has-icon-left">
                                                            <!-- <div
                                                                class="input-group"
                                                                id="show_hide_password"
                                                            > -->
                                                            <div id="show_hide_password">
                                                                <mat-form-field>
                                                                    <!-- <mat-label >PhoneNumber</mat-label> -->
                                                                    <ngx-mat-intl-tel-input class="form-control"
                                                                        formControlName="phone"
                                                                        class="form-control form-control-custom"
                                                                        [enablePlaceholder]=" true "
                                                                        [enableSearch]=" true" name="phone" #phone
                                                                        [preferredCountries]="['us']"
                                                                        describedBy="phoneInput">
                                                                    </ngx-mat-intl-tel-input>
                                                                </mat-form-field>
                                                            </div>
                                                            <!-- <input
                                                                    type="text"
                                                                    id="email"
                                                                    formControlName="phone"
                                                                    class="form-control form-control-custom"
                                                                    placeholder="Mobile Number"
                                                                    maxlength="10"
                                                                    minlength="10"
                                                                    (keypress)="
                                                                        numericOnly(
                                                                            $event
                                                                        )
                                                                    "
                                                                    (paste)="
                                                                        $event.preventDefault()
                                                                    "
                                                                    [ngClass]="{
                                                                        'is-invalid':
                                                                            submitted &&
                                                                            f[
                                                                                'phone'
                                                                            ]
                                                                                .errors
                                                                    }"
                                                                /> -->
                                                        </div>
                                                        <div *ngIf="
                                                                    submitted &&
                                                                    f['phone']
                                                                        .errors
                                                                " class="invalid-feedback">
                                                            <div *ngIf="
                                                                        f.phone  .errors  .required  ">
                                                                Please Enter
                                                                Mobile
                                                                Number
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="mb-3" *ngIf="otpget">
                                                        <div class="position-relative has-icon-left">
                                                            <label for="password" class="form-label">OTP</label>
                                                            <div class="input-group" id="show_hide_password">
                                                                <input type="text" class="form-control border-end-0"
                                                                    formControlName="usr_pwd" id="password"
                                                                    class="form-control form-control-custom"
                                                                    placeholder="Enter OTP" (keypress)="
                                                                        numericOnly(
                                                                            $event
                                                                        )
                                                                    " (paste)="
                                                                        $event.preventDefault()
                                                                    " [ngClass]="{
                                                                        'is-invalid':
                                                                            submitted &&
                                                                            f[
                                                                                'usr_pwd'
                                                                            ]
                                                                                .errors
                                                                    }" />
                                                                <a href="javascript:;"
                                                                    class="input-group-text bg-transparent"><span
                                                                        class="material-icons">lock</span></a>
                                                            </div>
                                                            <div *ngIf="
                                                                    submitted &&
                                                                    f['usr_pwd']
                                                                        .errors
                                                                " class="invalid-feedback">
                                                                <span *ngIf="
                                                                        f[
                                                                            'usr_pwd'
                                                                        ].errors
                                                                            .required
                                                                    ">Please
                                                                    Enter Your
                                                                    OTP</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cust-resend" *ngIf="otpget">
                                                        <div class="icheck-material-primary" (click)="getOtp()">
                                                            <span class="resend" *ngIf="refresh">
                                                                <span class="material-icons cust-icon">
                                                                    replay_30
                                                                </span>
                                                                <span>Resend</span>
                                                            </span>
                                                            <span *ngIf="
                                                                    timer$
                                                                        | async as timer
                                                                ">Re Send OTP in
                                                                <span>{{ timer }}
                                                                    Sec
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="icheck-material-primary" (click)="
                                                                onResetpassword2()
                                                            ">
                                                            <a href="JavaScript:;">Wrong Number</a>
                                                        </div>
                                                    </div>
                                                    <div class="d-grid gap-2">
                                                        <button *ngIf="!otpget" type="button" (click)="getOtp()"
                                                            style="width: 100%"
                                                            class="btn btn-primary btn-block waves-effect waves-light log-btn curse"
                                                            [disabled]="
                                                                submitted
                                                            ">
                                                            Request OTP
                                                        </button>
                                                        <button *ngIf="otpget" type="submit" style="width: 100%"
                                                            class="btn btn-primary btn-block waves-effect waves-light log-btn curse">
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="login-sign-in">
                                            <ul>
                                                <li>Don’t Have Account ?</li>
                                                <li><a>Sign Up</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay-container-res">
                <div class="overlay-res">
                    <div class="overlay-panel-res overlay-left-res">
                        <h1>Welcome Back!</h1>
                        <p>Already have an account?</p>
                        <p>Please Login here</p>
                        <button type="button" class="ghost" (click)="
                                dynoclass = false;
                                onResetpassword2();
                                changeCard()
                            " id="signIn">
                            Sign In
                        </button>
                    </div>
                    <div class="overlay-panel-new overlay-right-res">
                        <h1>Welcome Back!</h1>
                        <p>Are you new here?</p>
                        <p>Please Sign up as a Recruiter</p>
                        <button type="button" class="ghost" (click)="dynoclass = true; changeCard()" id="signUp">
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ////////////////////////////////////////////////////mobile Responsive/////////////////////////////////////////////// -->

<div style="font-family: Times New Roman">
    <section class="faq-area">
        <div class="container">
            <div class="row faq-wrap">
                <div class="col-lg-12">
                    <div class="faq-head">
                        <h2 style="text-align: center">
                            Quick & Easy Job Posting − Get Quality Applies
                        </h2>
                    </div>
                    <div class="pricing-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 col-lg-3" *ngFor="let i of packages">
                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
                                        <span style="font-size: 32px">{{ i.packagename }} </span><br />
                                        <h2>₹ {{ i.packageamount }}</h2>
                                        <p style="font-size: 20px">
                                            {{ i.uploadresume }} Resumes
                                        </p>
                                        <ul>
                                            <li style="font-size: 20px">
                                                {{ i.description }}
                                            </li>
                                        </ul>
                                        <br />
                                        <div style="margin-bottom: 3%">
                                            <button type="button" class="btn btn-primary" (click)="submitbuynow(i)">
                                                Buy Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 style="text-align: center" class="head">
                            Why Choose Recruitin Job Posting?
                        </h3>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card" style="color: #b3b9ba">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon1.png" style="
                                                    height: 64px;
                                                    width: 65px;
                                                " />
                                            <h4>2 Minutes To Post</h4>
                                            <h5>
                                                Quick and easy to post job
                                                posting with highly optimised
                                                job posting form
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon2.png" style="
                                                    height: 64px;
                                                    width: 65px;
                                                " />
                                            <i class="fa-brands fa-searchengin"></i>
                                            <h4>Attract Audience</h4>
                                            <h5>
                                                Reach to over 8.27 crore
                                                talented jobseekers
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon3.png" style="
                                                    height: 64px;
                                                    width: 65px;
                                                " />
                                            <h4>30 days visibility</h4>
                                            <h5>
                                                Get quality applies guaranteed
                                                with 30 days visibility of your
                                                job ads.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>