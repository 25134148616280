import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SerService {
  Api = environment.Api
  constructor(private http:HttpClient) { }

  getpackagedatalist() {
    return this.http.get<any>(this.Api + `getpackagedata`)
  }
}
