import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-packageprofile',
  templateUrl: './packageprofile.component.html',
  styleUrls: ['./packageprofile.component.scss']
})
export class PackageprofileComponent implements OnInit {
  profilearr:any;
  paymentarr:any;
  role:any;
  studentdata:any [] = [];
  getexamtest:any[] = [];
  constructor(private profileservice:ServiceService, private router: Router ) {
    this.getStudentProfileData();
    this.role =  localStorage.getItem('role');
    this.getExamTypeList();
   }

  ngOnInit(): void {
    this.getloginprofile();
    this.getpaymentprofile();
  }

  getExamTypeList() {
    var user_id = localStorage.getItem('user_id')
    this.profileservice.ExamTypeList(user_id).subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getexamtest = Result.data.filter( res => {
            return res.completed == 1;
        })
        this.getexamtest.map(  poco => {
            poco.result = Math.round((poco.score / poco.total_marks) * 100)
       })
       this.getexamtest.sort( (a:any , b:any) => b.result - a.result );
        console.log(this.getexamtest);
      }
    })
  }

  getStudentProfileData(){
    var id = localStorage.getItem('user_id')
    this.profileservice.getStudentProfileData(id).subscribe(data => {
          this.studentdata = data.data;
          console.log(data.data);
      }, er => {
        //console.log(er);
      })
  }

  getloginprofile() {
    var data={
      'companyuser_id':localStorage.getItem('companyuser_id'),
    }
    this.profileservice.getloginprofiledata(data).subscribe(data => {
      if (data.status == 200) {
        this.profilearr = data.data;
        //console.log(data.data);
      }
    }, er => {
      //console.log(er);
    })

  }

  getpaymentprofile() {
    var data={
      'companyuser_id':localStorage.getItem('companyuser_id'),
    }
    this.profileservice.getpaymentprofiledata(data).subscribe(data => {
      if (data.status == 200) {
        this.paymentarr = data.data;
        //console.log(data.data);
      }
    }, er => {
      //console.log(er);
    })

  }
  startTest(id, limt, time){
    if (localStorage.getItem('role') == '0') {
        this.router.navigate(['/blog-details', {id :id, limit: limt, it:time }]);
    } else {
        this.router.navigate(['/login', {index: 1}]);
    }

  }

  genarateCert(date, exam){
    var data = {
        date: date,
        exam:exam,
        name: this.studentdata[0].name

    }
    console.log(date);

    sessionStorage.setItem('certdata', JSON.stringify([data]) )
    this.router.navigate(['/cert'])
  }

}
