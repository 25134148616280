import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServiceService } from '../service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  loginStatus: boolean = false;
  testbtn = 0;
  title = 'ng-carousel-demo';
  getDepartment: any = [];
  searchgetDepartment: any = [];
  getLocation: any = [];
  qualification: any = [];
  searchggetLocation: any = [];
  searchqualification: any = [];
  software: any = [];
  joblist: any = [];
  globaljoblist: any = [];
  departenments: any = [];
  locations: any = [];
  addjobEducationForm: FormGroup;
  departmentdata: any;
  newdata: any = [];
  popular: any = [];
  role: any;
  public alljobs: Array<any> = [
  ];
  langauge_id: any
  departmentform: any;
  //   router: any;
  addreceipt: any;
  websitecount: any;
  constructor(private fvv: FormBuilder, private serv: ServiceService, private router: Router) {
    this.departmentform = this.fvv.group({
      department_name: [''],
      location_name: [''],
      qualification: ['']
    });
  }

  ngOnInit(): void {
    this.langauge_id = localStorage.getItem('language_id')
    if (localStorage.getItem('user_id')) {
      this.loginStatus = true;
      this.role = localStorage.getItem('role');
    }
    this.getwebsitecount();
    this.getDepartmentList();
    this.getLocationList();
    this.geQualificationList();
    this.getjoblist();
    this.submitdepartmentdata();
    this.new();
    this.getfulltime();
    this.getparttime();
    this.popularCompanies()

    // this.receipt();

    this.getjoblist();
  }

  changecolor(index) {
    this.testbtn = index;
    if (index == 2) {
      // this.router.navigate(['walkins'])
    }
  }

  getDepartmentList() {
    this.serv.departmentget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getDepartment = Result.data;
        console.log(this.getDepartment);
      }
    })
  }

  getSearchProducts(event) {
    console.log(event.target.value);
    if (event.target.value.length) {
      this.searchgetDepartment = this.getDepartment.filter(res => res.department_name.toLowerCase().includes(event.target.value));
    } else {
      this.searchgetDepartment = [];
    }
  }
  getSearchJoblist(event) {
    console.log(event.target.value);
    if (event.target.value.length) {
      this.searchggetLocation = this.getLocation.filter(res => res.location_name.toLowerCase().includes(event.target.value));
    } else {
      this.searchggetLocation = [];
    }
  }
  getSearchQualification(event) {
    console.log(event.target.value);
    if (event.target.value.length) {
      this.searchqualification = this.qualification.filter(res => res.qualification.toLowerCase().includes(event.target.value));
    } else {
      this.searchqualification = [];
    }
  }

  getLocationList() {
    this.serv.locationget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getLocation = Result.data;
        console.log(this.getLocation);
      }
    })
  }
  geQualificationList() {
    this.serv.qualificationget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.qualification = Result.data;
        console.log(this.qualification);
      }
    })
  }
  //clickevent starts
  getSelectedJobList(jobtype, ind) {
    this.joblist = [];
    if (ind == 1) {
      this.globaljoblist.map(obj => {
        if (obj.department_name == jobtype) {
          this.joblist.push(obj)
        }
      })
    }
    else if (ind == 2) {
      this.globaljoblist.map(obj => {
        if (obj.location_name == jobtype) {
          this.joblist.push(obj)
        }
      })
    }
    else if (ind == 3) {
      this.globaljoblist.map(obj => {
        // console.log(obj.qualification_name +"=="+ jobtype);
        if (obj.qualification_name == jobtype) {
          this.joblist.push(obj)
        }
      })
    }
  }
  //clickevent ends
  getdepartmentlist(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Department == e) {
        this.software.push(obj)
      }
    })
  }
  getLocationlist(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Location == e) {
        this.software.push(obj)
      }
    })
  }
  getQualificationtList(e) {
    this.software = []
    console.log(e);
    this.alljobs.map(obj => {
      if (obj.Qualification == e) {
        this.software.push(obj)
      }
    })
  }
  getjoblist() {
    this.serv.joblistget().subscribe((Result: any) => {
      if (Result.status == 200) {
        this.joblist = Result.data;
        this.globaljoblist = Result.data;
        console.log(this.globaljoblist);
      }
    })
  }

  searchdepartments(value) {


    var filter = this.globaljoblist.filter(repo => {
      return repo.department_name == value;
    })

    console.log(filter, value);
    this.joblist = filter;
    if (filter.length == 0) {
      this.errorMessageAlert('No Data Found')
    }
    document.getElementById("content").scrollIntoView();
  }

  //search data starts
  submitdepartmentdata() {
    //  this.router.navigate(['/faq']);
    // console.log(this.addjobEducationForm.value);
    // var departmentdata = this.addjobEducationForm.value.department_name.split(',')
    // this.addjobEducationForm.value.department_name = departmentdata[0]
    // var locationdata = this.addjobEducationForm.value.location_name.split(',')
    // this.addjobEducationForm.value.location_name = departmentdata[0]
    // var qualificationdata = this.addjobEducationForm.value.qualification.split(',')
    // this.addjobEducationForm.value.qualification = departmentdata[0]
    //   var data = {
    //   departmentdata:this.addjobEducationForm.value.department_name,
    //   locationdata:this.addjobEducationForm.value.location_name,
    //   qualificationdata:this.departmentform.value.qualification
    //  }
    //  this.router.navigate(['/faq']);
    //  console.log(this.departmentform.value);
    //  var departmentdata = this.departmentform.value.department_name.split(',')
    //  this.departmentform.value.department_name = departmentdata[0]
    //  var locationdata = this.departmentform.value.location_name.split(',')
    //  this.departmentform.value.location_name = departmentdata[0]
    //  var qualificationdata = this.departmentform.value.qualification.split(',')
    //  this.departmentform.value.qualification = departmentdata[0]
    //    var data = {
    //    departmentdata:this.departmentform.value.department_name,
    //    locationdata:this.departmentform.value.location_name,
    //    qualificationdata:this.departmentform.value.qualification
    //   }
    // console.log(this.departmentform.value);
    //    this.serv.postSearchStarts(this.departmentform.value).subscribe(
    //   res => {
    //    console.log(res);
    //    },
    //    error => {
    //    });
  }

  //recentjobs starts
  //new jobs data
  popularCompanies() {
    this.serv.popularDataget().subscribe((Result: any) => {
      console.log(Result, "results");
      if (Result.status == 200) {
        this.popular = Result.data;
        console.log(Result.data);
      }
    })
  }
  new() {
    this.serv.newDataget().subscribe((Result: any) => {
      console.log(Result, "results");
      if (Result.status == 200) {
        this.joblist = Result.data;
        this.globaljoblist = Result.data;
        console.log(Result.data);
      }
    })
  }

  getfulltime() {
    this.serv.getfulltime().subscribe((Result: any) => {
      console.log(Result, "results");
      if (Result.status == 200) {
        this.joblist = Result.data;
        console.log(Result.data);
      }
    })
  }

  getparttime() {
    this.serv.getparttime().subscribe((Result: any) => {
      console.log(Result, "results");
      if (Result.status == 200) {
        this.joblist = Result.data;
        console.log(Result.data);
      }
    })
  }
  //recent jobs data

  searchData() {
    // console.log(this.departmentform.value.department_name, this.globaljoblist);
    var search = this.globaljoblist.filter(repo => {
      return (repo.department_name == this.departmentform.value.department_name) && (repo.location_name == this.departmentform.value.location_name) && (repo.qualification_name == this.departmentform.value.qualification)
    })
    if (search.length == 0) {
      this.errorMessageAlert('No Data Found')
    }
    this.joblist = search;
    console.log(search);

    console.log(this.globaljoblist);
    document.getElementById("content").scrollIntoView();
    // this.joblist = [];
    // console.log( this.globaljoblist);

    //   this.globaljoblist.map(repo => {
    //     if (repo.department_name == this.departmentform.value.department_name  && repo.location_name == this.departmentform.value.location_name &&  repo.qualification_name == this.departmentform.value.qualification) {
    //       this.joblist.push(repo)
    //       console.log(this.joblist);

    //     }
    //   })
  }
  errorMessageAlert(message) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
    })
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 1200,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  jobassignstudent(company) {
    // var data = {
    //   'company': company,
    //   'student_id': localStorage.getItem('student_id'),
    //   'date': localStorage.getItem('date')
    // }
    // console.log(data);

    // this.serv.jobassignstudentdata(data).subscribe(res => {
    //   if (res.status == 300) {
    //     alert('Already Applied With This Job');
    //   } else {

    //     alert('Applied Sucessfully');

    //   }
    // })
    console.log(company);


    if (localStorage.getItem('role') == '0') {
      var data = {
        'company': company,
        'student_id': localStorage.getItem('student_id'),
        'date': localStorage.getItem('date')

      }
      console.log(data);

      this.serv.jobassignstudentdata(data).subscribe(res => {
        console.log(res.status);

        if (res.status == 300) {
          this.sucessAlert('Already Applied With This Job');
        } else {

          this.sucessAlert('Applied Sucessfully');

        }
      })
    } else {
      this.router.navigate(['/login', { index: 1 }]);
    }




  }

  loginpage() {
    this.router.navigate(['/login', { index: 1 }]);
  }

  sucessAlert(message) {
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  }

  getwebsitecount() {
    this.serv.getwebsitecount().subscribe(res => {
      console.log(res.data);
      this.websitecount = res.data
      res.data.map((res, index) => { res.i = ++index; })
    }, error => {
      //console.log(error);
    })
  }





}

