<div class="page-title-area-jobs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Post A Job</h2>
                    <ul>
                        <li><a routerLink="/">Companies</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Post A Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="font-family:Times New Roman;">
    <section class="faq-area pt-100">
        <div class="container">
            <div class="row faq-wrap">
                <div class="col-lg-12">
                    <div class="faq-head">

                        <h2 style="text-align:center">Quick & Easy Job Posting − Get Quality Applies</h2>
                    </div>

                    <div class="pricing-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 col-lg-4" *ngFor="let i of packages">
                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
                                        <span style="font-size:32px ;">{{i.packagename}}
                                        </span><br>

                                        <h2>₹{{i.packageamount}}</h2>
                                        <p style="font-size:20px ;">{{i.uploadresume}} Resumes</p>

                                        <ul>
                                            <li style="font-size:20px ;">
                                                {{i.description}}</li>

                                        </ul>
                                        <br>
                                        <div style="margin-bottom: 3%;">
                                            <button type="button" class="btn btn-primary" (click)="submitbuynow(i)">Buy Now</button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 col-lg-4">
                                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                        <span>Classifed

                                            Basic Job Posting</span>

                                        <br>
                                        <h2>₹850</h2>
                                        <p>For All Cities</p>
                                        <ul>
                                            <li>250 Characters in Job Description</li>
                                            <li>Boost on Job Search Pag</li>
                                            <li>Job Branding</li>
                                            <li> Flat 10% OFF | Buy 5 Job Postings or more</li>
                                        </ul>
                                        <br>
                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                        <button type="button" style="margin-left:20px" class="btns"
                                            class="btn btn-primary">Add To
                                            Cart</button>
                                    </div>
                                </div> -->

                            <!-- <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".5s">
                                        <span>Classified Basic Job Posting</span>
                                        <br>
                                        <h2>₹400</h2>
                                        <p>For Non-Metro Cities</p>

                                        <ul>
                                            <li>250 Characters in Job Description</li>
                                            <li>Boost on Job Search Pag</li>
                                            <li>Job Branding</li>
                                            <li> Flat 10% OFF | Buy 5 Job Postings or more</li>
                                        </ul>
                                        <br>
                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                        <button type="button" style="margin-left:20px" class="btns"
                                            class="btn btn-primary">Add To
                                            Cart</button>
                                    </div>
                                </div> -->

                        </div>
                    </div>


                    <!-- <div class="job-post"> -->
                    <!-- <h2> Knowledge, Skills, and Abilities</h2> -->
                    <!-- <ul style="margin-bottom: 3%; margin-top: 2%;">
                        <li><i class="icofont-hand-drawn-right"></i> For quantities upto 4, job posting credits should
                            be
                            consumed within 30 days from the date of activation/purchase.</li>
                        <li><i class="icofont-hand-drawn-right"></i> For quantities 5 and above, credits should be
                            consumed
                            within 1 year from the date of activation/purchase.</li>
                        <li><i class="icofont-hand-drawn-right"></i> Please note that the amounts are exclusive of
                            taxes.
                            Taxes will be added as applicable.</li>
                        <li><i class="icofont-hand-drawn-right"></i> Experience designing and developing responsive
                            design
                            websites</li>
                        <li><i class="icofont-hand-drawn-right"></i>Discount percentage have been rounded off to the
                            nearest
                            number.</li>
                        <li><i class="icofont-hand-drawn-right"></i> Metro cities include Delhi/NCR(National Capital
                            Region), Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Faridabad, Mumbai and Mumbai
                            Suburbs,
                            Thane, Navi Mumbai, Pune, Chennai, Bengaluru/Bangalore, Kolkata, Hyderabad/Secunderabad and
                            Ahmedabad</li>
                    </ul> -->
                    <!-- <section class="faq-area">
                        <div class="container">
                            <div class="row faq-wrap">
                                <div class="col-lg-12">
                                    <div class="faq-head">
                                        <h2 style="text-align:center">Search Candidates from India’s Largest Talent Pool
                                            −
                                            Resdex</h2>
                                    </div>
                                    <div class="pricing-area">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
                                                        <span class="blu">3 DAYS RESDEX</span><br>
                                                        <h2>₹4300</h2>
                                                        <p><span class="colur">1 Hot Vacancy Job </span> Posting worth
                                                            ₹1650
                                                            <span class="col"> free</span>
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                Resume Database Access</li>
                                                            <li>
                                                                15 CV Acces</li>
                                                            <li>15 Emails</li>
                                                        </ul>
                                                        <br>
                                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                                        <button type="button" style="margin-left:5px" class="btns"
                                                            class="btn btn-primary">Add To
                                                            Cart</button>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="pricing-item pricing-two wow fadeInUp"
                                                        data-wow-delay=".4s">
                                                        <span class="blu">7 DAYS RESDEX</span><br>
                                                        <h2>₹8000</h2>
                                                        <p><span class="colur">2 Hot Vacancy Job </span> Job Posting
                                                            worth
                                                            ₹3350 <span class="col">free</span></p>
                                                        <ul>
                                                            <li>
                                                                Resume Database Access</li>
                                                            <li>
                                                                25 CV Acces</li>
                                                            <li>25 Emails</li>
                                                        </ul>
                                                        <br>
                                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                                        <button type="button" style="margin-left:5px" class="btns"
                                                            class="btn btn-primary">Add To
                                                            Cart</button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-3">
                                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".5s">
                                                        <span class="blu">10 DAYS RESDEX</span><br>
                                                        <h2>₹10000</h2>
                                                        <p><span class="colur">2 Hot Vacancy Job </span> Job Posting
                                                            worth
                                                            ₹3350 <span class="col">free</span></p>
                                                        <ul>
                                                            <li>
                                                                Resume Database Access</li>
                                                            <li>
                                                                25 CV Acces</li>
                                                            <li>25 Emails</li>

                                                        </ul>
                                                        <br>
                                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                                        <button type="button" style="margin-left:5px" class="btns"
                                                            class="btn btn-primary">Add To
                                                            Cart</button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-3">
                                                    <div class="pricing-item pricing-two wow fadeInUp"
                                                        data-wow-delay=".4s">
                                                        <span class="blu">15 DAYS RESDEX</span><br>

                                                        <h2>₹16000</h2>
                                                        <p><span class="colur">4 Hot Vacancy Job </span> Job Posting
                                                            worth
                                                            ₹4950 <span class="col">free</span></p>

                                                        <ul>
                                                            <li>
                                                                Resume Database Access</li>
                                                            <li>
                                                                40 CV Acces</li>
                                                            <li>40 Emails</li>

                                                        </ul>

                                                        <br>
                                                        <button type="button" class="btn btn-danger">Buy Now</button>
                                                        <button type="button" style="margin-left:5px" class="btns"
                                                            class="btn btn-primary">Add To
                                                            Cart</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="job-knowledge extra-info-text" style="margin-bottom: 5%;">
                                        <ul>
                                            <li><i class="icofont-hand-drawn-right"></i> CV View / CV Download / Click
                                                to
                                                view phone no. = 1 CV Access, 1 Excel download / Export to Response
                                                manager
                                                = 2 CV Access.</li>
                                            <li><i class="icofont-hand-drawn-right"></i> Job Posting credits should be
                                                consumed within 30 Days from the date of activation/purchase.</li>
                                            <li><i class="icofont-hand-drawn-right"></i> Please note that the amounts
                                                are
                                                exclusive of taxes. Taxes will be added as applicable.</li>
                                            <li><i class="icofont-hand-drawn-right"></i> Experience designing and
                                                developing
                                                responsive design websites</li>
                                            <li><i class="icofont-hand-drawn-right"></i> Strong understanding of PHP
                                                back-end development</li>

                                        </ul>

                                    </div> -->


                    <div style="margin-bottom: 8%;">
                        <h3 style="text-align:center;" class="head">Why Choose Recruitin Job Posting?</h3>

                        <div class="row">

                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card" style="color: #b3b9ba;">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon1.png" style="height: 64px; width:65px;">
                                            <h4>2 Minutes To Post</h4>
                                            <h5>Quick and easy to post job posting with highly optimised job
                                                posting form</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon2.png" style="height: 64px; width:65px;">
                                            <i class="fa-brands fa-searchengin"></i>
                                            <h4>Attract Audience</h4>
                                            <h5>Reach to over 8.27 crore talented jobseekers</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon3.png" style="height: 64px; width:65px;">
                                            <h4>30 days visibility</h4>
                                            <h5>Get quality applies guaranteed with 30 days visibility of your job
                                                ads.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


                <!-- <div class="productCard" style="margin-bottom: 9%;">
                    <div class="container">
                <div class="jss1 jss24">
                    <div class="jss2 jss32" style="position: relative; padding: 0px;">
                        <div class="product-container" style="box-shadow: none;">
                            <div class="container-tooltip"><span class="jss100" style="color: initial;"><span
                                        class="rcom-icon rcomIcon-info"></span></span></div>
                            <p class="product-heading">3 DAYS RESDEX</p>
                            <p class="product-label"></p>
                            <div class="jss1">
                                <div class="jss2 jss41">
                                    <div style="padding-left: 18px; padding-right: 18px;">
                                        <div class="text-center" style="position: relative;"><span
                                                class="product-currency"><sup>₹</sup></span><span
                                                class="product-price">4300</span></div>
                                        <div>
                                            <p class="resdex-add-icon">+</p>
                                            <div class="offer-propduct">
                                                <p><span class="greenTxt">1 Hot Vacancy</span><span> </span> Job Posting
                                                    worth <span>₹</span>1650 <span class="greenBg">FREE</span></p>
                                                <div class="division-line"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="product-available-features">
                                                <div class="feature-available-text" style="padding-left: 0px;">Resume
                                                    Database Access</div>
                                            </div>
                                            <div class="product-available-features">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">150 CV Access</div>
                                                </span>
                                            </div>
                                            <div class="product-available-features" style="padding-bottom: 20px;">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">1500 Emails</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div><button
                                                class="jss138 jss112 jss123 jss124 jss126 jss127 buy-now primary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113">Buy Now</span><span
                                                    class="jss207"></span></button><button
                                                class="jss138 jss112 jss120 jss121 add-to-cart secondary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113"><span
                                                        id="added-tick"></span><span id="btn-text">Add to
                                                        cart</span></span><span class="jss207"></span></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="jss2 jss32" style="position: relative; padding: 0px;">
                        <div class="product-container" style="box-shadow: none;">
                            <div class="container-tooltip"><span class="jss100" style="color: initial;"><span
                                        class="rcom-icon rcomIcon-info"></span></span></div>
                            <p class="product-heading">7 DAYS RESDEX</p>
                            <p class="product-label"></p>
                            <div class="jss1">
                                <div class="jss2 jss41">
                                    <div style="padding-left: 18px; padding-right: 18px;">
                                        <div class="text-center" style="position: relative;"><span
                                                class="product-currency"><sup>₹</sup></span><span
                                                class="product-price">8150</span></div>
                                        <div>
                                            <p class="resdex-add-icon">+</p>
                                            <div class="offer-propduct">
                                                <p><span class="greenTxt">2 Hot Vacancy</span><span> </span> Job Posting
                                                    worth <span>₹</span>3300 <span class="greenBg">FREE</span></p>
                                                <div class="division-line"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="product-available-features">
                                                <div class="feature-available-text" style="padding-left: 0px;">Resume
                                                    Database Access</div>
                                            </div>
                                            <div class="product-available-features">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">350 CV Access</div>
                                                </span>
                                            </div>
                                            <div class="product-available-features" style="padding-bottom: 20px;">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">3500 Emails</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div><button
                                                class="jss138 jss112 jss123 jss124 jss126 jss127 buy-now primary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113">Buy Now</span><span
                                                    class="jss207"></span></button><button
                                                class="jss138 jss112 jss120 jss121 add-to-cart secondary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113"><span
                                                        id="added-tick"></span><span id="btn-text">Add to
                                                        cart</span></span><span class="jss207"></span></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="jss2 jss32" style="position: relative; padding: 0px;">
                        <div class="product-container" style="box-shadow: none;">
                            <div class="container-tooltip"><span class="jss100" style="color: initial;" title=""><span
                                        class="rcom-icon rcomIcon-info"></span></span></div>
                            <p class="product-heading">15 DAYS RESDEX</p>
                            <p class="product-label"></p>
                            <div class="jss1">
                                <div class="jss2 jss41">
                                    <div style="padding-left: 18px; padding-right: 18px;">
                                        <div class="text-center" style="position: relative;"><span
                                                class="product-currency"><sup>₹</sup></span><span
                                                class="product-price">15250</span></div>
                                        <div>
                                            <p class="resdex-add-icon">+</p>
                                            <div class="offer-propduct">
                                                <p><span class="greenTxt">2 Hot Vacancy</span><span> </span> Job Posting
                                                    worth <span>₹</span>3300 <span class="greenBg">FREE</span></p>
                                                <div class="division-line"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="product-available-features">
                                                <div class="feature-available-text" style="padding-left: 0px;">Resume
                                                    Database Access</div>
                                            </div>
                                            <div class="product-available-features">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">700 CV Access</div>
                                                </span>
                                            </div>
                                            <div class="product-available-features" style="padding-bottom: 20px;">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">7000 Emails</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div><button
                                                class="jss138 jss112 jss123 jss124 jss126 jss127 buy-now primary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113">Buy Now</span><span
                                                    class="jss207"></span></button><button
                                                class="jss138 jss112 jss120 jss121 add-to-cart secondary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113"><span
                                                        id="added-tick"></span><span id="btn-text">Add to
                                                        cart</span></span><span class="jss207"></span></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="jss2 jss32" style="position: relative; padding: 0px;">
                        <div class="product-container" style="box-shadow: none;">
                            <div class="container-tooltip"><span class="jss100" style="color: initial;"><span
                                        class="rcom-icon rcomIcon-info"></span></span></div>
                            <p class="product-heading">15 DAYS RESDEX</p>
                            <p class="product-label"></p>
                            <div class="jss1">
                                <div class="jss2 jss41">
                                    <div style="padding-left: 18px; padding-right: 18px;">
                                        <div class="text-center" style="position: relative;"><span
                                                class="product-currency"><sup>₹</sup></span><span
                                                class="product-price">16500</span></div>
                                        <div>
                                            <p class="resdex-add-icon">+</p>
                                            <div class="offer-propduct">
                                                <p><span class="greenTxt">3 Hot Vacancy</span><span> </span> Job Posting
                                                    worth <span>₹</span>4950 <span class="greenBg">FREE</span></p>
                                                <div class="division-line"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="product-available-features">
                                                <div class="feature-available-text" style="padding-left: 0px;">Resume
                                                    Database Access</div>
                                            </div>
                                            <div class="product-available-features">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">700 CV Access</div>
                                                </span>
                                            </div>
                                            <div class="product-available-features" style="padding-bottom: 20px;">
                                                <div class="feature-available-image"></div><span class="jss100"
                                                    style="color: initial;">
                                                    <div class="feature-available-text">7000 Emails</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div><button
                                                class="jss138 jss112 jss123 jss124 jss126 jss127 buy-now primary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113">Buy Now</span><span
                                                    class="jss207"></span></button><button
                                                class="jss138 jss112 jss120 jss121 add-to-cart secondary-btn-effect"
                                                tabindex="0" type="button"><span class="jss113"><span
                                                        id="added-tick"></span><span id="btn-text">Add to
                                                        cart</span></span><span class="jss207"></span></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

             <div class="job-knowledge" style="font-size: 18px;margin-top: 3%;">
                                    <ul>
                                        <li><i class="icofont-hand-drawn-right"></i> CV View / CV Download / Click to
                                            view phone no. = 1 CV Access, 1 Excel download / Export to Response manager
                                            = 2 CV Access.</li>
                                        <li><i class="icofont-hand-drawn-right"></i> Job Posting credits should be
                                            consumed within 30 Days from the date of activation/purchase.</li>
                                        <li><i class="icofont-hand-drawn-right"></i> Please note that the amounts are
                                            exclusive of taxes. Taxes will be added as applicable.</li>
                                        <li><i class="icofont-hand-drawn-right"></i> Experience designing and developing
                                            responsive design websites</li>
                                        <li><i class="icofont-hand-drawn-right"></i> Strong understanding of PHP
                                            back-end development</li>

                                    </ul>

                                </div>
                            </div> -->
                <!-- </div> -->
                <!-- <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What's your ideal company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a>Why should we hire you?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>What do we serve from our company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>What attracted me to this company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>When will you be most satisfied in our job?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Web Development</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What are the advantages of HTTP 2.0 over HTTP 1.1?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut fugiat, expedita vero et quia saepe debitis exercitationem labore consequatur fuga ab, laudantium sed maiores velit reiciendis. Ullam illo laborum sequi.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a> What are the new form elements introduced in HTML5?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>What is Frontend?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>What’s the difference between Canvas and SVG?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>What is Backend?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>UX/UI</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What made me go into UX design?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a>Why do you want to work here?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>How will I define UX?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>How does UX differ from other design disciplines?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>What’s my design process?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>  -->
