// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  Api: 'https://recruitin.in:8084/nodeapp/',

  // Api : 'http://localhost:2479/dashboardapi/',

  firebase: {
    apiKey: "AIzaSyCDrGgdvJnQkZ5GDfkG-lk-d2v8tsOKqCM",
    authDomain: "recruitin-bd725.firebaseapp.com",
    projectId: "recruitin-bd725",
    storageBucket: "recruitin-bd725.appspot.com",
    messagingSenderId: "693544639002",
    appId: "1:693544639002:web:c5fa4d5cfbe6b31cc4cfa3",
    measurementId: "G-335TTWXJ3X"
  }

};