import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreateaccountserviceService {


  Api = environment.Api
  loginData: any;
  constructor(private httpservice: HttpClient) { }

  candidateSignupData(data: any) {
    console.log(data);

    return this.httpservice.post<any>(this.Api + `candidatesignuppost`, data).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }
  //recruiter sinup post starts
  recruiterSignupData(data: any) {
    return this.httpservice.post<any>(this.Api + `recruiterSignupPost`, data).pipe(map(res => {
      return res;
    }, (error: any) => {

      return error;
    }));
  }
  deleteskilldata(id) {
    return this.httpservice.get<any>(this.Api + `deleteskill/` + id)
  }

  getcandidatesignuppost() {
    return this.httpservice.get<any>(this.Api + `getcandidatesignuppost`)
  }

  departmentget() {
    return this.httpservice.get<any>(this.Api + 'departmentget')
  }

  getrecruiterdata(data) {
    return this.httpservice.post<any>(this.Api + `getrecruiterdata`, data)

  }


}
