import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  Api = environment.Api
  constructor(private http: HttpClient) { }

  addbuypackage(data) {
    return this.http.post<any>(this.Api + `addbuypackagedata`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  departmentget() {
    return this.http.get<any>(this.Api + 'departmentget')
  }

  adddepartmentdata(data) {
    return this.http.post<any>(this.Api + `adddepartmentdata`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
  addlocationdata(data) {
    return this.http.post<any>(this.Api + `addlocationdata`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
  addeducationdata(data) {
    return this.http.post<any>(this.Api + `addeducationdata`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }

  getdepartment() {
    return this.http.get<any>(this.Api + `getdepartment`)
  }


  getlocationdata() {
    return this.http.get<any>(this.Api + `getlocationdata`)
  }
  geteducationdata() {
    return this.http.get<any>(this.Api + `geteducationdata`)
  }
  RazarpayStatuscode(data) {
    return this.http.post<any>(this.Api + `RazarpayStatuscode`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
  RazarpayStatuscode1(data) {
    return this.http.post<any>(this.Api + `RazarpayStatuscode1`, data).pipe(map(res => {
      return res;
    }, error => {
      return error;
    }));
  }
}

