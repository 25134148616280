<div class="page-title-area-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="login-area pt-100 close" style="padding-top:40px; padding-bottom :60px" >
    <div class="container">

        <div class="containers" [ngClass]="dynoclass ? 'right-panel-active' : '' " id="container">

            <div class="form-container sign-in-container">

                <!-- <div *ngIf="dynoclass"> -->
                    <!-- <div class="wrapper"> -->
                        <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                            <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:40px 0px;">
                                <div class="job-card" style="color: #b3b9ba;">
                                    <div class="job-inner align-items-center">
                                        <div class="row">
                                            <div class="col-12 col-lg-10 mx-auto">
                                                <div>
                                                    <h2 style="text-align: center;">GET IN TOUCH</h2>
                                                </div>
                                                <form (ngSubmit)="contactPostSubmit()" [formGroup]="contactUs">
                                                    <div class="row contact-wrap">
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <!-- <label for="name"><b>Name</b><span style="color:red">*</span></label> -->
                                                                <input type="text" name="fullname" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;"
                                                                id="fullname" class="form-control fms" formControlName="fullname"
                                                                 placeholder="Full Name"  [ngClass]="{'is-invalid': submitted && addUsersFormCnt['fullname'].errors}">
                                                                    <div *ngIf="submitted && addUsersFormCnt['fullname'].errors" class="invalid-feedback">
                                                                        <span *ngIf="addUsersFormCnt['fullname'].errors.required">Please Enter User Name.</span>
                                                                      </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <!-- <label for="name"><b>Email</b><span style="color:red">*</span></label> -->
                                                                <input type="email" id="email" name="email" class="form-control fms" style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;"  formControlName="email"
                                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}" placeholder="Email"  >
                                                                <div *ngIf="submitted && addUsersFormCnt['name'].errors" class="invalid-feedback">
                                                                    <span *ngIf="addUsersFormCnt['name'].errors.required">Please Email Name.</span>
                                                                  </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <!-- <label for="name"><b>Mobile Number</b><span style="color:red">*</span></label> -->
                                                                <input type="text" id="phone_number" name="phone_number" class="phone_number"
                                                                    class="form-control fms" formControlName="phone_number" style="background-color: transparent;border: 1px solid;
                                                                    border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                    border-image-slice: 1;border-radius: 0;"  placeholder="Mobile Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10"
                                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['number'].errors}">
                                                                    <div *ngIf="submitted && addUsersFormCnt['number'].errors" class="invalid-feedback">
                                                                        <span *ngIf="addUsersFormCnt['number'].errors.required">Please Enter Mobile Number.</span>
                                                                      </div>
                                                            </div>
                                                        </div>
<!--
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <input type="text" id="msg_subject" name="msg_subject" class="msg_subject"  style="background-color: transparent;border: 1px solid;
                                                                border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                                border-image-slice: 1;border-radius: 0;" class="form-control fms"
                                                                    formControlName="msg_subject"  placeholder="Subject" [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}" >
                                                                    <div *ngIf="submitted && addUsersFormCnt['name'].errors" class="invalid-feedback">
                                                                        <span *ngIf="addUsersFormCnt['name'].errors.required">Please Enter Subject.</span>
                                                                      </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-md-6 col-lg-6">
                                                            <!-- <div class="form-group">
                                                                <textarea name="message"  id="message"  formControlName="message" cols="10" rows="8" required placeholder="Job Description"></textarea>
                                                            </div> -->
                                                            <!-- <label>Description</label> -->
                                                            <!-- <label for="name"><b>Job Description</b><span style="color:red">*</span></label> -->
                                                            <textarea id="your_message" name="message" id="message" class="form-control fms"
                                                                placeholder="Comment" formControlName="message" [ngClass]="{'is-invalid': submitted && addUsersFormCnt['message'].errors}"></textarea>
                                                                <div *ngIf="submitted && addUsersFormCnt['message'].errors" class="invalid-feedback">
                                                                    <span *ngIf="addUsersFormCnt['message'].errors.required">Please Enter Comments.</span>
                                                                  </div>
                                                        </div>
                                                        <div class="col-md-12 col-lg-12" class="bottom">
                                                            <div class="text-center">
                                                                <!-- <button type="submit" class="btn contact-btn" >Submit</button> -->
                                                                <button type="submit" class="btn create-ac-btn" [disabled]="!contactUs.valid">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">


                        <div>
                            <h3><a class="anc" style="border-bottom: 1px solid;">Message Us</a> </h3>
                            <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message here; instead, register with us, and our Human Resources department will contact you after their review of your submitted information. </p>
                        </div>

                        <!-- <button type="button" class="ghost" (click)="dynoclass = false; " id="signIn">Sign In</button> -->
                    </div>
                    <!-- <div class="overlay-panel overlay-right">
                        <div>
                            <h2 style="text-align: center;">GET IN TOUCH</h2>
                        </div>

                        <div>
                            <h5><a class="anc" style="border-bottom: 1px solid;">Message Us</a> </h5>
                            <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message,here – instead, register with us and our
                                 Human Resources department will contact you after their review of your submitted information. </p>
                        </div>
                        <button type="button" class="ghost" (click)="dynoclass = true; " id="signUp">Sign In</button>
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container">
<div class="login-area pt-100 " style="padding-top:40px; padding-bottom :60px" >
<div class="containers-res filter row"  id="container" >



    <div class="col-12 ">

        <!-- <div *ngIf="dynoclass"> -->
            <!-- <div class="wrapper"> -->
                <div class="authentication-reset-password d-flex align-items-center justify-content-center back">
                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:2px 0px 0px 0px">
                        <div class="job-card" style="color: #b3b9ba;">
                            <div class="job-inner align-items-center">
                                <div class="row">
                                    <div class="col-12 col-lg-10 mx-auto">
                                        <div class="dodo">
                                            <div>
                                                <h3><a class="anc" style="border-bottom: 1px solid;">Message Us</a> </h3>
                                                <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message here; instead, register with us, and our Human Resources department will contact you after their review of your submitted information. </p>
                                            </div>
                                            <!-- <h3><a class="anc" style="border-bottom: 1px solid;">Talk to our Experts</a> </h3> -->
                                            <!-- <p class="justy">If you wish to be considered for employment at Recruitin, please do not send a message,here – instead, register with us and our
                                                 Human Resources department will contact you after their review of your submitted information. </p> -->
                                        </div>
                                        <!-- <div>
                                            <img src="https://www.recruitin.in/app_images/cust.gif" style="height: 390px;" alt="">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>

    <div class="  col-12">

        <!-- <div *ngIf="dynoclass"> -->
            <!-- <div class="wrapper"> -->
                <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                    <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s" style="padding:70px 0px;">
                        <div class="job-card" style="color: #b3b9ba;">
                            <div class="job-inner align-items-center">
                                <div class="row">
                                    <div class="col-12 col-lg-10 mx-auto">
                                        <div>
                                            <h3 style="text-align: start;">Contact Us</h3>
                                            <h4 style="text-align: start;font-size: 18px;">Our executive will get in touch with you soon . </h4>
                                        </div>
                                        <form (ngSubmit)="contactPostSubmit()" [formGroup]="contactUs">
                                            <div class="row contact-wrap">
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Name</b><span style="color:red">*</span></label> -->
                                                        <input type="text" name="fullname" style="background-color: transparent;border: 1px solid;
                                                        border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                        border-image-slice: 1;border-radius: 0;"
                                                        id="fullname" class="form-control fms" formControlName="fullname"
                                                         placeholder="Full Name"  [ngClass]="{'is-invalid': submitted && addUsersFormCnt['fullname'].errors}">
                                                            <div *ngIf="submitted && addUsersFormCnt['fullname'].errors" class="invalid-feedback">
                                                                <span *ngIf="addUsersFormCnt['fullname'].errors.required">Please Enter User Name.</span>
                                                              </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Email</b><span style="color:red">*</span></label> -->
                                                        <input type="email" id="email" name="email" class="form-control fms" style="background-color: transparent;border: 1px solid;
                                                        border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                        border-image-slice: 1;border-radius: 0;"  formControlName="email"
                                                        [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}" placeholder="Email"  >
                                                        <div *ngIf="submitted && addUsersFormCnt['name'].errors" class="invalid-feedback">
                                                            <span *ngIf="addUsersFormCnt['name'].errors.required">Please Email Name.</span>
                                                          </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <!-- <label for="name"><b>Mobile Number</b><span style="color:red">*</span></label> -->
                                                        <input type="text" id="phone_number" name="phone_number" class="phone_number"
                                                            class="form-control fms" formControlName="phone_number" style="background-color: transparent;border: 1px solid;
                                                            border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                            border-image-slice: 1;border-radius: 0;"  placeholder="Mobile Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10"
                                                            [ngClass]="{'is-invalid': submitted && addUsersFormCnt['number'].errors}">
                                                            <div *ngIf="submitted && addUsersFormCnt['number'].errors" class="invalid-feedback">
                                                                <span *ngIf="addUsersFormCnt['number'].errors.required">Please Enter Mobile Number.</span>
                                                              </div>
                                                    </div>
                                                </div>
<!--
                                                <div class="col-sm-6 col-lg-6">
                                                    <div class="form-group">
                                                        <input type="text" id="msg_subject" name="msg_subject" class="msg_subject"  style="background-color: transparent;border: 1px solid;
                                                        border-image-source: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%);
                                                        border-image-slice: 1;border-radius: 0;" class="form-control fms"
                                                            formControlName="msg_subject"  placeholder="Subject" [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}" >
                                                            <div *ngIf="submitted && addUsersFormCnt['name'].errors" class="invalid-feedback">
                                                                <span *ngIf="addUsersFormCnt['name'].errors.required">Please Enter Subject.</span>
                                                              </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-6 col-lg-6">
                                                    <!-- <div class="form-group">
                                                        <textarea name="message"  id="message"  formControlName="message" cols="10" rows="8" required placeholder="Job Description"></textarea>
                                                    </div> -->
                                                    <!-- <label>Description</label> -->
                                                    <!-- <label for="name"><b>Job Description</b><span style="color:red">*</span></label> -->
                                                    <textarea id="your_message" name="message" id="message" class="form-control fms"
                                                        placeholder="Comment" formControlName="message" [ngClass]="{'is-invalid': submitted && addUsersFormCnt['message'].errors}"></textarea>
                                                        <div *ngIf="submitted && addUsersFormCnt['message'].errors" class="invalid-feedback">
                                                            <span *ngIf="addUsersFormCnt['message'].errors.required">Please Enter Comments.</span>
                                                          </div>
                                                </div>
                                                <div class="col-md-12 col-lg-12" class="bottom">
                                                    <div class="text-center">
                                                        <!-- <button type="submit" class="btn contact-btn" >Submit</button> -->
                                                        <button type="submit" class="btn create-ac-btn" [disabled]="!contactUs.valid">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>

</div>
</div>
</div>

<!-- <div class="contact-form-area ptb-100">
    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Job Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="btn contact-btn">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->

<div class="map-area pb-100">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1587549815840!5m2!1sen!2sbd"></iframe>
</div>
