<div class="page-title-area-profile">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Profile</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="pricing-area">
    <div class="container" *ngIf="role == 1">
        <form>
            <div class="row">

                <div class="col-sm-12 col-lg-12">
                    <h2 style="text-align: center; color: black;">Recruiter Details</h2>
                    <div class="padding">
                        <div class="card user-card-full" *ngFor="let item of profilearr">
                            <div class="row m-l-0 m-r-0">
                                <div class="col-sm-4 bg-c-lite-green user-profile">
                                    <div class="card-block text-center text-white">
                                        <div class="m-b-25">
                                            <img src="{{item.file}}" class="img-radius"
                                                onerror="this.src='assets/img/home-1/avatar.png';"
                                                alt="User-Profile-Image">
                                        </div>
                                        <h6 class="f-w-600">{{item.company_name}}</h6>
                                        <p>{{item.industry_type}}</p>
                                        <button class="btn bg-c-lite-green"><b style="color: #e2ffef;"
                                                (click)="openmodal(2)">Edit Company Profile</b></button>
                                    </div>
                                </div>
                                <div class="col-sm-8" style="    background: #e2ffef;">
                                    <div class="card-block">
                                        <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h3>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Email</p>
                                                <h6 class="text-muted f-w-400"> {{item.recruiter_email}} </h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Phone</p>
                                                <h6 class="text-muted f-w-400">{{item.phone_number}}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Address</p>
                                                <h6 class="text-muted f-w-400"> {{item.address}} </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" *ngIf="showin==2">
                    <div class="card-body">
                        <form [formGroup]="recruiterSignup">
                            <div class="container">
                                <div class="row">
                                    <!-- Name Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="recruiter_name">Name:</label>
                                        <input id="recruiter_name" class="form-control"
                                            formControlName="recruiter_name" />
                                        <div
                                            *ngIf="recruiterSignup.get('recruiter_name').invalid && recruiterSignup.get('recruiter_name').touched">
                                            <small *ngIf="recruiterSignup.get('recruiter_name').hasError('required')"
                                                class="text-danger">Name is required.</small>
                                        </div>
                                    </div>

                                    <!-- Email Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="recruiter_email">Email:</label>
                                        <input id="recruiter_email" class="form-control"
                                            formControlName="recruiter_email" />
                                        <div
                                            *ngIf="recruiterSignup.get('recruiter_email').invalid && recruiterSignup.get('recruiter_email').touched">
                                            <small *ngIf="recruiterSignup.get('recruiter_email').hasError('required')"
                                                class="text-danger">Email is required.</small>
                                            <small *ngIf="recruiterSignup.get('recruiter_email').hasError('email')"
                                                class="text-danger">Enter a valid email.</small>
                                        </div>
                                    </div>

                                    <!-- Phone Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="phone">Phone:</label>
                                        <input id="phone" class="form-control" formControlName="phone" />
                                        <div
                                            *ngIf="recruiterSignup.get('phone').invalid && recruiterSignup.get('phone').touched">
                                            <small *ngIf="recruiterSignup.get('phone').hasError('required')"
                                                class="text-danger">Phone number is required.</small>
                                            <small *ngIf="recruiterSignup.get('phone').hasError('pattern')"
                                                class="text-danger">Enter a valid 10-digit phone number.</small>
                                        </div>
                                    </div>

                                    <!-- Company Name Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="company_name">Company Name:</label>
                                        <input id="company_name" class="form-control" formControlName="company_name" />
                                        <div
                                            *ngIf="recruiterSignup.get('company_name').invalid && recruiterSignup.get('company_name').touched">
                                            <small *ngIf="recruiterSignup.get('company_name').hasError('required')"
                                                class="text-danger">Company name is required.</small>
                                        </div>
                                    </div>

                                    <!-- Address Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="recruiter_address">Address:</label>
                                        <input id="recruiter_address" class="form-control"
                                            formControlName="recruiter_address" />
                                    </div>

                                    <!-- Industry Type Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="industry_type">Industry Type:</label>
                                        <input id="industry_type" class="form-control"
                                            formControlName="industry_type" />
                                    </div>

                                    <!-- Description Field -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="recruiter_description">Description:</label>
                                        <textarea id="recruiter_description" class="form-control"
                                            formControlName="recruiter_description"></textarea>
                                    </div>
                                </div>

                                <!-- Submit Button -->
                                <div class="row" style="text-align: center;">
                                    <div class="col-12">
                                        <button type="submit" class="btn btn-primary"
                                            [disabled]="recruiterSignup.invalid"
                                            (click)="upadteemployee()">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

                <div class="col-sm-12 col-lg-12" style="margin-bottom: 10%;">
                    <h2 style="text-align: center; color: black;">Recruiter Payment Details</h2>
                    <div class="padding">
                        <div class="card user-card-full" *ngFor="let item of paymentarr" style="margin: 2%;">
                            <div class="row m-l-0 m-r-0">
                                <div class="col-sm-4 bg-c-lite-green user-profile">
                                    <div class="card-block text-center text-white">
                                        <div class="m-b-25">
                                            <br>
                                            <img src="{{item.file}}" class="img-radius"
                                                onerror="this.src='assets/img/package.svg';" alt="User-Profile-Image">
                                        </div>
                                        <h6 class="f-w-600">{{item.company_name}}</h6>

                                    </div>
                                </div>
                                <div class="col-sm-8" style="background: #e2ffef;">
                                    <div class="card-block">
                                        <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Package Information</h3>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Job Title</p>
                                                <h6 class="text-muted f-w-400"> {{item.job_title}} </h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Package Name</p>
                                                <h6 class="text-muted f-w-400"> {{item.packagename}} </h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Package Amount</p>
                                                <h6 class="text-muted f-w-400">{{item.packageamount}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Experience</p>
                                                <h6 class="text-muted f-w-400">{{item.experience}}years</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Salary</p>
                                                <h6 class="text-muted f-w-400">{{item.salary}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">How Many Resumes:</p>
                                                <h6 class="text-muted f-w-400">{{item.uploadresume}}</h6>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Location Name</p>
                                                <h6 class="text-muted f-w-400">{{item.location_name}}</h6>
                                            </div>
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Remarks</p>
                                                <h6 class="text-muted f-w-400"> {{item.description}} </h6>
                                            </div>
                                            <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Description</p>
                                                <h6 class="text-muted f-w-400"> {{item.remarks}} </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="pricing-area" *ngIf="role == 0">
    <div class="page-content container page-container" id="page-content">
        <div class="padding">
            <div class="card user-card-full" *ngFor="let item of studentdata">
                <div class="row m-l-0 m-r-0">
                    <div class="col-sm-4 bg-c-lite-green user-profile">
                        <div class="card-block text-center text-white">
                            <div class="m-b-25">
                                <img src="{{item.image}}" class="img-radius"
                                    onerror="this.src='assets/img/home-1/avatar.png';" alt="User-Profile-Image">
                            </div>
                            <h6 class="f-w-600">Name : {{item.name}}</h6>
                            <p>Role : {{item.job_title}}</p>
                            <button class="btn bg-c-lite-green" (click)="openmodal(1)">
                                <b style="color: #e2ffef;">Edit Profile</b>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-8" style="background: #e2ffef;">
                        <div class="card-block">
                            <h3 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h3>
                            <div class="row">
                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Email</p>
                                    <h6 class="text-muted f-w-400"> {{item.email}} </h6>
                                </div>
                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Mobile Number</p>
                                    <h6 class="text-muted f-w-400">{{item.phone_number}}</h6>
                                </div>

                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">Address</p>
                                    <h6 class="text-muted f-w-400">{{item.address}}</h6>
                                </div>

                                <div class="col-sm-6">
                                    <p class="m-b-10 f-w-600">No of Tests completed</p>
                                    <h6 class="text-muted f-w-400">{{getexamtest.length}}</h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <p class="m-b-10 f-w-600" style="margin-bottom: 15px;">Skills</p>
                                    <span class="chip" *ngFor="let data of item.skills">{{data.skills}}</span>
                                </div>
                                <div class="col-sm-6">
                                </div>
                            </div>
                            <ul class="social-link list-unstyled m-t-40 m-b-10">
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="facebook" data-abc="true"><i
                                            class="mdi mdi-facebook feather icon-facebook facebook"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="twitter" data-abc="true"><i
                                            class="mdi mdi-twitter feather icon-twitter twitter"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="instagram" data-abc="true"><i
                                            class="mdi mdi-instagram feather icon-instagram instagram"
                                            aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="showin==1">
            <div class="card-body">
                <form [formGroup]="studentform" (ngSubmit)="onSubmit()">
                    <div class="container">

                        <div class="row">
                            <div class="col-12" style="text-align: right;">
                                <button class="btn btn-primary" (click)="closestudentfrom()">X</button>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="name">Name : </label>
                                    <input id="name" class="form-control" formControlName="name">
                                    <div *ngIf="formControls.name.invalid && (formControls.name.dirty || formControls.name.touched)"
                                        class="text-danger">
                                        <div *ngIf="formControls.name.errors?.required">Name is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="email">Email : </label>
                                    <input id="email" class="form-control" formControlName="email">
                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)"
                                        class="text-danger">
                                        <div *ngIf="formControls.email.errors?.required">Email is required.</div>
                                        <div *ngIf="formControls.email.errors?.email">Invalid email address.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="date_of_birth">Date of Birth : </label>
                                    <input id="date_of_birth" type="date" class="form-control"
                                        formControlName="date_of_birth">
                                    <div *ngIf="formControls.date_of_birth.invalid && (formControls.date_of_birth.dirty || formControls.date_of_birth.touched)"
                                        class="text-danger">
                                        <div *ngIf="formControls.date_of_birth.errors?.required">Date of birth is
                                            required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="phone">Phone : </label>
                                    <input type="text" class="form-control" formControlName="phone_number">
                                    <div *ngIf="formControls.phone_number.invalid && (formControls.phone_number.dirty || formControls.phone_number.touched)"
                                        class="text-danger">
                                        <div *ngIf="formControls.phone_number.errors?.required">Phone number is
                                            required.</div>
                                        <div *ngIf="formControls.phone_number.errors?.pattern">Phone number must be
                                            exactly 10
                                            digits.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="job_title">Job Title : </label>
                                    <input id="job_title" class="form-control" formControlName="job_title">
                                </div>
                            </div>


                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="gender">Gender : </label>
                                    <input id="gender" class="form-control" formControlName="gender">
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="qualification">Qualification : </label>
                                    <input id="qualification" class="form-control" formControlName="qualification">
                                </div>
                            </div>

                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="year_of_passing">Year of Passing : </label>
                                    <input id="year_of_passing" class="form-control" formControlName="year_of_passing">
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="percentage">Percentage : </label>
                                    <input id="percentage" class="form-control" formControlName="percentage">
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="experience">Experience : </label>
                                    <input id="experience" class="form-control" formControlName="experience">
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="address">Address : </label>
                                    <textarea id="address" class="form-control" rows="2"
                                        formControlName="address"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="text-align: center;">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="studentform.invalid">Update</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>



    <section class="job-area job-area-twos pt-100">
        <h3 style="text-align: center;color: green; margin-bottom: 15px;">Completed Tests</h3>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 " *ngFor="let i of getexamtest">
                    <div class="job-item box">
                        <div *ngIf="i.completed == 1" class="ribbon ribbon-top-right"><span>completed</span></div>
                        <img src="{{i.exam_imgurl}}" alt="img" style="width:60px; height: 60px;margin-top: 10%;">
                        <div class="job-inner align-items-center" style="padding-top: 35px;">
                            <div class="job-inner-left">
                                <h3 style="font-size:17px"><label>Exam Name:</label>
                                    {{i.exam_name}}</h3>
                                <label>Total Questions:</label><span>{{i.total_qns}}</span>&nbsp;<br>
                                <label>Exam Time Minutes:</label><span>{{i.exam_duration}}</span>&nbsp;<br>
                                <ul>
                                    <label>Total Marks:</label><span>{{i.total_marks}}</span>&nbsp;<br>
                                    <label>Description:</label><span>{{i.description}}</span>&nbsp;<br>
                                    <label>Total Score:</label><span>{{i.score}}</span>&nbsp;<br>
                                    <label>Total Percentage:</label><span>{{i.result}}% </span> &nbsp;<br>
                                    <label *ngIf="i.result >= 90">You are qualified</label><span *ngIf="i.result >= 90"
                                        (click)="genarateCert(i.date, i.exam_name)" class="blink-text">&nbsp; Download
                                        Certificate</span>&nbsp;<br>
                                </ul>
                            </div>
                            <div class="job-inner-right">
                                <ul>
                                    <li class="curse"><a (click)="startTest(i.id, i.total_qns, i.exam_duration)">{{
                                            i.completed == 0 ? 'Start Test': 'View Test'}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<ng-template #modal let-modal>

</ng-template>