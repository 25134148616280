import { Component, OnInit } from '@angular/core';
var google;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  langauge_id: any;
  
  constructor() {

    this.langauge_id = localStorage.getItem('language_id')
  }

  ngOnInit(): void {
  }
  // googleTranslateElementInit(){
  //   new google.translate.TranslateElement({ pageLanguage: 'te', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
  // }
}
