<div class="center card_cent bg" style="width: 100%;"  *ngFor="let item of userdata">
    <div >
    <h1 class="cert" style="font-family:  -webkit-body;;">CERTIFICATE</h1>
    <h3 class="cert">Of Appreciation</h3>

    <h5  class="cert">The Certificate is presented to</h5>

    <h1 class="cert"> <span class="spa"><i> {{item.name}} </i></span> </h1>

    <hr style="color: blue;width: 500px; height: 5px; margin: auto;font-weight: bold; ">

    <h5 class="cert"> <span class="spot">In recognition for a record of outstanding accomplishment. </span> </h5>
    <h5 class="cert"> <span class="spot"> For his achievement in the <span style="font-weight: bold;    font-size: 25px;"> {{ item.exam | uppercase}} </span>  Exam </span> </h5>

    <div class="motto">
        <h5  class="cert dat">
            <span style="font-family: initial;
            font-weight: bold;">{{item.date | date : 'dd-MM-y'}}</span>
            <span style="border-top: 1px solid blue;"><i>Date</i> </span>
            </h5>
        <img src="assets/img/cert.png" style="width: 200px;" alt="">
        <h5  class="cert dat">
            <img src="assets/img/sign.png" style="height: 50px;width: 150px;" alt="">
            <span style="border-top: 1px solid blue;"> <i>Sudheer Kumar K</i> </span>
            </h5>
    </div>
</div>
</div>
<!-- <div class="my-overlay" *ngIf="showOverlay" >
    <mat-spinner></mat-spinner>
    <h5 style="color: white;">Please Wait...</h5>
  </div> -->
