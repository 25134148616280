import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    websitecount: any;

    constructor(private serv: ServiceService) { }

    ngOnInit(): void {
      this.getwebsitecount();
    }


    getwebsitecount() {
      this.serv.getwebsitecount().subscribe(res => {
        console.log(res.data);
        this.websitecount = res.data
        res.data.map((res, index) => { res.i = ++index; })
      }, error => {
        //console.log(error);
      })
    }
}
